<template>
    <v-flex v-if="dataLoaded">
        <v-flex v-if="subscriptionList.length" class="ma-0 pa-0">
            <v-layout wrap>
                <v-flex xs12 class="mb-2 pl-2"><div class="subheading" data-test-id="headline-subtitle">{{ isDistrictAdmin ? 'My Districts/Schools' : 'My Schools'}}</div></v-flex>
                <v-flex v-if="isDistrictAdmin" xs10 sm8 lg9 id="DistrictAdminSchoolSelector">
                    <v-menu v-model="schoolDropdown" max-width="400" bottom nudge-bottom="62" nudge-right="0">
                        <template #activator="{}">
                            <Headline
                                :headline="selectedSchool.name"
                                dropdown
                                v-on:dropdown-click="schoolDropdown=true"
                                :icon="headlineIcon"
                                flowOverride="none"
                                color="#1976D2"
                            ></Headline>
                        </template>
                        <v-card max-height="600">
                            <v-list>
                                <v-list-tile v-for="s in schools" :key="s.id" @click="selectedSchoolID=s.id" :class="{'selectedSchool':s.id==selectedSchoolID}">
                                    <v-list-tile-avatar>
                                        <v-icon :large="s.typeId<4">{{s.typeId>3 ? 'fa-school' : 'fa-building'}}</v-icon>
                                    </v-list-tile-avatar>
                                    <v-list-tile-content>
                                        <v-list-tile-title v-html="s.name" :class="{'ml-2' : s.typeId>3}"></v-list-tile-title>
                                        <v-list-tile-sub-title v-html="printLocation(s.location)" :class="{'ml-2' : s.typeId>3}"></v-list-tile-sub-title>
                                    </v-list-tile-content>
                                </v-list-tile>
                            </v-list>
                        </v-card>
                    </v-menu>
                </v-flex>
                <v-flex v-else xs11 sm8 lg9>
                    <Headline :headline="selectedSchool.name" flowOverride="none" :icon="headlineIcon" color="#1976D2"></Headline>
                </v-flex>
                <v-flex sm4 lg3 hidden-sm-and-down class="text-xs-right px-0 pt-0">
                    <v-btn id="institution_settings_button" class="elevation-0 mt-0" flat @click="openDialog('schoolSettingsModal')">
                        <v-icon>settings</v-icon>&nbsp;{{$t('schoolMgmt.settings')}}
                    </v-btn>
                </v-flex>
                <v-flex xs2 hidden-md-and-up class="text-xs-right px-0 pt-0">
                    <v-btn class="elevation-0 mt-0" flat icon large @click="openDialog('inviteUserModal')" v-show="showInviteBtn">
                        <v-icon>person_add</v-icon>
                    </v-btn>
                    <v-btn id="institution_settings_button" icon class="elevation-0 mt-0" flat large @click="openDialog('schoolSettingsModal')">
                        <v-icon>settings</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
            <v-layout row justify-space-between>            
                <v-tabs
                    v-model="activeTab"
                    slider-color="blue"
                    class="ml-2"
                >
                    <v-tab id="administrators_tab" data-test-id="admin-administrators-tab" key="administrators" ripple @click="setActive('active')">
                        {{$tc('classMgmt.administrators', localizationConstants.MULTIPLE)}}
                    </v-tab>
                    <v-tab id="teacher_tab" data-test-id="admin-teachers-tab" key="teachers" ripple @click="setActive('active')">
                        {{$tc('classMgmt.teachers', localizationConstants.MULTIPLE)}}
                    </v-tab>
                    <v-tab id="students_tab" v-if="!isCertifiedTrainer" data-test-id="admin-students-tab" key="student" ripple @click="setActive('active')">
                        {{$tc('classMgmt.students', localizationConstants.MULTIPLE)}}
                    </v-tab>
                </v-tabs>
                <div 
                    v-if="showCCEnabledStudentsHiddenAlert"
                    @click="openDialog('classCodeInfoModal')"
                    class="cc-enabled-alert px-2 my-auto">
                    <div class="ma-1 d-flex">
                        <v-icon color="primary" class="my-auto mr-1" small>info</v-icon>
                        <div class="my-auto">{{ $t('classCode.ccStudentsAreHidden') }}</div>
                    </div>
                </div>
            </v-layout>
            <!-- Student Tab -->
            <v-flex v-show="activeTab==tabIDs.STUDENT">
                <div v-if="isClassCodeGizmosOnlySchool || isAdminOnlyInstitute()">
                    <v-layout wrap my-5>
                        <v-flex class="d-flex mb-2" xs12>
                            <v-icon class="large-chat-bubble mx-auto" color="primary">chat_bubble</v-icon>
                        </v-flex>
                        <v-flex xs8 class="mx-auto">
                            <div class="text-xs-center title mb-3">{{isClassCodeGizmosOnlySchool ? $t('classCode.explainationHeader') : $t('classMgmt.selectASchoolStudents')}}</div>
                            <div class="text-xs-center subheading" v-show="isClassCodeGizmosOnlySchool">{{ $t('classCode.explainationSubheader') }}</div>
                        </v-flex>
                    </v-layout>
                </div>
                <div v-else-if="filteredStudents.length < 1">
                    <v-layout wrap my-5>
                        <v-flex class="d-flex mb-2" xs12>
                            <v-icon class="large-chat-bubble mx-auto" color="primary">groups</v-icon>
                        </v-flex>
                        <v-flex xs8 class="mx-auto">
                            <div class="text-xs-center title mb-3">{{ $t('classMgmt.noStudentsToManage') }}</div>
                        </v-flex>
                    </v-layout>
                </div>
                <div v-else>
                    <v-card-title class="pb-1 px-0">
                        <span class="subheading pt-2 grey--text" v-show="!showFilterMsg" data-test-id="admin-user-mgmt-student-length">
                            {{students.length}}&nbsp;{{$tc('classMgmt.students', students.length)}}<span v-if="isSelectedSchoolRostered">, <v-icon class="body-2 pb-1">sync_alt</v-icon> {{$t('classMgmt.rosteredSchool')}} <RosterModal :adminText="true"></RosterModal></span>
                        </span>
                        <span class="subheading pt-2 grey--text" v-show="showFilterMsg" v-text="filterMsg"></span>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="studentSearch"
                            prepend-icon="search"
                            clearable
                            label="Search"
                            hide-details
                            class="userSearch"
                        ></v-text-field>
                        <v-select
                            v-model="gradeLevelFilter"
                            :items="gradeLevelIdFilter"
                            item-text="label"
                            item-value="id"
                            class="gradeLevelFilter"
                        ></v-select>
                        <v-btn class="elevation-0 mr-0" :color="filterBtnColor" @click="openDialog('filterModal')">
                            <v-icon>filter_list</v-icon>
                            <span v-if="numberOfFilters">&nbsp;{{numberOfFilters}}&nbsp;Active</span>
                            <span v-else>&nbsp;{{$t('classMgmt.filter')}}</span>
                        </v-btn>
                    </v-card-title>
                    <v-data-table
                        v-model="selectedStudents"
                        :headers="studentHeaders"
                        :items="filteredStudents"
                        item-key="id"
                        :search="studentSearch"
                        :pagination.sync="paginationOptions"
                        :rows-per-page-items="rowsPerPageItems(filteredStudents.length)"
                        select-all
                        :no-data-text="$t('classMgmt.noData')"
                        class="elevation-2 mb-3 roundedCorners"
                        must-sort
                        dense
                        ref="studentTable"
                    >
                        <template #headers="props">
                            <tr>
                                <th class="px-3">
                                    <v-checkbox
                                        :input-value="props.all"
                                        :indeterminate="props.indeterminate"
                                        primary
                                        hide-details
                                        @click.stop="toggleAll('selectedStudents',filteredTableData('studentTable'))"
                                    ></v-checkbox>
                                </th>
                                <th
                                    v-for="header in props.headers"
                                    :key="header.text"
                                    :class="['column',
                                        header.sortable ? 'sortable':'',
                                        paginationOptions.descending ? 'desc' : 'asc',
                                        header.sortable && header.value === paginationOptions.sortBy ? 'active' : '',
                                        'text-xs-'+header.align,
                                        header.icon ? 'px-0':'px-3']"
                                    @click="if(header.sortable)changeSort(header.value,paginationOptions)"
                                >
                                    <span v-if="header.icon">
                                        <v-icon small class="header-icon pt-1">{{header.icon}}</v-icon>
                                    </span>
                                    <span v-else>{{ header.text }}</span>
                                    <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                                </th>
                            </tr>
                        </template>
                        <template #items="student">
                            <tr data-test-id="admin-student-table-row">
                                <td class="px-3">
                                    <v-checkbox
                                        v-model="student.selected"
                                        primary
                                        hide-details
                                    ></v-checkbox>
                                </td>
                                <td v-if="isSelectedSchoolRostered" @click="openDialog('editStudentModal', {users: student.item})" class="pointer px-0">
                                    <v-icon small v-if="student.item.isRostered">sync_alt</v-icon>
                                </td>
                                <td @click="openDialog('editStudentModal', {users: student.item})" class="blue--text pointer px-3" >
                                    {{student.item.lastName}}, {{student.item.firstName}}
                                </td>
                                <td @click="openDialog('editStudentModal', {users: student.item})" class="pointer px-3" data-test-id="admin-student-table-row-student-id-data">
                                    {{student.item.sisID}}
                                </td>
                                <td @click="openDialog('editStudentModal', {users: student.item})" class="pointer">
                                        <span>{{getGradeLevel(student.item.gradeID)}}</span>
                                </td>
                                <td @click="openDialog('editStudentModal', {users: student.item})" class="text-xs-right pointer px-3" :style="productAccessColumnMinWidth">
                                    <span v-for="productID in ownedProductsForClassCodes" :key="student.item.id+'-'+productID">
                                        <v-tooltip bottom :key="student.item.id+'-'+productID+'-tooltip'">
                                            <template #activator="{ on }">
                                                <img v-on="on"
                                                    :src="productImgPath(productID)"
                                                    :class="[
                                                        {'grey-product-indicator': !determineAssignment(student.item,productID).code},
                                                        productDisplayName(productID).toLowerCase()+'-leaf'
                                                    ]"
                                                    class="product-indicator"
                                                    :alt="productDisplayName(productID)+' Logo'"
                                                />
                                            </template>
                                            <span>{{determineAssignment(student.item,productID).label}}</span>
                                        </v-tooltip>
                                    </span>
                                </td>
                            </tr>
                        </template>
                        <template #pageText="props">
                            {{ props.pageStart }} - {{ props.pageStop }} of <span ref="studentsInTable">{{ props.itemsLength }}</span>
                        </template>
                    </v-data-table>
                </div>
            </v-flex>
            <!-- Bottom Buttons (Students) -->
            <v-layout wrap v-if="activeTab==tabIDs.STUDENT && !isClassCodeGizmosOnlySchool && !isAdminOnlyInstitute() && filteredStudents.length > 0">
                <v-flex>
                    <v-menu offset-y top>
                        <template #activator="{ on }">
                            <v-btn id="add_student_button" flat color="primary" v-on="on">
                                <v-icon>person_add</v-icon>&nbsp;{{$t('classMgmt.addStudents')}}&nbsp;&#9662;
                            </v-btn>
                        </template>
                        <v-card id="addOptions">
                            <v-list class="blue--text">
                                <v-list-tile @click="openDialog('importStudentModal', {}, false)" id="addOptionImport">
                                    <v-list-tile-action>
                                        <v-icon color="blue">publish</v-icon>
                                    </v-list-tile-action>
                                    <v-list-tile-content>
                                        {{$t('classMgmt.importStudentsFromFile')}}
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-list-tile v-if="!isSelectedSchoolRostered" @click="openDialog('addStudentNewModal')" id="addOptionManual">
                                    <v-list-tile-action>
                                        <v-icon color="blue">person_add</v-icon>
                                    </v-list-tile-action>
                                    <v-list-tile-content>
                                        {{$t('classMgmt.addANewStudent')}}
                                    </v-list-tile-content>
                                </v-list-tile>
                            </v-list>
                        </v-card>
                    </v-menu>
                </v-flex>
                <v-flex class="text-xs-right action-bar">
                    <v-btn color="primary" class="elevation-0" @click="exportStudents(selectedStudents,students)">
                        <v-icon>get_app</v-icon>&nbsp;{{$t('classMgmt.export')}} ({{selectedStudents.length || "All"}})
                    </v-btn>
                    <v-btn color="primary" class="elevation-0 ml-1" v-if="isDistrictAdmin" :disabled="!selectedStudents.length || containsRosteredUser(selectedStudents) || !otherNonRosteredSchools.length" @click="openDialog('transferModal')">
                        <v-icon>swap_horiz</v-icon>&nbsp;{{$t('classMgmt.transfer')}} ({{selectedStudents.length}})
                    </v-btn>
                    <v-btn color="primary" class="elevation-0 ml-1" :disabled="!selectedStudents.length || containsRosteredUser(selectedStudents)" @click="openDialog('editGradeLevelModal')">
                        <v-icon>edit</v-icon>&nbsp;{{$t('classMgmt.gradeLevel')}} ({{selectedStudents.length}})
                    </v-btn>
                    <v-btn color="error" class="elevation-0 ml-1" :disabled="!selectedStudents.length || containsRosteredUser(selectedStudents)" @click="openDialog('deleteConfirmModal')">
                        <v-icon>delete</v-icon>&nbsp;{{$t('classMgmt.delete')}} ({{selectedStudents.length}})
                    </v-btn>
                </v-flex>
            </v-layout>
            <v-layout v-else-if="!isClassCodeGizmosOnlySchool && !isAdminOnlyInstitute() && activeTab==tabIDs.STUDENT && filteredStudents.length < 1">
                <v-flex grow="false" xs6 class="mx-auto">
                    <v-btn flat color="primary" @click="openDialog('importStudentModal', {}, false)" >
                        <v-icon class="mr-1">publish</v-icon>
                        <span>{{$t('classMgmt.importStudentsFromFile')}}</span>
                    </v-btn>
                    <v-btn flat color="primary" v-if="!isSelectedSchoolRostered" @click="openDialog('addStudentNewModal')">
                        <v-icon class="mr-1">person_add</v-icon>
                        <span>{{$t('classMgmt.addANewStudent')}}</span>
                    </v-btn>
                </v-flex>
            </v-layout>
            <!-- Non-Student Tab -->
            <v-flex v-show="activeTab!=tabIDs.STUDENT">
                <div v-if="isAdminOnlyInstitute() && activeTab == tabIDs.TEACHER">
                    <v-layout wrap my-5>
                        <v-flex class="d-flex mb-2" xs12>
                            <v-icon class="large-chat-bubble mx-auto" color="primary">chat_bubble</v-icon>
                        </v-flex>
                        <v-flex xs8 class="mx-auto">
                            <div class="text-xs-center title mb-3">{{$t('classMgmt.selectASchoolTeachers')}}</div>
                        </v-flex>
                    </v-layout>
                </div>
                <div v-else>
                    <v-card-title class="pb-1 px-0 nonStudentTitle">
                        <v-layout row no-wrap>
                            <v-flex shrink align-self-center>
                                <v-layout wrap>
                                    <v-flex align-self-center>
                                        <span v-if="invitedActiveType.length" class="linkTabs">
                                            <v-layout row wrap justify-start>
                                                <v-flex shrink py-2>
                                                    <a @click="setActive('active')" :class="[{'activeTab':activeOrPending=='active'}, 'mr-1', 'py-2', 'px-3']" data-test-id="admin-link-tab-active-teachers-tab">{{this[userType.toLowerCase()].length}} {{$t('classMgmt.userStatusActive')}} {{$t('classMgmt.accounts')}}</a>
                                                </v-flex>
                                                <v-flex shrink py-2>
                                                    <a @click="setActive('pending')" :class="[{'activeTab':activeOrPending=='pending'}, 'py-2', 'px-3']" data-test-id="admin-link-tab-invited-teachers-tab">{{invitedActiveType.length}} {{$t('classMgmt.pendingInvites')}}</a>
                                                </v-flex>
                                            </v-layout>
                                        </span>
                                    </v-flex>
                                    <v-flex pa-2><span v-show="showNonStudentFilterMsg">{{nonStudentFilterMsg}}</span></v-flex>
                                    <v-flex align-self-center><span v-if="isSelectedSchoolRostered"><v-icon class="body-2 pb-1">sync_alt</v-icon> {{$t('classMgmt.rosteredSchool')}} <RosterModal :adminText="true"></RosterModal></span></v-flex>
                                </v-layout>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="nonStudentSearch"
                                prepend-icon="search"
                                clearable
                                label="Search"
                                hide-details
                                class="userSearch align-self-center"
                            ></v-text-field>
                            <v-flex shrink>
                                <v-btn :id="'invite_'+userType.toLowerCase()+'_button'" class="elevation-0 ma-0 hidden-sm-and-down" flat color="primary" @click="openDialog('inviteUserModal')" v-show="showInviteBtn">
                                    <v-icon>person_add</v-icon>&nbsp;{{ inviteBtnLabel }}
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-title>
                    <v-data-table
                        v-model="selectedNonStudents"
                        :headers="nonStudentHeaders"
                        :items="nonStudentsForTable"
                        item-key="id"
                        :search="nonStudentSearch"
                        :pagination.sync="paginationOptions"
                        :rows-per-page-items="rowsPerPageItems(nonStudentsForTable.length)"
                        select-all
                        :no-data-text="$t('classMgmt.noData')"
                        class="elevation-2 mb-3 roundedCorners"
                        must-sort
                        dense
                        ref="nonStudentTable"
                    >
                        <template #headers="props">
                            <tr>
                                <th class="px-3">
                                    <v-checkbox
                                        :input-value="props.all"
                                        :indeterminate="props.indeterminate"
                                        primary
                                        hide-details
                                        @click.stop="toggleAll('selectedNonStudents',filteredTableData('nonStudentTable'))"
                                    ></v-checkbox>
                                </th>
                                <th
                                    v-for="header in props.headers"
                                    :key="header.text"
                                    :class="['column',
                                        header.sortable ? 'sortable':'',
                                        paginationOptions.descending ? 'desc' : 'asc',
                                        header.sortable && header.value === paginationOptions.sortBy ? 'active' : '',
                                        'text-xs-'+header.align,
                                        header.icon ? 'px-0':'px-3']"
                                    @click="if(header.sortable)changeSort(header.value,paginationOptions)"
                                >
                                    <span v-if="header.icon">
                                        <v-icon small class="header-icon pt-1">{{header.icon}}</v-icon>
                                    </span>
                                    <span v-else>{{ header.text }}</span>
                                    <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                                </th>
                            </tr>
                        </template>
                        <template v-if="activeOrPending=='active'" #items="user">
                            <tr data-test-id="admin-nonstudent-table-row">
                                <td class="px-3">
                                    <v-checkbox
                                        v-model="user.selected"
                                        primary
                                        hide-details
                                    ></v-checkbox>
                                </td>
                                <td v-if="isSelectedSchoolRostered && activeTab==tabIDs.TEACHER" @click="openDialog('editNonStudentModal', {users: user.item})" class="pointer px-0">
                                    <v-icon small v-if="user.item.isRostered">sync_alt</v-icon>
                                </td>
                                <td @click="openDialog('editNonStudentModal', {users: user.item})" class="blue--text pointer px-3" >
                                    {{user.item.lastName}}, {{user.item.firstName}}
                                </td>
                                <td @click="openDialog('editNonStudentModal', {users: user.item})" class="pointer px-3">
                                    {{user.item.username}}
                                </td>
                                <td @click="openDialog('editNonStudentModal', {users: user.item})" class="pointer px-3" :class="{'text-xs-right':!showProductCol}">
                                    {{user.item.email}}
                                </td>
                                <td v-if="showProductCol" @click="openDialog('editNonStudentModal', {users: user.item})" class="text-xs-right pointer px-3" :style="productAccessColumnMinWidth">
                                    <span v-for="productID in ownedProducts" :key="user.item.username+'-'+productID">
                                        <v-tooltip bottom :key="user.item.username+'-'+productID+'-tooltip'">
                                            <template #activator="{ on }">
                                                <img v-on="on"
                                                    :src="productImgPath(productID)"
                                                    :class="[
                                                        {'grey-product-indicator': !hasLiscense(user.item,productID)},
                                                        productDisplayName(productID).toLowerCase()+'-leaf'
                                                    ]"
                                                    class="product-indicator"
                                                    :alt="productDisplayName(productID)+' Logo'"
                                                />
                                            </template>
                                            <span v-if="hasLiscense(user.item,productID)">{{$t('classMgmt.productAccessEnabled', {product: productDisplayName(productID)})}}</span>
                                            <span v-else>{{$t('classMgmt.productAccessDisabled', {product: productDisplayName(productID)})}}</span>
                                        </v-tooltip>
                                    </span>
                                </td>
                            </tr>
                        </template>
                        <template v-else #items="user">
                            <tr>
                                <td class="px-3">
                                    <v-checkbox
                                        v-model="user.selected"
                                        primary
                                        hide-details
                                    ></v-checkbox>
                                </td>
                                <td @click="openDialog('viewInviteModal', {users: user.item})" class="blue--text pointer px-3" width="100%">
                                    {{user.item.email}}
                                </td>
                                <td @click="openDialog('viewInviteModal', {users: user.item})" class="px-3 pointer text-xs-right" style="white-space:nowrap">
                                    <v-tooltip bottom :key="user.item.username+'status-tooltip'" :disabled="!Boolean(user.item.inviteStatus.tooltip)">
                                        <template #activator="{ on }">
                                            <span v-on="on">
                                                
                                                <span :style="{'color':user.item.inviteStatus.color}">
                                                    {{user.item.inviteStatus.label}}
                                                </span>
                                                <v-icon small :color="user.item.inviteStatus.color">
                                                    {{user.item.inviteStatus.icon}}
                                                </v-icon>
                                            </span>
                                        </template>
                                        <span>{{user.item.inviteStatus.tooltip}}</span>
                                    </v-tooltip>
                                </td>
                                <td v-if="showProductCol" @click="openDialog('viewInviteModal', {users: user.item})" class="text-xs-right px-3 pointer" :style="productAccessColumnMinWidth">
                                    <span v-for="productID in ownedProducts" :key="user.item.username+'-'+productID">
                                        <v-tooltip bottom :key="user.item.username+'-'+productID+'-tooltip'">
                                            <template #activator="{ on }">
                                                <img v-on="on"
                                                    :src="productImgPath(productID)"
                                                    :class="[
                                                        {'grey-product-indicator': !hasLiscense(user.item,productID)},
                                                        productDisplayName(productID).toLowerCase()+'-leaf'
                                                    ]"
                                                    class="product-indicator"
                                                    :alt="productDisplayName(productID)+' Logo'"
                                                />
                                            </template>
                                            <span v-if="hasLiscense(user.item,productID)">{{$t('classMgmt.productAccessEnabled', {product: productDisplayName(productID)})}}</span>
                                            <span v-else>{{$t('classMgmt.productAccessDisabled', {product: productDisplayName(productID)})}}</span>
                                        </v-tooltip>
                                    </span>
                                </td>
                            </tr>
                        </template>
                        <template #pageText="props">
                            {{ props.pageStart }} - {{ props.pageStop }} of <span ref="nonStudentsInTable">{{ props.itemsLength }}</span>
                        </template>                    
                    </v-data-table>
                </div>
            </v-flex>
            <!-- Bottom Buttons (Non-Students) -->
            <v-layout wrap v-if="activeTab == tabIDs.ADMIN && !isCertifiedTrainer || (activeTab == tabIDs.TEACHER && !isAdminOnlyInstitute())">
                <v-flex class="text-xs-right">
                    <v-btn v-if="activeTab==tabIDs.TEACHER && activeOrPending=='active' && !isEmployee" 
                        id="product_access_button" 
                        color="primary" 
                        class="elevation-0" 
                        :disabled="!selectedNonStudents.length" 
                        @click="openProductAccessModal()">
                            <v-icon>edit</v-icon>
                            &nbsp;Product Access ({{selectedNonStudents.length}})
                    </v-btn>
                    <v-btn v-if="activeOrPending=='pending'" color="primary" class="elevation-0" :disabled="refreshingInvites" @click="refreshInvites()">
                        <v-icon>refresh</v-icon>&nbsp;{{$t('classMgmt.refreshInvites')}}
                    </v-btn>
                    <v-btn v-if="activeOrPending=='pending'" color="primary" class="elevation-0" :disabled="!selectedNonStudents.length" @click="resendInvite(selectedNonStudents)">
                        <v-icon>send</v-icon>&nbsp;{{$t('classMgmt.resendInvite')}} ({{selectedNonStudents.length}})
                    </v-btn>
                    <v-btn color="error" class="elevation-0" :disabled="!selectedNonStudents.length || containsRosteredUser(selectedNonStudents)" @click="openDialog('deleteConfirmModal')">
                        <v-icon>delete</v-icon>&nbsp;Delete ({{selectedNonStudents.length}})
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-flex>
        <!-- No Subscriptions -->
        <v-flex v-else class="text-xs-center pt-3">
            <v-flex class="display-1 font-weight-thin grey--text">{{$t('classMgmt.youDoNotHaveAnyActiveSubscriptions')}}</v-flex>
            <v-flex class="display-1 font-weight-thin grey--text">{{$t('classMgmt.visitYour')}} <router-link :to="{name:'Subscriptions'}">{{$t('header.account')}}</router-link> {{$t('classMgmt.pageToLearnMore')}}</v-flex>
        </v-flex>
        <!-- Success Toast -->
        <v-snackbar
            v-model='successBar'
            color="success"
            multi-line
        >
            {{successMessage}}
            <v-btn dark flat @click="successBar = false">
                Close
            </v-btn>
        </v-snackbar>
        <!--
            =====================================
            End Page Content, Begin Modal Content
            =====================================
         -->
        <div v-if="subscriptionList.length">
<FilterModal
                @clearFilters="clearFilters"
                @closeDialogs="closeDialogs"
                @addFilter="addFilter"
                :value="modalControl.filterModal"
            ></FilterModal>

            <EditStudentModal
                @closeDialogs="closeDialogs"
                @openDialog="openDialog"
                @updateModel="updateModel"
                :altLicenseSource="employeeData.subscriptionMap"
                :checkProductUpgrade="checkProductUpgrade"
                :determineAssignment="determineAssignment"
                :institutionID="institutionID"
                :ownedProducts="ownedProducts"
                :student="passedOrSelectedStudents"
                :subscriptions="subscriptionList"
                :tenantID="tenantID"
                :value="modalControl.editStudentModal"
            ></EditStudentModal>

            <AddNewStudentModal
                @closeDialogs="closeDialogs"
                @openDialog="openDialog"
                @updateModel="updateModel"
                @updateSectors="updateSectors"
                @handleSuccess="handleSuccess"
                @trackAddStudents="trackAddStudents"
                :hasGradeLevelLessThan="hasGradeLevelLessThan"
                :institutionID="institutionID"
                :isDistrictAdmin="isDistrictAdmin"
                :multiProduct="multiProduct"
                :productId="productId"
                :seatInfo="seatInfo"
                :selectedSchool="selectedSchool"
                :subscriptions="subscriptionList"
                :tenantID="tenantID"
                :value="modalControl.addStudentNewModal"
            ></AddNewStudentModal>

            <EditNonStudentModal
                @closeDialogs="closeDialogs"
                @openDialog="openDialog"
                @toggleProductAccess="toggleProductAccess"
                @updateModel="updateModel"         
                :altLicenseSource="employeeData.subscriptionMap"
                :checkProductUpgrade="checkProductUpgrade"
                :isUserEditable="!isCertifiedTrainer"
                :isProductAccessEditable="!isEmployeeOrCertifiedTrainer"
                :institutionID="institutionID"
                :ownedProducts="ownedProducts"
                :products="ownedProducts"
                :seatInfo="seatInfo"
                :selectedSchool="selectedSchool"
                :subscriptions="subscriptionList"
                :tenantID="tenantID"
                :user="passedOrSelectedUsers"
                :userType="userType"
                :value="modalControl.editNonStudentModal"
            ></EditNonStudentModal>

            <ResetPasswordModal
                @closeDialogs="closeDialogs"
                :selectedUsers="passedOrSelectedUsers"
                :value="modalControl.resetPasswordModal"
            ></ResetPasswordModal>

            <TransferStudentModal
                @closeDialogs="closeDialogs"
                @handleSuccess="handleSuccess"
                @updateModel="updateModel"
                :currentSchoolID="selectedSchoolID"
                :editedStudent="selectedStudents"
                :rowsPerPageItems="rowsPerPageItems"
                :schools="schools"
                :selectedStudents="passedOrSelectedUsers"
                :userCountOrName="userCountOrName"
                :value="modalControl.transferModal"
            ></TransferStudentModal>

            <EditGradeLevelModal
                @closeDialogs="closeDialogs"
                @updateModel="updateModel"
                :institutionID="institutionID"
                :selectedUsers="passedOrSelectedUsers"
                :tenantID="tenantID"
                :userCountOrName="userCountOrName"
                :value="modalControl.editGradeLevelModal"
            ></EditGradeLevelModal>

            <ViewInviteModal
                @closeDialogs="closeDialogs"
                @handleSuccess="handleSuccess"
                @openDialog="openDialog"
                @resendInvite="resendInvite"
                @toggleProductAccess="toggleProductAccess"
                :hasLiscense="hasLiscense"
                :multiProduct="multiProduct"
                :ownedProducts="ownedProducts"
                :selectedSchool="selectedSchool"
                :subscriptions="subscriptionList"
                :user="passedOrSelectedUsers"
                :userType="userType"
                :value="modalControl.viewInviteModal"
            ></ViewInviteModal>

            <DeleteUserModal
                @closeDialogs="closeDialogs"
                @deleteUsers="deleteUsers"
                :forceDeleteMode="false"
                :selectedUsers="passedOrSelectedUsers"
                :userCountOrName="userCountOrName"
                :userType="userType"
                :value="modalControl.deleteConfirmModal"
                :inviteMode="activeOrPending=='pending'"
            ></DeleteUserModal>

            <InviteUserModal
                @closeDialogs="closeDialogs"
                @displayInvites="displayInvites"
                @handleSuccess="handleSuccess"
                @updateInvites="updateInvites"
                @updateModel="updateModel"
                :value="modalControl.inviteUserModal"
                :invites="invitedUsers"
                :isDistrictAdmin="isAdminOnlyInstitute()"
                :selectedSchool="selectedSchool"
                :productIDs="ownedProducts"
                :role="userType.toLocaleLowerCase()"
                :nonStudents="nonStudents"
                :seatInfo="seatInfo"
                :subSeatInfo="subSeatInfo"
                :tenantID="tenantID"
            ></InviteUserModal>

            <ImportStudentModal
                @closeDialogs="closeDialogs"
                @handleSuccess="handleSuccess"
                @openDialog="openDialog"
                @trackAddStudents="trackAddStudents"
                :institutionID="institutionID"
                :institutions="institutionList"
                :isSelectedSchoolRostered="isSelectedSchoolRostered"
                :productId="productId"
                :rowsPerPageItems="rowsPerPageItems"
                :tenantID="tenantID"
                :updateModel="updateModel"
                :value="modalControl.importStudentModal"
            ></ImportStudentModal>

            <FormattingModal
                @closeDialogs="closeDialogs"
                :isAdmin="true"
                :isSelectedSchoolRostered="isSelectedSchoolRostered"
                :value="modalControl.formattingModal"
            ></FormattingModal>

            <ProductAccessModal
                @closeDialogs="closeDialogs"
                @deselectUsers="deselectUsers"
                @handleSuccess="handleSuccess"
                @toggleProductAccess="toggleProductAccess"
                @updateModel="updateModel"
                :productIDs="ownedProducts"
                :seatInfo="seatInfo"
                :subSeatInfo="subSeatInfo"
                :selectedNonStudents="passedOrSelectedUsers"
                :selectedSchool="selectedSchool"
                :userCountOrName="userCountOrName"
                :userType="userType"
                :value="modalControl.productAccessModal"
            ></ProductAccessModal>

            <MultiSubAccessModal
                @closeDialogs="closeDialogs"
                @deselectUsers="deselectUsers"
                @handleSuccess="handleSuccess"
                @toggleProductAccess="toggleProductAccess"
                :productID="modalParameters.productID"
                :productIDs="ownedProducts"
                :seatInfo="seatInfo"
                :subSeatInfo="subSeatInfo"
                :selectedNonStudent="passedOrSelectedUsers"
                :selectedSchool="selectedSchool"
                :value="modalControl.multiSubAccessModal"
            ></MultiSubAccessModal>

            <SchoolSettingsModal
                @closeDialogs="closeDialogs"
                @handleSuccess="handleSuccess"
                @updateModel="updateModel"
                :allowEdits="!isCertifiedTrainer"
                :institutionID="institutionID"
                :isClassCodeEnabledSchoolGizmosOnly="isClassCodeEnabledSchoolGizmosOnly"
                :isClassCodeEnabledSchoolGizmosPlus="isClassCodeEnabledSchoolGizmosPlus"
                :isSelectedSchoolRostered="isSelectedSchoolRostered"
                :selectedSchool="selectedSchool"
                :value="modalControl.schoolSettingsModal"
            ></SchoolSettingsModal>
                
            <ClassCodeInfoModal
                @closeDialogs="closeDialogs"
                :value="modalControl.classCodeInfoModal"
            ></ClassCodeInfoModal>
        </div>
    </v-flex>
</template>

<script>
    import APIs from 'mixins/APIs'
    import ClassMgmt from 'mixins/ClassMgmt'
    import Constants from 'mixins/Constants'
    import CsvExport from '@explorelearning/csv-export'
    import { Auth } from '@explorelearning/el-authenticator'
    import ElPlatformRestApi from '@explorelearning/platform-rest-api'
    import EnvironmentDetector from 'mixins/EnvironmentDetector'
    import Headline from 'components/layout/Headline'
    import LoginDetector from 'mixins/LoginDetector'
    import RoleDetector from 'mixins/RoleDetector'
    import ProductInfo from 'mixins/ProductInfo'

    import AddNewStudentModal from 'components/modals/AddNewStudentModal'
    import DeleteUserModal from 'components/modals/DeleteUserModal'
    import EditGradeLevelModal from 'components/modals/EditGradeLevelModal'
    import EditNonStudentModal from 'components/modals/EditNonStudentModal'
    import EditStudentModal from 'components/modals/EditStudentModal'
    import FilterModal from 'components/modals/FilterModal'
    import FormattingModal from 'components/modals/FormattingModal'
    import ImportStudentModal from 'components/modals/ImportStudentModal'
    import InviteUserModal from 'components/modals/InviteUserModal'
    import ProductAccessModal from 'components/modals/ProductAccessModal'
    import MultiSubAccessModal from 'components/modals/MultiSubAccessModal'
    import ResetPasswordModal from 'components/modals/ResetPasswordModal'
    import RosterModal from 'components/classMgmt/RosterModal'
    import SchoolSettingsModal from 'components/modals/SchoolSettingsModal'
    import TransferStudentModal from 'components/modals/TransferStudentModal'
    import ViewInviteModal from 'components/modals/ViewInviteModal'
    import ClassCodeInfoModal from 'components/modals/ClassCodeInfoModal'
    
    import { mapGetters, mapActions, mapState } from 'vuex'

    export default {
        name: 'AdminManger',
        metaInfo : {
            title : 'User Management'
        },
        components: {
            AddNewStudentModal,
            ClassCodeInfoModal,
            DeleteUserModal,
            EditGradeLevelModal,
            EditNonStudentModal,
            EditStudentModal,
            FilterModal,
            FormattingModal,
            Headline,
            ImportStudentModal,
            InviteUserModal,
            ProductAccessModal,
            MultiSubAccessModal,
            ResetPasswordModal,
            RosterModal,
            SchoolSettingsModal,
            TransferStudentModal,
            ViewInviteModal,
        },
        mixins: [APIs, ClassMgmt, Constants, EnvironmentDetector, LoginDetector, RoleDetector, ProductInfo],
        data () {
            return {
                experimentalMode : false,
                // Async Data
                auth : null,
                dataLoaded: false,
                invitedUsers: [],
                // model : {},
                paginationOptions: {rowsPerPage: 25},
                subscriptionId : null,
                tenantID : null,
                regCodes : [],
                // Headline Data
                schoolDropdown: false,
                // Tabs Data
                activeTab: 0,
                activeOrPending: 'active',
                // Student Tab
                addMenu : false,
                editedStudent : [{}],
                gradeLevelFilter: -1,
                selectedStudents: [],
                studentSearch: '',
                totalStudentsInTable: -1,
                totalNonStudentsInTable: -1,
                //Control Lists
                modalControl : {
                    editStudentModal: false,
                    addStudentNewModal: false,
                    editNonStudentModal: false,
                    resetPasswordModal: false,
                    transferModal: false,
                    editGradeLevelModal: false,
                    regCodeModal: false,
                    deleteConfirmModal: false,
                    importStudentModal: false,
                    inviteUserModal : false,
                    filterModal: false,
                    productAccessModal: false,
                    schoolSettingsModal: false,
                    formattingModal: false,
                    viewInviteModal: false,
                    classCodeInfoModal: false,
                    multiSubAccessModal: false,
                },
                filterControl : {
                    econDis: null,
                    engPro: null,
                    ethnicity: null,
                    gender: null,
                    specEd: null,
                },
                // Teacher Tab
                selectedNonStudents: [],
                nonStudentSearch: '',
                refreshingInvites: false,
                // Employee Alt Data Sources
                employeeData: {
                    products : [],
                    subscriptionMap : {},
                    useEmployeeData : false
                }
            }
        },
        computed: {
            ...mapGetters('platformData/subscriptions', ['subscriptionList']),
            ...mapGetters('platformData/institutions', ['institutionList']),
            ...mapGetters('platformData/administrators', ['administratorList']),
            ...mapGetters('platformData/teachers', ['teacherList']),
            ...mapGetters('platformData/students', ['classCodeEnabledStudentList', 'nonClassCodeEnabledStudentList']),
            ...mapState('platformData/app', ['claims']),
            activeRegCodes(){
                let now = new Date()
                return this.regCodes.filter(function(code){
                    let expirationDate = new Date(code.expiresOn)
                    return now<expirationDate && code.isActive
                })
            },
            activeRegCodesForAccountType(){
                let regTypeIdMap = {
                    Students : null,
                    Teachers : 5,
                    Administrators : 8,
                }
                let regTypeID = regTypeIdMap[this.userType]
                if(!regTypeID){
                    return []
                }
                return this.activeRegCodes.filter(function(code){
                    return regTypeID == code.regTypeID
                })
            },
            filterBtnColor(){
                if(this.numberOfFilters){
                    return 'orange'
                } else {
                    return 'light-grey'
                }
            },
            filteredStudents(){
                let students = this.students
                let gradeFilter = this.gradeLevelFilter
                let self = this;
                if(gradeFilter>=0){
                    students = students.filter(function(student){
                        return student.gradeID==gradeFilter
                    })
                }
                if(Number.isInteger(self.filterControl.gender)){
                    students = students.filter(function(student){
                        return student.genderID==self.filterControl.gender
                    })
                }
                if(Number.isInteger(self.filterControl.engPro)){
                    students = students.filter(function(student){
                        return student.engProfID==self.filterControl.engPro
                    })
                }
                if(Number.isInteger(self.filterControl.econDis)){
                    students = students.filter(function(student){
                        return student.disadvID==self.filterControl.econDis
                    })
                }
                if(Number.isInteger(self.filterControl.specEd)){
                    students = students.filter(function(student){
                        return student.specialEdID==self.filterControl.specEd
                    })
                }
                if(Array.isArray(self.filterControl.ethnicity) && self.filterControl.ethnicity.length){
                    students = students.filter(function(student){
                        return self.filterControl.ethnicity.indexOf(student.ethnicityID)>=0
                    })
                }
                return students
            },
            filterMsg(){
                return `Showing ${this.totalStudentsInTable} out of ${this.students.length}`
            },
            isClassCodeGizmosOnlySchool() {
                return this.selectedSchool.isClassCodeEnabled && this.selectedSchool.subscriptions.length < 2 && this.selectedSchool.subscriptions[0].productId == 3
            },
            inviteBtnLabel(){
                if(this.userType.toLowerCase()=='teachers'){
                    return this.$t('classMgmt.inviteTeachers')
                } else {
                    return this.$t('classMgmt.inviteAdmins')
                }
            },
            invitedActiveType(){
                if(this.userType.toLowerCase()=='teachers'){
                    return this.invitedTeachers
                } else {
                    return this.invitedAdmins
                }
            },
            invitedAdmins(){
                return this.invitedUsers.filter(user => {
                    return user.roleId==5
                })
            },
            invitedTeachers(){
                return this.invitedUsers.filter(user => {
                    return user.roleId==3
                })
            },
            isDistrictAdmin(){
                if(this.institutionList){
                    return this.institutionList.length>1
                } else {
                    return false
                }
            },
            hasCCEnabledProduct() {
                return this.selectedSchool.subscriptions.some(sub => {
                    return this.classCodeEnabledProductIDs.includes(sub.productId)
                })
            },
            hasCCDisabledProduct() {
                return this.selectedSchool.subscriptions.some(sub => {
                    return !this.classCodeEnabledProductIDs.includes(sub.productId)
                })
            },
            headlineIcon() {
                return this.selectedSchool.typeId>3 ? 'fa-school' : 'fa-building'
            },
            showCCEnabledStudentsHiddenAlert() {
                return this.selectedSchool.isClassCodeEnabled && this.hasCCEnabledProduct && this.hasCCDisabledProduct && this.activeTab == 2 && !this.isAdminOnlyInstitute()
            },
            isClassCodeEnabledSchoolGizmosOnly() {
                return this.selectedSchool.isClassCodeEnabled && this.hasCCEnabledProduct && !this.hasCCDisabledProduct
            },
            isClassCodeEnabledSchoolGizmosPlus() {
                return this.selectedSchool.isClassCodeEnabled && this.hasCCEnabledProduct && this.hasCCDisabledProduct
            },
            isSelectedSchoolRostered() {
                return this.selectedSchool.isRosterEnabled
            },
            localizationConstants() {
                return {
                    NONE: 0,
                    SINGLE: 1,
                    MULTIPLE: 2
                }
            },
            nonStudentFilterMsg(){
                let total
                if (!this.invitedActiveType.length) {
                    total = this[this.userType.toLowerCase()].length+this.invitedActiveType.length
                } else if (this.activeOrPending == 'active'){
                    total = this[this.userType.toLowerCase()].length
                } else {
                    total = this.invitedActiveType.length
                }
                return `Showing ${this.totalNonStudentsInTable} out of ${total}`
            },
            nonStudentsForTable(){
                if(this.activeOrPending=='pending'){
                    return this.invitedActiveType
                } else if(this.activeTab==this.tabIDs.TEACHER){
                    return this.teachers
                } else if(this.activeTab==this.tabIDs.ADMIN){
                    return this.administrators
                } else {
                    return []
                }
            },
            nonStudentHeaders(){
                let headers = []
                if(this.activeOrPending=='active'){
                    headers.push({text: this.$t('classMgmt.name'), value:'searchableName', sortable:true, align: 'left'})
                    headers.push({text: 'Username', value:'username', sortable:true, align: 'left'})
                    if(this.showProductCol){
                        headers.push({text: 'Email Address', value:'email', sortable:true, align: 'left'})
                        headers.push({text: 'Products', value:'', sortable:false, align: 'right'})
                    } else {
                        headers.push({text: 'Email Address', value:'email', sortable:true, align: 'right'})
                    }
                    if(this.isSelectedSchoolRostered && this.activeTab==this.tabIDs.TEACHER){
                        headers.unshift({text:'Rostered', icon:'sync_alt', value:'isRostered', sortable:true, align:'left'})
                    }
                } else {
                    headers.push({text: 'Email Address', value:'email', sortable:true, align: 'left'})
                    headers.push({text: this.$t('classMgmt.inviteStatus'), value:'inviteStatus.sortOrder', sortable:true, align: 'right'})
                    if(this.activeTab==this.tabIDs.TEACHER){
                        headers.push({text: 'Products', value:'', sortable:false, align: 'right'})
                    }
                }
                return headers
            },
            numberOfFilters(){
                let count = 0
                if(Number.isInteger(this.filterControl.gender)){
                    ++count
                }
                if(Number.isInteger(this.filterControl.engPro)){
                    ++count
                }
                if(Number.isInteger(this.filterControl.econDis)){
                    ++count
                }
                if(Number.isInteger(this.filterControl.specEd)){
                    ++count
                }
                if(Array.isArray(this.filterControl.ethnicity) && this.filterControl.ethnicity.length){
                    ++count
                }
                return count
            },
            ownedProductsForClassCodes() {
                let productsToUse
                this.isClassCodeEnabledSchool && this.selectedSchool.subscriptions.some(sub => sub.productId != 3)
                    ?   productsToUse = this.ownedProducts.filter(product => product !== 3)
                    :   productsToUse = this.ownedProducts
                return productsToUse
            },
            otherNonRosteredSchools() {
                return this.schools.filter(school => {
                    return school.id !== this.selectedSchoolID && school.typeId > 3 && !school.isRosterEnabled
                })
            },
            passedOrSelectedStudents(){
                if (this.activeTab == 2) {
                    return this.passedOrSelectedUsers
                } else {
                    return [{}]
                }
            },
            schools(){
                let schools = []
                if(this.institutionList){
                    this.institutionList.forEach(institute => {
                        if(schools.length){
                            let unique = true
                            schools.forEach(school => {
                                if(institute.id==school.id){
                                    unique = false
                                }
                            })
                            if(unique){
                                schools.push(institute)
                            }
                        } else {
                            schools.push(institute)
                        }
                    })
                }
                schools.sort(function(a,b){
                    if(a.typeId-b.typeId==0){
                        let isAlpha = a.name.toLowerCase() > b.name.toLowerCase()
                        if (isAlpha){
                            return 1
                        } else {
                            return -1
                        }
                    } else {
                        return a.typeId-b.typeId
                    }
                })
                return schools
            },
            showFilterMsg(){
                if (!this.studentSearch && this.gradeLevelFilter < 0 && this.numberOfFilters < 1) {
                    return false
                } else {
                    return true
                }    
            },
            showInviteBtn(){
                let role = this.userType.toLowerCase()
                if(role == 'students'){
                    return false
                }
                if(this.isAdminOnlyInstitute() && role != 'administrators'){
                    return false
                }
                if(this.isEmployeeOrCertifiedTrainer && role != 'teachers'){
                    return false
                }
                return true
            },
            showProductCol(){
                return this.activeTab==this.tabIDs.TEACHER || this.isEmployee
            },
            showNonStudentFilterMsg(){
                if (this.nonStudentSearch) {
                    return true
                } else {
                    return false
                }
            },
            // regCodeForSelectedProducts(){
            //     let selectedProducts = this.selectedRegCodeProducts
            //     let codesForSelectedProducts = this.activeRegCodesForAccountType.filter(function(code){
            //         return selectedProducts.includes(code.productID)
            //     })
            //     if(codesForSelectedProducts.length>1){
            //         let combinedCode = {
            //             productID : [],
            //             expiresOn : new Date(codesForSelectedProducts[0].expiresOn),
            //             regCode : ''
            //         }
            //         codesForSelectedProducts.forEach(code=>{
            //             combinedCode.productID.push(code.productID)
            //             let expirationDate = new Date(code.expiresOn)
            //             if(expirationDate<combinedCode.expiresOn){
            //                 combinedCode.expiresOn = expirationDate
            //             }
            //             combinedCode.regCode = combinedCode.regCode + ' ' + code.regCode
            //         })
            //         combinedCode.regCode = combinedCode.regCode.trim()
            //         combinedCode.regCode = combinedCode.regCode.replaceAll(' ','-')
            //         return combinedCode
            //     } else if(codesForSelectedProducts.length==0){
            //         return {}
            //     } else {
            //         return codesForSelectedProducts[0]
            //     }
            // },
            selectedSchoolAdmins() {
                // return this.administratorList.filter(user => {
                //     user.
                // })
            },
            studentHeaders(){
                let headers =  [
                    {text: this.$t('classMgmt.name'), value:'searchableName', sortable:true, align: 'left'},
                    {text: this.$t('classMgmt.studentID'), value:'sisID', sortable:true, align: 'left'},
                    {text: this.$t('classMgmt.gradeLevel'), value:'gradeID', sortable:true, align: 'left'},
                    {text: 'Products', value:'', sortable:false, align: 'right'}
                ]
                if(this.isSelectedSchoolRostered){
                    headers.unshift({text:'Rostered', icon:'sync_alt', value:'isRostered', sortable:true, align:'left'})
                }
                return headers
            },
            subscriptionIds(){
                let IDs = []
                this.subscriptionList.forEach(sub => {
                    IDs.push(sub.id)
                })
                return IDs
            },
            useEmployeeData(){
                return this.employeeData.useEmployeeData
            },
            userType(){
                if(this.activeTab==this.tabIDs.STUDENT){
                    return 'Students'
                } else if(this.activeTab==this.tabIDs.TEACHER){
                    return 'Teachers'
                } else if(this.activeTab==this.tabIDs.ADMIN){
                    return 'Administrators'
                } else {
                    return ''
                }
            }
        },
        methods: {
            ...mapActions('platformData/app', ['clearModel', 'fetchModel']),
            ...mapActions('platformData/teachers', ['addTeachersToSubscription', 'removeTeachersFromSubscription']),
            addFilter(filter){
                this.filterControl.gender = (filter.name === 'filterControl.gender') ? filter.value : this.filterControl.gender
                this.filterControl.engPro = (filter.name === 'filterControl.engPro') ? filter.value : this.filterControl.engPro
                this.filterControl.econDis = (filter.name === 'filterControl.econDis') ? filter.value : this.filterControl.econDis
                this.filterControl.specEd = (filter.name === 'filterControl.specEd') ? filter.value : this.filterControl.specEd
                this.filterControl.ethnicity = (filter.name === 'filterControl.ethnicity') ? filter.value : this.filterControl.ethnicity
            },
            clearFilters(filters){
                //if the parameter is null clear all
                if (!filters)
                {
                    this.filterControl.gender = null
                    this.filterControl.engPro = null
                    this.filterControl.econDis = null
                    this.filterControl.specEd = null
                    this.filterControl.ethnicity = null
                }
                else
                {
                    this.filterControl.gender = filters.includes('filterControl.gender') ? null : this.filterControl.gender
                    this.filterControl.engPro = filters.includes('filterControl.engPro') ? null : this.filterControl.engPro
                    this.filterControl.econDis = filters.includes('filterControl.econDis') ? null : this.filterControl.econDis
                    this.filterControl.specEd = filters.includes('filterControl.specEd') ? null : this.filterControl.specEd
                    this.filterControl.ethnicity = filters.includes('filterControl.ethnicity') ? null : this.filterControl.ethnicity
                }
            },
            displayInvites(tabID){
                this.activeTab = tabID
                this.setActive('pending')
            },
            deselectUsers(){
                this.selectedNonStudents = []
                this.selectedStudents = []
            },
            exportStudents(selectedStudents, allStudents){
                const headers = [
                    'LastName',
                    'FirstName',
                    'MiddleName',
                    'StudentID',
                    'GradeLevel',
                    'Language',
                    'Password',
                    'Gender',
                    'Ethnicity',
                    'LimitedEnglishProficiency',
                    'EconomicallyDisadvantaged',
                    'SpecialEdStatus'
                ]
                let rows = []
                let students = selectedStudents
                if(selectedStudents.length==0){
                    students = allStudents
                }
                students.forEach(student => {
                    let s = [
                        student.lastName,
                        student.firstName,
                        student.middleName,
                        student.sisID,
                    ]
                    if(student.gradeID){
                        if(student.gradeID>1){
                            s.push(student.gradeID-1)
                        } else {
                            s.push('K')
                        }
                    } else {
                        s.push('')
                    }
                    s.push(this.getLanguage(student.languageID,'value'))
                    s.push(student.password)
                    if(student.genderID){
                        s.push(this.getGender(student.genderID))
                    } else {
                        s.push('')
                    }
                    if(student.ethnicityID){
                        s.push(student.ethnicityID)
                    } else {
                        s.push('')
                    }
                    if(student.engProfID){
                        s.push(this.getYesNoNA(student.engProfID))
                    } else {
                        s.push('')
                    }
                    if(student.disadvID){
                        s.push(this.getYesNoNA(student.disadvID))
                    } else {
                        s.push('')
                    }
                    if(student.specialEdID){
                        s.push(this.getYesNoNA(student.specialEdID))
                    } else {
                        s.push('')
                    }
                    rows.push(s)
                })
                let filename = this.selectedSchool.name+' Summary - '+students.length+' Students'
                CsvExport.downloadArrayAsCSV([headers, ...rows], filename)
            },
            fixTotalUses(){
                this.$nextTick(function(){
                    if(this.$refs.totalUses){
                        let input = this.$refs.totalUses.$refs.input
                        input.style.padding = 0
                        input.style.maxHeight = "53px"
                        input.style.margin = "-10px 0"
                    }
                })
            },
            getAlternativeSubscriptionInformationInfo(institutionID){
                const productExclusionList = this.productExclusionList
                let subsAPI = this.getSubscriptionsEmployeeAPI(institutionID)
                this.$http.get(subsAPI,this.jtiHeader).then(response => {
                    const subTypeIDsToKeep = [
                        1,  // Site
                        5,  // Pilot
                        7,  // Employee Seed Program
                        10, // Teacher Plus
                        11, // Department
                        12, // External Access Partner
                        13  // Site Grant
                    ]
                    let loginIdMap = response.data.HashMap
                    let products = []
                    for(let loginID in loginIdMap){
                        let subs = loginIdMap[loginID]
                        subs.forEach(function(sub, index){
                            if(!subTypeIDsToKeep.includes(sub.licensingTypeID) || sub.isExpired){
                                // Remove Subscriptions We Don't Want to Display
                                subs.splice(index,1)
                            } else {
                                // Build out list of Products
                                if(!products.includes(sub.productID) && !productExclusionList.includes(sub.productID)){
                                    products.push(sub.productID)
                                }
                            }
                        })
                    }
                    this.employeeData.subscriptionMap = loginIdMap
                    this.employeeData.products = products
                }, error => {
                    console.log(error)
                })
            },
            getInviteStatusData(invite){
                let statusID = invite.inviteStatus
                // statusID = Math.floor(Math.random() * 6 + 1) // For Testing Purposes
                let statusObject = {
                    id: statusID,
                    sortOrder: 100,
                    color: null
                }
                const lastEmailSent = new Date(invite.mostRecentEmailSentOn).getTime()
                const now = Date.now()
                const fiveMin = 5 * 60 * 1000 // in Milliseconds
                if(!invite.inviteRegCode) {
                    // Expired RegCode
                    statusObject.sortOrder = 2
                    statusObject.icon = 'event_busy'
                    statusObject.label = this.$t('classMgmt.expiredInvite')
                    statusObject.tooltip = this.$t('classMgmt.expiredInviteTooltip')
                    statusObject.color = 'red'
                    statusObject.details = this.$t('classMgmt.expiredInviteDetails')
                    
                } else if(statusID <= 1 || statusID == 5){
                    // Pending (Default, Processed, Deferred)
                    if((invite.mostRecentEmailSentOn && lastEmailSent+fiveMin-now < 0) || statusID == 5){
                        statusObject.sortOrder = 3
                        statusObject.icon = 'warning'
                        statusObject.label = this.$t('classMgmt.deliveryIssue')
                        statusObject.tooltip = this.$t('classMgmt.deliveryIssueTooltip')
                        statusObject.color = 'red'
                        statusObject.details = this.$t('classMgmt.deliveryIssueDetails')
                    } else{
                        statusObject.sortOrder = 4
                        statusObject.icon = 'schedule_send'
                        statusObject.label = this.$t('classMgmt.statusPending')
                        statusObject.tooltip = this.$t('classMgmt.statusPendingTooltip')   
                    }
                } else if(statusID == 2 || statusID == 3){
                    // Delivered, Opened
                    statusObject.sortOrder = 5
                    statusObject.icon = 'check_circle'
                    statusObject.label = this.$t('classMgmt.statusDelivered')
                    statusObject.tooltip = this.$t('classMgmt.statusDeliveredTooltip')
                    statusObject.color = 'green'
                } else if(statusID == 6){
                    // Bounced
                    statusObject.sortOrder = 1
                    statusObject.icon = 'warning'
                    statusObject.label = this.$t('classMgmt.statusError')
                    statusObject.tooltip = this.$t('classMgmt.statusErrorTooltip')
                    statusObject.color = 'red'
                    statusObject.details = this.$t('classMgmt.statusErrorDetails',{email:invite.email})
                }
                return statusObject
            },
            getTotalStudentsInTable(){
                
                this.$nextTick(()=>{
                    if (this.$refs.studentsInTable) {
                        this.totalStudentsInTable = this.$refs.studentsInTable.innerHTML
                    } else {
                        this.totalStudentsInTable = 0
                    }
                })
            },
            getTotalnonStudentsInTable(){
                this.$nextTick(()=>{
                    if (this.$refs.nonStudentsInTable) {
                        this.totalNonStudentsInTable = this.$refs.nonStudentsInTable.innerHTML
                    } else {
                        this.totalNonStudentsInTable = 0
                    }
                    
                })                
            },
            handleDeepLink(){
                // Direct Student Navigation
                if(this.$route.query.student){
                    this.activeTab = this.tabIDs.STUDENT
                    let queryStudentID = this.$route.query.student
                    let queryStudent  = this.students.find(function(student){
                        return student.id == queryStudentID
                    })
                    if(queryStudent){
                        this.openDialog('editStudentModal',{users:queryStudent})
                    }
                } else if (this.$route.query.school) {
                    const schoolID = Number(this.$route.query.school)
                    this.selectedSchoolID = schoolID
                } else {
                    // Tab Controls
                    let tab = this.$route.query.tab
                    if(tab == 'students'){
                        this.activeTab = this.tabIDs.STUDENT
                    } else if (tab == 'teachers'){
                        this.activeTab = this.tabIDs.TEACHER
                    } else if (tab == 'admins'){
                        this.activeTab = this.tabIDs.ADMIN
                    }
                }
                // Modal Controls
                let modal = this.$route.query.modal
                if(modal == 'settings'){
                    this.openDialog('schoolSettingsModal')
                } else if(modal=='import'){
                    this.openDialog('importStudentModal')
                }
                // Experimental Mode
                if(this.$route.query.experimental){
                    this.experimentalMode = true
                }
                // Clean Up
                // if(Object.keys(this.$route.query).length && !this.isEmployeeOrCertifiedTrainer){
                //     this.$router.replace({query:{}})
                // }
            },
            hasLiscense(user, productId){
                if(this.isEmployeeOrCertifiedTrainer && this.useEmployeeData){
                    let hasProduct = false
                    if(Array.isArray(this.employeeData.subscriptionMap[user.id])){
                        this.employeeData.subscriptionMap[user.id].forEach(sub => {
                            if(sub.productID == productId){
                                hasProduct = true
                            }
                        })
                    }
                    return hasProduct
                } else {
                    let source = user.licenses.filter(l => l.matchingLicense).map(l => l.productId)
                    if(this.isEmployeeOrCertifiedTrainer){
                        source = user.licenses.map(l => l.productId)
                    }
                    if(source){
                        return source.includes(productId)
                    } else {
                        return false
                    }
                }
            },
            isAdminOnlyInstitute(school = this.selectedSchool){
                if (school) {
                    return school.typeId<4
                } else {
                    return false
                }
            },
            openMultiSubModal(teachers, productID) {
                console.log(teachers, productID)
                this.openDialog("multiSubAccessModal", {users: teachers})
            },
            openProductAccessModal() {
                    this.openDialog('productAccessModal');
            },
            async refreshInvites(){
                this.refreshingInvites = true
                await this.updateInvites()
                this.handleSuccess(this.$t('classMgmt.refreshInvitesSuccess'))
                this.refreshingInvites = false
            },
            resendInvite(users){
                const promises = users.map( user => {
                    return this.platform.resendInvite({loginId:user.id})
                })
                Promise.all(promises).then(() =>{
                    this.updateInvites()
                    this.handleSuccess(this.$tc('classMgmt.invitesResent',users.length).toLowerCase()+'. '+this.$tc('classMgmt.inviteDataDelay'))
                }, error => {
                    console.log(error)
                })
            },
            setActive(value){
                this.activeOrPending = value.toLowerCase()
            },
            async toggleProductAccess(teachers, productId, onOrOff, dontUpdate, updateInvites, subs){
                this.$store.commit('setMessageError', null)
                let institutionId = this.institutionID
                let teacherIds = this.getIDs(teachers)
                let tenantId = this.tenantID
                if(typeof onOrOff === 'undefined' || onOrOff === null){
                    if(!this.hasLiscense(teachers[0],productId)){
                        onOrOff = 1
                    } else {
                        onOrOff = 0
                    }
                }
                let subscriptionIds                
                if (subs && subs.length > 0) {
                    subscriptionIds = subs
                } else if (onOrOff) {
                    subscriptionIds = this.selectedSchool.products.filter(sub => {
                        return sub.productId == productId
                    }).map(s => s.subscriptionId);
                } else {
                    subscriptionIds = this.subscriptionList.filter(sub => {
                        return sub.productId == productId
                    }).map(s => s.id);
                }
                let message = ''
                let productName = this.productDisplayName(productId)
                let role = this.$tc('classMgmt.teachers',1).toLowerCase()
                if(updateInvites){
                    role = this.$t('classMgmt.invited')+' '+role
                }
                let self = this;
                (async function() {
                    if(onOrOff){
                        try {
                            await self.addTeachersToSubscription({ tenantId, institutionId, subscriptionIds, teacherIds })
                            subscriptionIds.length ? message = message + self.$t('classMgmt.subscriptionsUpdated') + ' ' : message = self.$t('classMgmt.productAccessGrantToast',{product:productName,num:1,role})
                            if(!updateInvites && !subscriptionIds.length){
                                message = message+' '+self.$t('classMgmt.productAccessEmail',{role})
                            }
                        } catch (error) {
                            if (error.response.data.MessageResponse.message.slice(0, 2) == 17) {
                                let alert = self.$t('error.error-17')
                                self.$store.commit('setMessageError', alert)
                                dontUpdate = true
                            }
                        }
                    } else {
                        await self.removeTeachersFromSubscription({ tenantId, institutionId, subscriptionIds, teacherIds })
                        subscriptionIds.length ? message = message + self.$t('classMgmt.subscriptionsUpdated') + ' ' : message = self.$t('classMgmt.productAccessRemoveToast',{product:productName,num:1,role})
                    }
                    if(!dontUpdate){
                        await self.updateModel()
                        self.handleSuccess(message)
                    }
                    if(updateInvites){
                        await self.updateInvites()
                        self.handleSuccess(message)
                    }
                })()
            },
            async updateInvites(){
                let inviteParams = {
                    institutionId:this.selectedSchoolID,
                    tenantId: this.tenantID
                }
                this.invitedUsers = await this.platform.fetchInvitedNonStudents(inviteParams)
                this.invitedUsers.forEach(invite => {
                    invite.inviteStatus = this.getInviteStatusData(invite)
                })
                if (this.modalParameters.users && this.passedOrSelectedUsers.length===1) {
                    let staleInvite = this.passedOrSelectedUsers[0]
                    let updatedInvite = this.invitedUsers.find( user => {
                        return staleInvite.id == user.id
                    })
                    if (updatedInvite) {
                        this.modalParameters.users = updatedInvite
                    }
                }
            },
            writeToRegCodes(newRegCodes){
                this.regCodes = newRegCodes
            }
        },
        watch: {
            activeOrPending(val){
                if(val=='active'){
                    this.paginationOptions.sortBy = 'lastName'
                } else {
                    this.paginationOptions.sortBy = 'email'
                }
            },
            activeTab() {
                this.selectedStudents = []
                this.selectedNonStudents = []
                this.studentSearch = '';
                this.nonStudentSearch = '';
            },
            'filteredStudents.length': function(){
                this.getTotalStudentsInTable()
            },
            invitedActiveType(users){
                if(users.length==0){
                    this.setActive('active')
                }
            },
            nonStudentSearch() {
                this.getTotalnonStudentsInTable();
            },
            selectedSchoolID: async function(schoolID){
                let school = this.schools.find(school => {
                    return school.id == schoolID
                })
                if(this.isAdminOnlyInstitute(school)){
                    this.activeTab = this.tabIDs.ADMIN
                    await this.updateModel()
                } else {
                    if(school){
                        await this.updateModel(school.id)
                    }
                }
                this.setActive('active')
                this.updateInvites()
            },
            studentSearch(){
                this.getTotalStudentsInTable()
            }
        },
        async created(){
            const auth = new Auth({
                auth: this.getClaimsAPI(),
                refresh: this.getRefreshAPI(),
                logging: this.getClientLogAPI()
            })
            auth.setClaims(this.claims)
            const platform = new ElPlatformRestApi({
                baseURL: this.getPlatformURL(),
                auth,
            })
            this.auth = auth
            this.$store.commit('setPlatform', platform)
            
            // Prepare Alternative Subscription Information Source For Employees
            let subId = this.$route.query.subId  || null
            let subsAPI = subId ? this.getSubscriptionsAPI(subId) : null

            let institutionId = Number(this.$route.query.institution || null)
            
            let defaultSub = {}

            let subs = []

            if(this.isEmployeeOrCertifiedTrainer && subsAPI){
                await this.$http.get(subsAPI,this.jtiHeader).then(response => {
                    subs = response.data.Subscription ? [response.data.Subscription] : []
                }, error => {
                    console.log(error)
                })
            } else if(this.subscriptionList){
                subs = this.subscriptionList
            }

            let activeSubs = []
            if(this.isEmployeeOrCertifiedTrainer){
                activeSubs = subs
            } else {
                let self = this
                activeSubs = subs.filter(function(sub){
                    return (sub.isActive && !sub.isExpired && !self.productExclusionList.includes(sub.productId))
                })
            }

            let currentProductID = this.getProductCode()
            defaultSub = activeSubs.find(function(sub){
                return currentProductID == sub.productId
            })
            if(!defaultSub ){
                defaultSub = activeSubs[0]
            }
            if(!institutionId && defaultSub && defaultSub.subscriptionMemberList){
                institutionId = defaultSub.subscriptionMemberList.sort(function(a,b){
                    return a.institutionTypeID - b.institutionTypeID
                })[0].institutionID
            }
            if(defaultSub){
                this.productId = defaultSub.productID || defaultSub.productId
                let subscriptionId = defaultSub.subscriptionID || defaultSub.id
                this.subscriptionId = subscriptionId
                let isEmployee = this.isEmployeeOrCertifiedTrainer;

                (async () => {
                    if(isEmployee && subsAPI){
                        await this.clearModel()
                        await this.fetchModel({ institutionId, subscriptionId, isEmployee })
                    }

                    await this.getProductData()
                    if(this.isDistrictAdmin){
                        this.activeTab=this.tabIDs.ADMIN
                    }
                    this.selectedSchoolID = institutionId || this.schools[0].id
                    this.tenantID = this.institutionList.find(i => i.id == this.selectedSchoolID)?.subscriptions[0]?.tenantId || defaultSub.tenantID || defaultSub.tenantId || null
                    this.handleDeepLink()
                    this.dataLoaded = true
                    //let subscriptionIds = this.subscriptionIds
                    //this.regCodes = await platform.fetchRegCodes({institutionId,subscriptionIds})
                })()
            } else {
                this.dataLoaded = true
            }
            // Pull down additional subscription information for employees
            if(this.$route.query.institution && this.isEmployeeOrCertifiedTrainer){
                this.getAlternativeSubscriptionInformationInfo(institutionId)
                this.employeeData.useEmployeeData = true
            }

        }
    }
</script>

<style lang="less">
    .action-bar {
            button {
                margin-left: 0px;
                margin-right: 0px;
            }
        }
    .school {
        margin-left: 40px;
    }
    .v-datatable thead th .header-icon {
        transform: none !important;
        opacity: 1 !important;
    }
    .userSearch {
        min-width: 175px;
        width: 100px;
        margin-top: -4px !important;
        padding-top: 0px !important;
    }
    .gradeLevelFilter {
        width: 100px;
        margin-left: 5px;
        padding-top: 17px;
    }
    .nonStudentTitle {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .selectedSchool{
        background: #e4f2fe;
    }
    .fullwidth {
        width: 100%;
    }
    .helpCursor {
        cursor: help;
    }
    .modal {
        .headline {
            background: #F5F5F5;
            border-bottom: solid 1px #BDBDBD;
        }
    }
    .passwordSheild {
        font-size: 20px;
        padding: 2px 0 0;
    }
    .pointer {
        cursor: pointer;
    }
    .roundedCorners {
        border-radius: 10px;
    }
    .inlineIcon{
        margin-bottom: -4px;;
    }

    .v-alert {
        border-radius: 5px;
    }
    .code {
        font-family: monospace;
        color: firebrick;
        font-weight: bold;
        background-color: #f5f5f5;
        padding: 4px 6px;
        border-radius: 5px;
    }
    .emptyClassOption {
        color: #2196f3 !important;
        font-size: 16px;
        font-weight: 500;
        padding: 0 10px !important;
        .btn {
            margin: 10px 20px;
            background: #BBDEFB;
            border-radius: 4px;
            padding: 20px 40px;
            min-height: 172px;
            .v-icon {
                font-size: 80px;
            }
        }
        :hover {
            background: #90CAF9;
            cursor: pointer;
        }
    }
    .product-indicator {
        width: 25px;
        margin-left: 5px;
    }
    .grey-product-indicator {
        filter: grayscale(1);
        opacity: 0.4;
    }
    .grey-product-indicator.reflex-leaf{
        opacity: 0.7;
    }
    .grey-product-indicator.gizmos-leaf{
        opacity: 0.45;
    }
    .grey-product-indicator.science4us-leaf{
        opacity: .9;
    }
    .permission-icon {
        width: 50px;
        margin-top: 8px;
    }
    .leaf-avatar {
        border-radius: 0px !important;
        width: 40px !important;
        height: 40px !important;
    }
    .linkTabs {
        a.activeTab{
            background-color: #90caf92a;
            border-radius: 50px;
        }
    }
    .cc-enabled-alert {
        text-decoration: none;
        background-color: #90caf92a;
        color: rgb(25, 118, 210);
        border-radius: 50px;
        cursor: pointer;
        font-size: 120%;
    }
    .large-chat-bubble {
        font-size: 150px !important;
    }
</style>
