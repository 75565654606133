 <template>
    <v-flex class="container">
        <v-flex v-for="productID in ownedProducts" :key="productID" class="item mt-2">
            <template v-if="!isProductLimitReached(productID) || !isProductDisabled(productID)">
                <img
                    @click="openProductSpecificModal(productID)"
                    :src="productImgPath(productID)" 
                    :class="[
                        {'grey-product-indicator': isProductDisabled(productID)}, 
                        {'pointer': editable},
                        productDisplayName(productID).toLowerCase()+'-leaf'
                    ]"
                    class="leaf-avatar" 
                    :alt="productDisplayName(productID)+' Logo'"
                    :disabled="isProductLimitReached(productID)"
                    data-test-id="profile-product-list-product-icon"
                />
                <v-tooltip bottom :key="user.sisID+'-'+productDisplayName(productID)+'-assignment-tooltip'" :disabled="!checkProductUpgrade(productID, user)">
                    <template #activator="{ on }">
                        <v-btn 
                            @click="openProductSpecificModal(productID)" 
                            :color="isProductDisabled(productID)?'grey':''" 
                            :class="[productColorClass(!isProductDisabled(productID) ? productID : null), {'disable-events': !editable}]"                        
                            v-on="on"
                            flat
                            small
                            data-test-id="profile-product-list-product-button-text"
                        >   
                            <v-icon v-if="isProductDisabled(productID)" small>remove_circle</v-icon>
                            <v-icon v-else-if="checkProductUpgrade(productID,user)" size="14" color="warning">fa-circle-up</v-icon>
                            <v-icon v-else small>check_circle</v-icon>
                            <span v-if="isTeacher && getActiveInstitutionSubscriptions(productID, selectedSchool).length > 1">
                                
                                &nbsp;{{`${getProductAccess(!isProductDisabled(productID)).short} (${getMatchingLicenses(productID, user).length}/${getActiveInstitutionSubscriptions(productID, selectedSchool).length})`}}
                            </span>   
                            <span v-else-if="isTeacher">&nbsp;{{getProductAccess(!isProductDisabled(productID)).short}}</span>                            
                            <span v-else>&nbsp;{{determineAssignment(user,productID).short}}<span v-if="editable">&nbsp;&#9662;</span></span>
                        </v-btn>     
                    </template>
                    <span v-if="!isTeacher">
                        {{$t('classMgmt.fraxUpgradeAlert', {student:user.firstName+' '+user.lastName, sector:determineAssignment(user,productID).label})}}
                    </span>
                </v-tooltip>
            </template>
            <v-tooltip bottom v-else>
                <template #activator="{ on }">
                    <img
                        :src="productImgPath(productID)" 
                        :class="[
                            {'grey-product-indicator': isProductDisabled(productID)}, 
                            {'pointer': editable},
                            productDisplayName(productID).toLowerCase()+'-leaf'
                        ]"
                        class="leaf-avatar" 
                        :alt="productDisplayName(productID)+' Logo'"
                        :disabled="isProductLimitReached(productID)"
                        v-on="on"
                    />
                    <div class="ma-auto limit-reached-alert" v-on="on">
                        {{$t('classMgmt.limitReached')}}
                    </div>                                  
                </template>
                <span>{{licenseLimitAlertMsg(productID)}}</span>
            </v-tooltip>
        </v-flex>
    </v-flex>
</template>

<script>
    import APIs from 'mixins/APIs'
    import Constants from 'mixins/Constants'
    import ProductInfo from 'mixins/ProductInfo'
    import SubscriptionHelper from 'mixins/SubscriptionHelper'
	export default {
        name: 'ProfileProductList',
        components: { },
        mixins: [APIs, Constants, ProductInfo, SubscriptionHelper],
        props: {
            altLicenseSource: Array,
            ownedProducts: Array,
            user: Object,
            checkProductUpgrade: {type: Function, default: ()=>false},
            determineAssignment: Function,
            editable: Boolean,
            isTeacher: Boolean,
            seatLimitReached: Array,
            selectedSchool: Object
        },
        data() {
            return {
            }
        },
		computed : {
            platform(){
                 return this.$store.state.platform
            },
            isAdmin(){
                return this.$store.state.classMgmt.isAdmin
            },
        },
        methods: {
            getMatchingLicenses(product, user) {
                if (user && user.licenses) {
                    return this.user.licenses.filter(l => {
                        return l.productId == product
                    })
                } else {
                    return []
                }

            },
            isProductDisabled(productID) {
                if(this.altLicenseSource && this.altLicenseSource.length){
                    let hasLicense = false
                    this.altLicenseSource.forEach(sub => {
                        if(sub.productId==productID){
                            hasLicense = true
                        }
                    })
                    return !hasLicense
                } else {
                    if (this.user && this.user.licenses) {
                        return !this.user.licenses.filter(l => l.matchingLicense).map(l => l.productId).includes(productID)
                    } else {
                        return true
                    }
                }
            },
            isProductLimitReached(product){
                let limitReached = false
                if (this.seatLimitReached){
                    this.seatLimitReached.forEach(prod => {
                    if (prod.id == product) {
                        limitReached = true
                    }
                })
                }
                return limitReached
            },
            licenseLimitAlertMsg(productID){
                let message = this.$t('classMgmt.licenseLimitReached')
                const found = this.seatLimitReached.find(x => x.id === productID)
                message = message.replace('{name}', found.name);
                message = message.replace('{teachersCap}', found.teachersCap);
                return message
            },
            openProductSpecificModal(productID){
                if (this.isTeacher){
                    if (this.getActiveInstitutionSubscriptions(productID, this.selectedSchool).length > 1) {
                        this.$emit("openMultiSubModal", productID)
                    } else {
                        this.$emit("toggleProductAccess", this.user, productID)
                    }
                } else {
                    if (this.editable){
                        this.$emit("openProductSpecificAssignmentModal", productID, [this.user])
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
        position: relative;
        padding: 0;
    }
    .disable-events {
        pointer-events: none
    }
    .limit-reached-alert {
        color: rgba(0,0,0,.54);
    }
</style>