<template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
  import APIs from 'mixins/APIs.js'
  import EnvironmentDetector from 'mixins/EnvironmentDetector'
  import Forwarding from 'mixins/Forwarding'
  import ProductInfo from 'mixins/ProductInfo'
  export default {
    name: 'Logout',
    mixins : [APIs, EnvironmentDetector, Forwarding, ProductInfo],
    computed : {
      ...mapGetters('platformData/profile', ['profile']),
      isAppUser(){
        return this.$route.params.appUser
      }
    },
    methods: {
      ...mapActions('platformData/app', ['clearModel']),
      ...mapActions('platformData/profile', ['removeProfile']),
      async returnUserToLogin(roleID){
        // Clean Up Data
        this.clearModel()
        
        this.$store.commit('setIgnoreReferrer', true)
        this.$store.commit('setJTI', null)
        this.removeProfile()
        // Reroute User
        const params = {environment: this.getEnvironment()}
      	if(typeof roleID == 'undefined'){
      		this.forward({ name: 'Login', params})
      	} else if(roleID == 2){
          if(this.isAppUser){
            this.forward({name: 'AppLogin', params})
          } else {
      		  this.forward({name: 'StudentLogin', params})
          }
      	} else if(roleID == 4){
      		this.forward({name: 'ParentLogin', params})
      	} else if(roleID >= 100){
      		this.forward({name: 'EmployeeLogin', params})
      	} else {
        	this.forward({name: 'TeacherLogin', params})
      	}
   		}
    },
    created: function() {
    	// Internalize Messaging
    	if(this.$route.query.e){
    		this.$store.commit('setMessageError', this.$route.query.e)
    	}
    	if(this.$route.query.s){
    		this.$store.commit('setMessageSuccess', this.$route.query.s)
    	}
    	if(this.$route.query.w){
    		this.$store.commit('setMessageWarning', this.$route.query.w)
    	}
      // Shutdown Intercom
      if(window.Intercom){
        window.Intercom('shutdown');
      }
    	// Find Out Who They Are
    	let roleID = this.profile.roleID || this.$route.query.r
      const environment = this.getEnvironment()
      this.$http.get(this.getClaimsAPI(environment)).then(response => {
        // User Has A Session
        const xclaims = this.decodeXclaims(response)
        const product = this.productEviroment(xclaims.curProd)
        const xclaimsJti = xclaims.jti
        if(xclaimsJti){
        	roleID = xclaims.roleID
        	let logoutEndpoint = this.getLogoutAPI(product)
        	if(roleID==2){
        		logoutEndpoint = this.getStudentLogoutAPI(product)
        	}
          this.$http.get(logoutEndpoint, {headers: {'X-CSRFToken': xclaimsJti}}).then(() => {
            // Successfully Logged Out
            this.returnUserToLogin(roleID)
          }).catch(() => {
            // Unsuccessful Log Out
            this.returnUserToLogin(roleID)
          })
        } else {
          // Couldn't find users jtiHeader
          this.returnUserToLogin(roleID)
        }
      }).catch(() => {
        // User Does Not Have A Session
        this.returnUserToLogin(roleID)
      })
    }
  }
</script>
