/**
 * Class containing common functionality for all PDF Documents being generated
 * by the admin app.
 **/

import jsPDF from 'jspdf'
import 'jspdf-autotable';
import imgs from 'utils/Imgs'
import _times from 'lodash/times'
import _defaultTo from 'lodash/defaultTo'

export default class PDFDocument {

  /**
   * Creates an instance of PDFDocument.
   * @param {Object} [config={}] config object with options for the document
   * @param {String} [config.filename=document] The filename of the document
   * @param {String} [config.lastUpdated=] Text label for the "last update" date
   * @param {Number} [config.padding=32] Amount of vertical and horizonal
   * padding used in the document
   * @param {String} [config.orientation=portrait] The name of the orientation
   * of the document
   * @param {String} [config.logo=reflex] The name of the logo to use in the
   * document
   * @memberof PDFDocument
   */
  constructor({
    filename = 'document',
    lastUpdated = '',
    padding = 32,
    orientation = 'portrait',
  } = {}) {
    this.filename = filename;
    this.lastUpdated = lastUpdated;
    this.padding = padding;

    let ori = { portrait: 'p', landscape: 'l' }[orientation] || 'p';
    this.pdf = new jsPDF(ori, 'pt', 'letter');
    this.width = this.pdf.internal.pageSize.getWidth();
    this.height = this.pdf.internal.pageSize.getHeight();

    this.logo = this.getLogoInfo();

    this.cursor = { x: padding, y: padding };
  }

  /**
   * Private reference to an iframe used to render the pdf when printing
   *
   * @static
   * @memberof PDFDocument
   */
  static _iframe = null

  /**
   * Presets of size/type/color config objects for text formatting, keyed by
   * simple titles
   *
   * @static
   * @memberof PDFDocument
   */
  static TextPresets = {
    'h2': { size: 16, type: 'bold', color: 25 },
    'h3': { size: 14, type: 'bold', color: 130 },
    'h4': { size: 12, type: 'bold', color: 130 },
    'div': { size: 8, type: 'normal', color: 180 },
    'b': { size: 8, type: 'bold', color: 100 },
    'lg': { size: 18, type: 'normal', color: 100 },
    'md': { size: 12, type: 'normal', color: 100 },
  }

  /**
   * Presets of width/color config objects for line formatting, keyed by simple
   * titles
   *
   * @static
   * @memberof PDFDocument
   */
  static LinePresets = {
    'sm': { width: .25, color: 180 },
    'md': { width: 1.2, color: 0 },
  }

  /**
   * Private reference to the previous cursor location (used for easily moving
   * back to the previous location, but only works once and is set to `null` if
   * the cursor has been moved back)
   *
   * @memberof PDFDocument
   */
  _previousCursorLocation = null;

  /**
   * Returns the src/width/height info for logo
   *
   * @returns {Object} The logo info in an object (defaults to the reflex logo)
   * @memberof PDFDocument
   */
  getLogoInfo() {
    return {
      src: imgs.exploreLearningLogo,
      width: 150,
      height: 32
    };
  }

  /**
   * Adjust the x and y positions of the document's cursor relative to the
   * current position
   *
   * @param {Object} position An object with `x` and `y` properties
   * @param {Integer} [position.x=0] The relative horizontal adjustment amount
   * @param {Integer} [position.y=0] The relative vertical adjustment amount
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  moveCursor({ x = 0, y = 0 }) {
    this._previousCursorLocation = {...this.cursor};
    this.cursor.x += x;
    this.cursor.y += y;
    return this;
  }

  /**
   * Set the x and y positions of the document's cursor
   *
   * @param {Object} position An object with `x` and `y` properties
   * @param {Number} position.x The horizontal position value
   * @param {Number} position.y The vertical position value
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  setCursor({ x, y }) {
    this._previousCursorLocation = {...this.cursor};
    if (typeof x !== 'undefined') {
      this.cursor.x = x;
    }
    if (typeof y !== 'undefined') {
      this.cursor.y = y;
    }
    return this;
  }

  /**
   * Set the cusor's x and y positions to the previous location (only able to
   * use once after moving the cursor, as the previous reference is then set to
   * the reverted cursor location)
   *
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  moveCursorBack() {
    this.cursor = {...this._previousCursorLocation};
    this._previousCursorLocation = {...this.cursor};
    return this;
  }

  /**
   * Adds a new page to the document and then sets the cursor to the document's
   * upper-left padding location
   *
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  newPage() {
    this.pdf.addPage();
    let x = this.padding;
    let y = this.padding;
    this.cursor = { x, y };
    return this;
  }

  /**
   * Add text to the document at the current cursor position, optionally
   * specifying a text preset for formatting
   *
   * @param {String|Number} text The text to render to the document
   * @param {String} [preset] Optional key string for the text preset to format
   * the text to
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  write(text, preset) {
    if (preset) {
      this.formatText({ preset });
    }

    let { x, y } = this.cursor;
    this.pdf.text(_defaultTo(text, ''), x, y);
    return this;
  }

  /**
   * Add a vertical or horizontal line to the document at the current cursor
   * position
   *
   * @param {Object} config { type = 'horizontal', length }
   * @param {String} [config.type=horizontal] Whether the line is horizontal
   * or vertical
   * @param {Number} config.length The length of the line
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  line({ type = 'horizontal', length }) {
    let { x, y } = this.cursor;
    if (type === 'horizontal') {
      this.pdf.line(x, y, x + length, y);
    } else if (type === 'vertical') {
      this.pdf.line(x, y, x, y + length);
    }
    return this;
  }

  /**
   * Render an image on the document
   *
   * @param {Object} config { src, width, height, fileType = 'jpeg' }
   * @param {String} config.src The data source of the image to render
   * @param {Number} config.width The width of the image to render
   * @param {Number} config.height The height of the image to render
   * @param {String} [config.fileType=jpeg] The file type of the image
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  render({ src, width, height, fileType = 'jpeg' }) {
    let { x, y } = this.cursor;
    this.pdf.addImage(src, fileType, x, y, width, height);
    return this;
  }

  /**
   * Render a table on the document
   *
   * @param {Object} config { headers = [], rows = [], config }
   * @param {String[]|Number[]} [config.headers=[]] An array of items with each
   * entry being a header value for each column
   * @param {Array[]} [config.rows=[]] An array of arrays with each array
   * containing a row's values for each column
   * @param {Object} [config.config] A table configuration object used by jspdf
   * autotable plugin
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  table({ headers = [], rows = [], config }) {
    let { padding, cursor } = this;
    let tableConfig = config || {
      styles: { overflow: 'linebreak' },
      margin: { horizontal: padding + 8, bottom: padding * 2 }
    };

    if (tableConfig.startY === undefined) {
      tableConfig.startY = cursor.y;
    }

    if (!headers.length) {
      headers = _times(rows[0].length);
      tableConfig.showHeader = 'never';
    }

    this.pdf.autoTable(headers, rows, tableConfig);

    return this;
  }

  /**
   * Render a rectangle to the document at the current cursor location
   *
   * @param {Object} config { width, height, color = [0, 0, 0] }
   * @param {Number} config.width The width of the rectangle
   * @param {Number} config.height The height of the rectangle
   * @param {Number[]} [config.color=[0,0,0]] The rgb values of the color of the
   * rectangle
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  rectangle({ width, height, color = [0, 0, 0] }) {
    this.pdf.setFillColor(...color);

    let { x, y } = this.cursor;
    this.pdf.rect(x, y, width, height, 'F');

    return this;
  }

  /**
   * Render a circle to the document at the current cursor location
   *
   * @param {Object} config { radius, color = [0, 0, 0], outline = false }
   * @param {Number} config.radius The radius of the circle
   * @param {Number[]} [config.color=[0,0,0]] The rgb values of the color of the
   * circle
   * @param {Boolean} [config.outline=false] Whether to render a filled-in
   * circle or an outlined circle
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  circle({ radius, color = [0, 0, 0], outline = false }) {
    this.pdf.setFillColor(...color);

    let { x, y } = this.cursor;
    this.pdf.circle(x, y, radius, outline ? 'D' : 'F');

    return this;
  }

  /**
   * Get the source data of the document
   *
   * @param {Object} [config={}] [{ autoPrint = false, newTab = false }={}]
   * @param {Boolean} [config.autoPrint=false] Whether or not to output the
   * document source data with autoprint tag (browsers use this to bring up the
   * print dialog automatically)
   * @param {Boolean} [config.newTab] Whether or not to open a new tab when
   * outputting the document's source data
   * @returns {String} The rendered document's source data
   * @memberof PDFDocument
   */
  getSource({ autoPrint = false, newTab = false } = {}) {
    if (autoPrint) {
      this.pdf.autoPrint();
    }
    return this.pdf.output((newTab) ? 'dataurlnewwindow': 'bloburi');
  }

  /**
   * Render and print the document by printing from an iframe or using an
   * autoprint tag if that fails
   *
   * @returns {Promise} A promise that the image with be rendered, loaded to the
   * iframe and then printed
   * @memberof PDFDocument
   */
  print() {
    if (PDFDocument._iframe) {
      document.body.removeChild(PDFDocument._iframe);
    }

    return new Promise((resolve, reject) => {
      let iframe = document.createElement('iframe');
      let style = 'position: absolute; top: -100vh; left: -100vw';
      iframe.setAttribute('style', style);
      document.body.appendChild(iframe);

      iframe.src = this.getSource({ autoPrint: false });
      iframe.onload = () => {
        try {
          iframe.contentWindow.print();
          resolve();
        } catch {
          iframe.onload = () => resolve();
          iframe.onerror = (e) => reject(e);
          iframe.src = this.getSource({ autoPrint: true });
          reject(new Error('print method not supported'));
        }
      }

      PDFDocument._iframe = iframe;
    })
  }

  /**
   * Renders and downloads the document
   *
   * @returns {undefined}
   * @memberof PDFDocument
   */
  download() {
    let name = this.filename;
    this.pdf.save(name.endsWith('.pdf') ? name : name + '.pdf');
  }

  /**
   * Get the current page number from the internal data of the pdf object
   *
   * @readonly
   * @returns {Number} The current page number
   * @memberof PDFDocument
   */
  get pageCount() {
    return this.pdf.internal.getNumberOfPages();
  }

  /**
   * Set the formatting for any lines rendered to the document
   *
   * @param {Object} [config] { preset, width = 1.2, color = 0 }
   * @param {String} [config.preset] The key for a preset config which overrides
   * the width and color params if used
   * @param {Number} [config.width=1.2] The width to format lines to
   * @param {Number} [config.color=0] The color to format lines to
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  formatLine({ preset, width = 1.2, color = 0 }) {
    if (preset) {
      this.formatLine(PDFDocument.LinePresets[preset]);
    } else {
      this.pdf.setLineWidth(width).setDrawColor(color);
    }
    return this;
  }

  /**
   * Set the formatting for any text rendered to the document
   *
   * @param {Object} [config] { preset, size = 14, type = 'normal', color = 0 }
   * @param {String} [config.preset] The key for a preset config which overrides
   * the size, type, and color params if used
   * @param {Number} [config.size=14] The size to format text to
   * @param {Number} [config.type=normal] The text type to format text to
   * @param {Number} [config.color=0] The color to format text to
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  formatText({ preset, size = 14, type = 'normal', color = 0 }) {
    if (preset) {
      this.formatText(PDFDocument.TextPresets[preset]);
    } else {
      let colorParam = Array.isArray(color) ? color : [color];
      this.pdf.setFontSize(size).setFontType(type).setTextColor(...colorParam);
    }
    return this;
  }

  /**
   * Get the width of provided text (helpful when needing to know how far to
   * move the cursor after having rendered text or for center-aligning text)
   *
   * @param {String} text The text to get the width of
   * @returns {Number} The width of the provided text
   * @memberof PDFDocument
   */
  getTextWidth(text) {
    let { pdf } = this;
    let scale = pdf.internal.scaleFactor;
    return pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / scale;
  }

  /**
   * Resize the current font size so that a provided text will be the provided
   * width when rendered
   *
   * @param {Object} config { text, width }
   * @param {String|Number} config.text The text to get the width of when
   * determining the font size
   * @param {Number} config.width The width to resize the text to
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  resizeToWidth({ text, width }) {
    let textWidth = this.getTextWidth(text);
    let fontSize = this.pdf.internal.getFontSize();
    this.pdf.setFontSize((width / textWidth) * fontSize);

    return this;
  }

  /**
   * Renders a header for a report document taking in an object with label info
   * the use as text in the header
   *
   * @param {Object} labels Keyed object of labels to use in the header
   * @param {String} labels.title The title of the document
   * @param {String} labels.superText The text to put at the top of the header
   * @param {String} labels.subText The text to put at the bottom of the header
   * @param {String} labels.bottomLeft The text on the left and below the header
   * @param {String} labels.bottomRight The text on the right and below the
   * header
   * @param {String} labels.filters The text to use to describe the filters
   * being used in the report
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  drawHeader({ title, superText, subText, bottomLeft, bottomRight, filters}) {
    let { padding, width } = this;
    let lineOffset = padding + 20;

    this.setCursor({ x: padding + 16, y: padding + 16 })
      .render(this.logo)
      .moveCursor({ x: this.logo.width + 20, y: -5 });

    if (superText) {
      this.moveCursor({ y: 10 }).write(superText, 'h4')
    }

    this.moveCursor({ y: 20 })
      .write(title, 'h2')
      .moveCursor({ y: 16 })
      .write(subText, 'h3');

    if (superText) {
      this.moveCursor({ y: -10 });
    }

    if (filters) {
      this.drawFilterDescription({ filters });
    }

    this.setCursor({ x: lineOffset }).moveCursor({ y: 10 });

    if (bottomLeft) {
      this.write(bottomLeft, 'h4');
    }

    if (bottomRight) {
      let length = this.pdf.getStringUnitWidth(bottomRight) * 12; // 'h4' size
      this.setCursor({ x: width - lineOffset - length })
        .write(bottomRight, 'h4')
        .setCursor({ x: lineOffset })
    }

    this.moveCursor({ y: 4 })
      .formatLine({ preset: 'sm' })
      .line({ length: width - lineOffset * 2 })
      .setCursor({ x: padding, y: padding + 68 });

    return this;
  }

  /**
   * Renders a filter description for a report document
   *
   * @param {Object} config { filters }
   * @param {String} config.filters The text to use for the filters
   * @returns {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  drawFilterDescription({ filters }) {
    let { width } = this;
    let splitDesc = this.pdf.splitTextToSize(filters, 350);

    let { x, y } = this.cursor;

    this.setCursor({ x: width - 220, y: 46 })
      .write('* Filters applied to report:', 'b')
      .moveCursor({ y: 10 })
      .write(splitDesc, 'div');

    this.setCursor({ x, y });

    return this;
  }

  /**
   * For each page in the pdf document, draws a border around the page's content
   * with a copywrite message and the date last updated at the bottom of the
   * page
   *
   * @return {PDFDocument} Self reference
   * @memberof PDFDocument
   */
  drawBorder() {
    let { width, height, padding, pageCount, pdf } = this;
    let now = new Date()
    let footerText = `© `+now.getFullYear()+` ExploreLearning`;
    let w = width - padding * 2;
    let h = height - padding * 2;

    while (pageCount) {
      pdf.setPage(pageCount--);
      this.formatLine({ preset: 'md' });
      pdf.roundedRect(padding, padding, w, h, 4, 4, 'S');
      this.formatText({ preset: 'div' });
      let footerW = pdf.getStringUnitWidth(footerText) * 8;
      pdf.text(footerText, (width - footerW) / 2, height - padding - 4);
    }

    return this;
  }
}
    