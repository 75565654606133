<template>
	<form id="accountInfoForm" @submit.prevent="validate">
		<v-layout wrap align-center>
			<v-flex xs12 :md9="hasImage" :lg10="hasImage">
				<v-layout wrap>
					<Headline :headline="$t('accountInfo.header')" :logo="getEnvironment()"></Headline>
					<TrialStepper v-if="!isInvitedUser" :currentStep="1" />
					<Messaging outline></Messaging>
					<v-flex xs12 class="body-1 grey--text text--darken-2 uppercase">
						{{$t('accountInfo.contactDetails')}}
					</v-flex>
					<v-flex xs3 sm2>
						<v-autocomplete
							v-model="titleID"
							v-bind:items="userTitles"
							item-value="id"
							item-text="label"
							:label="$t('form.title')">
						</v-autocomplete>
					</v-flex>
					<v-flex xs9 sm5>
						<v-text-field
							v-model="firstName"
							:label="$t('form.firstName')"
							v-validate="'required|max:30|noTags|noEscapes'"
							name="first name"
							:error-messages="errors.collect('first name')">
						</v-text-field>
					</v-flex>
					<v-flex xs12 sm5>
						<v-text-field
							v-model="lastName"
							:label="$t('form.lastName')"
							v-validate="'required|max:30|noTags|noEscapes'"
							name="last name"
							:error-messages="errors.collect('last name')">
						</v-text-field>
					</v-flex>
					<v-flex xs12 :md6="!emailCheck" v-if="emailCheck || (!this.isTrialOrFreemium && !isInvitedUser)">
						<v-text-field
							v-model="email"
							:label="$t('form.email')"
							browser-autocomplete="new-password"
							v-validate="'required|email|max:100'"
							name="email"
							:error-messages="errors.collect('email')">
						</v-text-field>
					</v-flex>
					<v-flex xs9 md4 v-if="!isParentReg && !isInvitedUser">
						<v-text-field
							v-model="phone"
							:label="$t('form.phoneNum')"
							v-validate="'required|numeric'"
							name="phone number"
							:error-messages="errors.collect('phone number')">
						</v-text-field>
					</v-flex>
					<v-flex xs3 md2 v-if="!isParentReg && !isInvitedUser">
						<v-text-field
							v-model="ext"
							:label="$t('form.ext')"
							v-validate="'noTags'"
							name="phone extension"
							:error-messages="errors.collect('phone extension')">
						</v-text-field>
					</v-flex>
					<v-flex xs12 class="body-1 grey--text text--darken-2 mt-4">
						<span class="uppercase">{{$t('accountInfo.createLogin')}}</span>
					</v-flex>
					<v-flex xs12>
						<v-text-field
							v-model="userName"
							:label="$t('form.userName')"
							browser-autocomplete="new-password"
							v-validate="'required|min:4|max:50|noTags|noEscapesExceptAt|noSpaces'"
							name="username"
							:error-messages="errors.collect('username')"
							v-on:blur="checkAvalbility(false)"
							:append-icon="userNameAvalible ? '' : 'cancel'"
							:error="!userNameAvalible"
							:rules="userNameAvalible ? [] : [()=>($t('error.usernameUnavailable'))]">
						</v-text-field>
					</v-flex>
					<v-flex xs12>
						<PasswordValidationIndicator :password="password" classes="mt-2 pt-1 pb-2"/>
					</v-flex>
					<v-flex xs12>
						<v-text-field
							v-model="password"
							:label="$t('form.password')"
							browser-autocomplete="new-password"
							v-validate="veeValidatePasswordRules"
							name="password"
							:error-messages="errors.collect('password')"
							:append-icon="passwordVisible ? 'visibility_off' : 'visibility'"
							@click:append="() => (passwordVisible = !passwordVisible)"
							:type="passwordVisible ? 'text' : 'password'">
						</v-text-field>
					</v-flex>
					<v-flex v-if="showPromo" xs12 class="body-1 grey--text text--darken-2 mt-4">
						<span class="uppercase">{{$t('form.promoCode')}}</span>
					</v-flex>
					<v-flex v-if="showPromo" xs12>
						<v-text-field
							v-model="promoCode"
							:label="$t('form.promoCode')"
							v-on:blur="checkPromoCode"
							:append-icon="promoCodeValid ? '' : 'cancel'"
							:error="!promoCodeValid"
							:rules="promoCodeValid ? [] : [()=>($t('error.invalidPromoCode'))]">
						</v-text-field>
					</v-flex>
					<v-flex xs6 v-if="flow=='grant-act'">
						<v-btn color="grey lighten-2" large :to="{name:'GrantActivation', params:{grantCode:$store.state.grant.regCode}}">
							{{$t('navigation.back')}}
						</v-btn>
					</v-flex>
					<v-flex xs6 v-else-if="this.isTrialOrFreemium && !showPromo">
						<v-btn large flat color="primary" @click="promoBtnClicked=true">
							{{$t('accountInfo.havePromo')}}
						</v-btn>
					</v-flex>
					<v-flex xs12 class="text-xs-right">
						<v-btn color="primary" large id="accountInfoFormSubmit" type="submit" :disabled="checkingUserName || checkingPromoCode || loading">
							{{submitButtonText}}
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>
			<v-flex v-if="hasImage" md3 lg2 fill-height class="hidden-sm-and-down">
				<img  v-if="currentEnvironment=='reflex'" id="ballon" class="side-image" src="../../assets/reflex/img/balloon.png" alt="Hot Air Balloon">
				<img v-else-if="currentEnvironment=='frax'" id="survey-cat" src="../../assets/frax/img/surveycat.png" alt="Survey Cat">
			</v-flex>
		</v-layout>
	</form>
</template>

<script>
	import AutoFill from 'mixins/AutoFill'
	import APIs from 'mixins/APIs'
	import Constants from 'mixins/Constants'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import ErrorHandling from 'mixins/ErrorHandling'
	import LoginDetector from 'mixins/LoginDetector'
	import Headline from 'components/layout/Headline'
	import Messaging from 'components/layout/Messaging'
	import PasswordValidation from 'mixins/PasswordValidation'
  	import PasswordValidationIndicator from 'components/layout/PasswordValidationIndicator'
	import TrialStepper from 'components/registration/TrialStepper'
	import UserNameAvalibility from 'mixins/UserNameAvalibility'
	import Forwarding from 'mixins/Forwarding'
	import { mapActions, mapGetters, mapState } from 'vuex'
  	export default {
		name: 'AccountInfo',
		metaInfo : {
            title : 'Account Information'
        },
		components: {
			Headline,
			Messaging,
			PasswordValidationIndicator,
			TrialStepper
		},
		mixins: [AutoFill, APIs, Constants, EnvironmentDetector, ErrorHandling, LoginDetector, PasswordValidation, UserNameAvalibility, Forwarding],
		data () {
			return {
				titleID : '',
				firstName : '',
				lastName : '',
				email : '',
				emailCheck : false,
				phone : '',
				ext : '',
				userName : '',
				password : '',
				passwordVisible: false,
				productsRegistered: [],
				promoCode: '',
				promoBtnClicked: false,
				checkingPromoCode: false,
				promoCodeValid : true,
				regCode : '',
				loading : false
			}
		},
		computed : {
			...mapGetters('platformData/profile', ['profile']),
			...mapState('platformData/app', ['jti']),
			hasImage(){
				let hasImage = ['frax','reflex']
				return hasImage.includes(this.getEnvironment())
			},
			isInvitedUser(){
				let invitedRegCodeTypes = [
					3,	// Admin Invite (Invite System)
					15,	// Teacher Invite (Invite System)
				]
				return invitedRegCodeTypes.includes(this.regTypeID)
			},
			isParentReg(){
				return this.regTypeID == 6
			},
			isTrialOrFreemium(){
				return this.regTypeID == 1 || this.regTypeID == 12
			},
			regTypeID(){
				return this.$store.state.formData.regCodeType
			},
			showPromo(){
				if(this.promoCode || this.promoBtnClicked){
					return true
				} else {
					return false
				}
			},
			submitButtonText(){
				if(this.flow=='grant-act' || this.isInvitedUser){
					return this.$t('form.submit')
				} else {
					return this.$t('navigation.next')
				}
			}
		},
		methods: {
			...mapActions('platformData/profile', ['removeProfile']),
			checkPromoCode() {
				if(this.promoCode){
					this.checkingPromoCode = true
					this.$http.get(this.getValidatePromoCodeTrialAPI(this.promoCode)).then(response => {
						if(!response.data.PromoCodeResponse.valid){
							this.promoCodeValid = false
						}
						this.checkingPromoCode = false
					}).catch(() => {
						this.$store.commit('setMessageError', this.$t('error.404'))
						this.checkingPromoCode = false
					})
				} else {
					this.promoCodeValid = true
				}
			},
			validate: async function (){
				this.loading = true
				await this.checkAvalbility(false)
				var valid = await this.$validator.validateAll()
				if(valid && this.userNameAvalible && this.promoCodeValid){
					var accountInfo = {
						userName : this.userName,
						firstName : this.firstName,
						lastName : this.lastName,
						password : this.password,
						email : this.email,
						titleID : this.titleID
					}
					let correctAPI = null
					let regCode = Array.isArray(this.regCode) ? this.regCode[0] : this.regCode
					if(this.flow=='reg'){
						if (this.isTrialOrFreemium){
							correctAPI = this.getAccountCreationAPI(regCode)
						} else if (this.isInvitedUser) {
							correctAPI = this.getClaimPendingUserAPI(regCode, this.emailCheck)
						} else {
							correctAPI = this.getAccountCreationFromInviteAPI(regCode)
						}
					} else {
						correctAPI = this.getAccountCreationAPI()
					}
					await this.$http.post(correctAPI, accountInfo).then(response => {
						this.$store.commit('setJTI', this.decodeXclaims(response).jti)
						this.$store.commit('setTitleID',this.titleID)
						this.$store.commit('setFirstName', this.firstName)
						this.$store.commit('setLastName', this.lastName)
						this.$store.commit('setEmail', this.email)
						this.$store.commit('setPhone', this.phone)
						this.$store.commit('setUsername', this.username)
						if(this.ext){
							this.$store.commit('setPhoneExt', this.ext)
						}
						if(this.promoCode){
							this.$store.commit('setTrialPromoCode', this.promoCode)
						}
						if(this.isTrialOrFreemium){
							this.$router.push({name:'RegSchoolInfo'})
						} else if (this.flow=='grant-act'){
							this.$router.push({name:'GrantCreated'})
						} else if (this.isInvitedUser){
							this.$router.push({name:'Home', query:{s:'welcome'}})
						} else {
							if(Array.isArray(this.regCode)){
								// RegCode Registration Process
								this.regCode.shift()
								let self = this
								let regCodeURLs = this.regCode.map(function(x){return self.getLinkAccountToSubscriptionAPI(x)})
								let promises = regCodeURLs.map(function(x){return self.$http.post(x,{},self.jtiHeader)})
								Promise.all(promises).then(() => {
									this.$http.get(this.getRefreshAPI(),this.jtiHeader).then(response=>{
										let userInfo = this.decodeXclaims(response)
										this.$store.commit('setJTI', userInfo.jti)
										if(this.isParentReg){
											// Parent Registration
											this.$store.commit('setAllowConfirm', true)
											this.go(this.getReportingAppURL())
										} else {
											this.$router.push({name:'Home', params:{newSub:{type:'subscription',productIDs:this.productsRegistered}}})
										}
									}).catch(() => {
										this.$router.push({name:'Home', params:{newSub:{type:'subscription',productIDs:this.productsRegistered}}})
									})
								}).catch(() => {
									this.$store.commit('setMessageError', this.$t('error.unableToLink'))
								})
							} else {
								if(this.isParentReg){
									// Parent Registration
									this.$store.commit('setAllowConfirm', true)
									this.go(this.getReportingAppURL())
								} else {
									this.$router.push({name:'Home', params:{newSub:{type:'subscription',productIDs:this.productsRegistered}}})
								}
							}
						}
						if(typeof dataLayer != 'undefined'){
							var dataObject = {
								event : 'trial_2a',
								product : this.currentEnvironment,
								category : 'sign_up'
							}
							window.dataLayer.push(dataObject);
						}
					}, error => {
						let response = error.response
						this.handleError(response)
					})
				}
				this.loading = false
			}
		},
		created(){
			if(this.flow=='grant-act'){
				if(this.$store.state.grant.regCode){
					this.autoFill()
					this.checkAvalbility()
				} else {
					this.$router.replace({name:'GrantActivation'})
				}
			} else if(this.isInvitedUser){
				this.regCode = this.$store.state.formData.regCode
				if(this.loggedIn){
					this.$http.get(this.getLogoutAPI(),this.jtiHeader).then(() => {
						this.removeProfile()
					}, error => {
						this.removeProfile()
						console.log(error.response)
					})
				}

				if(this.$route.query.emailCheck){
					this.emailCheck = true
					this.autoFill()
				} else if (this.profile.email || this.$store.state.formData.email && !this.email) {
						this.email = this.profile.email || this.$store.state.formData.email
				}
			} else {
				if(this.$store.state.formData.regCode || this.jti || this.flow!=='reg' || this.profile.email || this.$store.state.formData.email){
					this.autoFill()
					this.checkAvalbility()
				} else {
					this.$router.replace({name:'RegCode', params: {environment: this.getEnvironment()}})
				}
			}
		}
  	}
</script>

<style scoped>
	#ballon {
		-webkit-transform: scaleX(-1);
  		transform: scaleX(-1);
		margin-left: 10px;
	}
</style>
