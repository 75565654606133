<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('classMgmt.editSectorHeader')"
        :subHeadline="userCountOrName(selectedStudents,$tc('classMgmt.students',selectedStudents.length))"
        size="600"
        @closeDialogs="closeDialogs()"
    > 
        <v-card-text>
            <v-alert
                :value="hasGradeLevelLessThan(selectedStudents,4)"
                color="warning"
                icon="warning"
                outline
                class="mb-3"
            >
                {{$t('classMgmt.fraxNotRecommended')}}
            </v-alert>
            <v-select
                v-model="editSectorSelection"
                :items="availableSectors"
                item-text="label"
                item-value="id"
                :label="$tc('classMgmt.assignment', localizationConstants.SINGLE)"
                prepend-icon="assignment"
                :item-disabled="disableSectorSelection"
            >
                <template #item="sector">
                    <v-list-tile-content>
                        <v-list-tile-title>{{ sector.item.label }}</v-list-tile-title>
                    </v-list-tile-content> 
                    <v-list-tile-avatar>
                        <span v-if="disableSectorSelection(sector.item)">
                            <v-icon small color="error">do_disturb_on</v-icon>
                        </span>
                    </v-list-tile-avatar>
                </template>
                <template v-if="hasDisabledOptions" #append-item>
                    <v-divider class="mt-2"></v-divider>
                    <v-list-tile>
                        <v-list-tile-content class="caption grey--text">
                            <span>
                                <v-icon small color="error">do_disturb_on</v-icon>
                                Sector 2 can only be assigned after students have completed Sector 1.
                            </span>
                        </v-list-tile-content>
                    </v-list-tile>
                </template>
            </v-select>
            <p v-show="getSectorDesc(editSectorSelection)" class="body-1 pl-4 ml-2 mt-1">
                {{getSectorDesc(editSectorSelection)}}
            </p>
            <SeatCapAlert
                @updateButtonState="this.updateButtonState"
                :determineAssignment="determineAssignment"
                :modalSpecificSelection="editSectorSelection"
                :productID="2"
                :seatInfo="seatInfo"
                :selectedSchool="selectedSchool"
                :selectedStudents="selectedStudents"
            ></SeatCapAlert>
            <v-alert
                :value="editSectorAlert && ((hasEnoughSeats && editSectorSelection>0) || editSectorSelection===0)"
                color="warning"
                icon="warning"
                outline
            >
                <p v-if="selectedStudents.length>1">{{$t('classMgmt.changingMultipleStudentAssignments')}}</p>
                <p v-if="editSectorSelection==0 && (selectedStudents.length>1 || determineAssignment(selectedStudents[0],2).code!=0)">
                    {{$t('classMgmt.settingNoAssignment')}}
                </p>
                <p v-if="studentSectorReduced">{{ $tc('classMgmt.studentSectorReduction',selectedStudents.length) }}</p>
                <v-checkbox
                    v-model="editSectorUnderstand"
                    color="warning"
                >
                    <template #label>
                        <span class="warning--text body-1">{{$t('classMgmt.iUnderstand')}}</span>
                    </template>
                </v-checkbox>
            </v-alert>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" 
                @click="callUpdateSectors(selectedStudents,editSectorSelection, true)" 
                :disabled="loading || disableSectorSelection({id:editSectorSelection}) || editSectorSelection===null || (editSectorAlert && !editSectorUnderstand) || (!hasEnoughSeats && editSectorSelection>0)" 
                class="elevation-0">
                <v-icon v-show="!loading">check</v-icon>
                <v-icon v-show="loading">fas fa-circle-notch fa-spin</v-icon>
                &nbsp;{{$t('classMgmt.accept')}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import Constants from 'mixins/Constants'
    import SeatCapAlert from 'components/modals/SeatCapAlert'

	export default {
        name: 'EditSectorModal',
        components: { UserManagmentModal, SeatCapAlert },
        mixins: [Constants],
        props: {
            subMaxSector: Number,
            determineAssignment: Function,
            hasGradeLevelLessThan: Function,
            loading: Boolean,
            seatInfo: Function,
            selectedSchool: Object,
            selectedUsers: Array,
            studentSectorData: Array,
            userCountOrName: Function,
            value: Boolean,
        },
        data() {
            return {
                deleteStudents: false,
                editSectorSelection: null,
                editSectorUnderstand: false,
                selectedStudents: [],
                hasEnoughSeats: true,
            }
        },
		computed : {
            availableSectors(){
                let upperLimit = this.subMaxSector
                if(!Number.isInteger(upperLimit)){
                    upperLimit = 0
                }
                return this.sectorIDs.slice(0,upperLimit+1)
            },
            editSectorAlert(){
                return (this.selectedStudents.length>1) ||
                    this.studentSectorReduced ||
                    (this.editSectorSelection==0 && (this.selectedStudents.length>1 || (this.selectedStudents[0] && this.determineAssignment(this.selectedStudents[0],2).code !=0)))
            },
            hasDisabledOptions(){
                let hasDisabled = false
                this.availableSectors.forEach(sector=>{
                    hasDisabled = hasDisabled || this.disableSectorSelection(sector)

                })
                return hasDisabled
            },
            localizationConstants() {
                return {
                    NONE: 0,
                    SINGLE: 1,
                    MULTIPLE: 2
                }
            },
            platform(){
                 return this.$store.state.platform
            },
            sectorDataForSelectedStudents(){
                let studentIDs = this.selectedStudents.map(student => (student.id))
                return this.studentSectorData.filter(student=>{
                    return studentIDs.includes(student.userID)
                })
            },
            studentSectorReduced(){
                if(this.editSectorSelection){
                    let highestCurrentSector = 0
                    this.selectedStudents.forEach(student => {
                        let currentCode = this.determineAssignment(student,2).code
                        if(currentCode && currentCode > highestCurrentSector){
                            highestCurrentSector = this.determineAssignment(student,2).code
                        }
                    })
                    return this.editSectorSelection < highestCurrentSector
                } else {
                    return false
                }
            }
        },
        watch: {
            // If a user changes assignment after clicking that they understand makes them click it again
            editSectorSelection : function(){
                this.editSectorUnderstand = false
            },
            value(val){
                if (val){
                    this.editSectorUnderstand = false
                    this.selectedStudents = this.selectedUsers
                    if(this.selectedStudents.length==1){
                        this.editSectorSelection = Number(this.determineAssignment(this.selectedStudents[0],2).code)
                    } else {
                        this.editSectorSelection = null
                    }
                }
            },
        },
        methods: {
            closeDialogs() {
                this.$emit('closeDialogs', ['editSectorModal'])
            },
            disableSectorSelection(sector){
                let minimumCompletedSector = 0
                if(this.sectorDataForSelectedStudents.length && this.sectorDataForSelectedStudents.length == this.selectedUsers.length){
                    minimumCompletedSector = this.sectorDataForSelectedStudents[0].maxSectorCompleted
                    if(this.sectorDataForSelectedStudents.length > 1){
                        this.sectorDataForSelectedStudents.forEach(student=>{
                            if(student.maxSectorCompleted < minimumCompletedSector){
                                minimumCompletedSector = student.maxSectorCompleted
                            }
                        })
                    }
                }
                return sector.id >  minimumCompletedSector+1
            },
            updateButtonState(hasEnoughSeats){
                this.hasEnoughSeats = hasEnoughSeats
            },
            callUpdateSectors(students, sectorID, maintainDialogs) {
                this.$emit('updateSectors', students, sectorID, maintainDialogs)
            },
        }
	}
</script>

<style lang="less" scoped>
</style>