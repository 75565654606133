// Please update the test spec and run `npm run test:unit` after any changes

import Fuse from 'fuse.js';
import _each from 'lodash/each';
import _keys from 'lodash/keys';
import _trim from 'lodash/trim';
import _map from 'lodash/map';
import _inRange from 'lodash/inRange';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import _upperCase from 'lodash/upperCase';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';
import _includes from 'lodash/includes';
import _without from 'lodash/without';

export function parseHeaders(headers, isClassCodeEnabled) {
  // check for duplicate header names
  const duplicates = _uniq(_filter(headers, (name, index) => {
    return _includes(headers, name, index + 1);
  }));
  if (duplicates.length) {
    let alert = this.$t('dropzoneImport.duplicate_headers');
    alert = alert.replace('{headers}', `"${duplicates.join('", "')}"`);
    const title = 'The file provided has duplicate headers';
    return { valid: false, alert: { title, message: alert } };
  }

  // attempt to map the provided headers to the column names
  let result = _parseColumnNames(headers, 0.1);
  if (!result.valid) {
    // if the high-threshold parse failed, run it again with a lower threshold
    result = _parseColumnNames(headers, 0.5);
    if (result.valid) { // and provide an alert to the user if successful
      result.alert = {
        title: 'We took our best guess at the column names provided',
        message: this.$t('dropzoneImport.guessed_columns')
      };
    } else { // otherwise, alert that the file isn't formatted correctly
      result.alert = {
        title: 'The file provided has data errors',
        message: this.$t('dropzoneImport.bad_file_format')
      };
    }
  }

  // check for required header names
  const parsedHeaders = _keys(result.columns);
  const missingHeaders = _filter(isClassCodeEnabled ? classCodeEnabledRequiredFields : RequiredFields, field => {
    return !_includes(parsedHeaders, field);
  });
  if (missingHeaders.length) {
    let alert = this.$t('dropzoneImport.missing_headers');
    // capitalize the first letter of each missing header name
    _map(missingHeaders, h => h.charAt(0).toUpperCase() + h.slice(1));
    alert = alert.replace('{headers}', `"${missingHeaders.join('", "')}"`);
    const title = 'The file provided has missing required columns';
    return { valid: false, alert: { title, message: alert } };
  }

  return result;
};

/**
 * Trims the given text and removes any unknown characters (�)
 * @param {Array} dirtyData the dirty data from the user to clean
 * @return {undefined}
 */
const _cleanInputData = (dirtyData) => {
  _each(dirtyData, (row, index) => {
    _each(row, (value, key) => {
      dirtyData[index][key] = value.trim().replace(/\uFFFD/g, '');
    });
  });
};

/**
 * checks for any diacritics and returns an error message if diacritics are found
 * @param {Array} pwords the passwords in the file
 */
export function validateNoDiacritics(pwords) {
  const cleanedPwords = _without(pwords, undefined, '');
  const diacritics = 'áàâäãéèëêíìïîóòöôõúùüûñçăşţ';

  const badPwords = cleanedPwords.reduce((acc, word) => {
    const chars = word.split('').map(c => c.toLowerCase());
    const hasDiacritic = chars.find(c => diacritics.includes(c));
    return hasDiacritic ? [...acc, word] : acc;
  }, []);

  const alert = this.$t('dropzoneImport.no_diacritics');
  return badPwords.length
    ? [alert.replace('{fields}', `"${badPwords.join('", "')}"`)]
    : null;
};

/**
 * returns true if there are no matching passwords in the params
 * @param {Array} pwords an array of passwords to check
 */
export const validateNoMatchingPasswords = (pwords) => {
  const cleanedPwords = _without(pwords, undefined, '');

  if (cleanedPwords.length) {
    const duplicates = _uniq(_filter(pwords, (name, index) => {
      return _includes(pwords, name, index + 1);
    }));

    return !(duplicates.length > 0);
  } else {
    return true;
  }
};

export function validateCharacterCounts({ data, columns }) {
  const _checkCharacterCount = (count, items) => {
    const invalidItems = [];
    items.forEach((item = '') => {
      if (item.length > count) {
        invalidItems.push(item);
      }
    });
    return invalidItems;
  };

  const characterCountErrors = [];
  _cleanInputData(data);

  // check firstName - must be 30 characters or less
  const firstNames = data.map(row => row[columns.firstName]);
  const longFNames = _checkCharacterCount(30, firstNames);
  if (longFNames.length) {
    const alert = this.$t('dropzoneImport.too_many_fname_characters');
    characterCountErrors.push(alert.replace('{fields}', `"${longFNames.join('", "')}"`));
  }

  // check lastName - must be 30 characters or less
  const lastNames = data.map(row => row[columns.lastName]);
  const longLNames = _checkCharacterCount(30, lastNames);
  if (longLNames.length) {
    const alert = this.$t('dropzoneImport.too_many_lname_characters');
    characterCountErrors.push(alert.replace('{fields}', `"${longLNames.join('", "')}"`));
  }

  // check password- must be 30 characters or less
  const passwords = data.map(row => row[columns.password]);
  const longPasswords = _checkCharacterCount(30, passwords);
  if (longPasswords.length) {
    const alert = this.$t('dropzoneImport.too_many_password_characters');
    characterCountErrors.push(alert.replace('{fields}', `"${longPasswords.join('", "')}"`));
  }

  // check studentIDs- must be 50 characters or less
  const studentIDs = data.map(row => row[columns.studentID]);
  const longIDs = _checkCharacterCount(50, studentIDs);
  if (longIDs.length) {
    const alert = this.$t('dropzoneImport.too_many_id_characters');
    characterCountErrors.push(alert.replace('{fields}', `"${longIDs.join('", "')}"`));
  }

  return characterCountErrors;
};

export function validateStudentData({ data, columns, fullOptions, isClassCodeEnabled }) {
  const errors = [];

  _cleanInputData(data);

  // check for missing required fields
  const rowsMissingData = _filter(data, row => {
    return !_reduce(isClassCodeEnabled ? classCodeEnabledRequiredFields : RequiredFields, (acc, field) => {
      return acc && (row[columns[field]].replace(/\s/g, '').length > 0);
    }, true);
  });
  if (rowsMissingData.length) {
    const alert = this.$t('dropzoneImport.missing_required_data');
    errors.push(alert.replace('{count}', rowsMissingData.length));
  }

  // check for duplicate student IDs
  if (!isClassCodeEnabled) {
    let userIDs = _map(data, d => d[columns.studentID]);
    // remove empty student IDs (that's a different error)
    userIDs = _filter(userIDs, id => (id.replace(/\s/g, '').length > 0));
    const duplicateIDs = _uniq(_filter(userIDs, (id, index) => {
      return _find(userIDs, (userID) => {
        return _upperCase(userID) === _upperCase(id);
      }, index + 1);
    }));
    if (duplicateIDs.length) {
      const alert = this.$t('dropzoneImport.duplicate_student_ids');
      errors.push(alert.replace('{ids}', `"${duplicateIDs.join('", "')}"`));
    }
  }

  const fieldParseMap = {
    language: {
      parser: _parseLanguages,
      messageKey: 'language'
    },
    gradeLevel: {
      required: !isClassCodeEnabled,
      parser: _parseGradeLevels,
      messageKey: 'grade_level'
    }
  };

  if (fullOptions) {
    fieldParseMap.gender = { parser: _parseGenders, messageKey: 'gender' };
    fieldParseMap.engProf = { parser: _parseYesNos, messageKey: 'eng_prof' };
    fieldParseMap.ethnicity = { parser: _parseEthnicities, messageKey: 'ethnicity' };
    fieldParseMap.disadv = { parser: _parseYesNos, messageKey: 'disadv' };
    fieldParseMap.special = { parser: _parseYesNos, messageKey: 'special' };
  }

  for (const key in fieldParseMap) {
    const { required, messageKey, parser } = fieldParseMap[key];
    if (columns[key] || required) {
      const invalidValues = parser(data, columns[key]);
      if (!invalidValues.length) {
        continue;
      }
      const alert = this.$t(`dropzoneImport.invalid_${messageKey}_vals`);
      const uniqueVals = _uniq(invalidValues);
      errors.push(alert.replace('{vals}', `"${uniqueVals.join('", "')}"`));
    }
  }

  return errors;
};

export const parseStudentData = ({ data, columns, fullOptions, isClassCodeEnabled }) => {
  _cleanInputData(data);

  return _map(data, (i) => {
    const row = {
      firstName: i[columns.firstName],
      lastName: i[columns.lastName],
      userID: i[columns.studentID],
      isClassCodeEnabled
    };

    if (isClassCodeEnabled) {
      delete row.userID;
    }
    if (columns.middleName) {
      row.middleName = i[columns.middleName];
    }
    if (columns.gradeLevel) {
      row.grade = i[columns.gradeLevel];
    }
    if (columns.language) {
      row.language = i[columns.language];
    }
    if (columns.password) {
      row.password = i[columns.password];
    }

    if (fullOptions) {
      if (columns.gender) {
        row.gender = i[columns.gender];
      }
      if (columns.engProf) {
        row.engProf = i[columns.engProf];
      }
      if (columns.ethnicity) {
        row.ethnicity = i[columns.ethnicity];
      }
      if (columns.disadv) {
        row.disadv = i[columns.disadv];
      }
      if (columns.special) {
        row.special = i[columns.special];
      }
    }

    return row;
  });
};

const _parseColumnNames = (keys, threshold) => {
  const columns = {};
  const names = _keys(ColumnNameMap);
  const fuse = new Fuse(names, { shouldSort: true, threshold });

  _each(keys, (key) => {
    const term = key.toUpperCase().replace(/\s/g, ''); // all caps, no spaces
    const resultIndex = fuse.search(term)[0];
    const prop = ColumnNameMap[names[resultIndex]];
    columns[prop] = key;
  });

  // if the fuzzy search added an "undefined" column, the search failed
  const valid = _isEmpty(columns.undefined);

  return { columns, valid };
};

const _parseLanguages = (data, key) => {
  const valueMap = {
    ENGLISH: 'English',
    SPANISH: 'Spanish',
    FRENCH: 'French'
  };
  return _parseInputValues({ data, key, valueMap });
};

const _parseGradeLevels = (data, key) => {
  const valueMap = GradeMap;
  const validator = (val) => {
    if (/^\+?\d+$/.test(val)) { // if the value is an integer
      return _inRange(parseInt(val, 10), 1, 13); // it must be in the range
    } else {
      return true;
    }
  };
  return _parseInputValues({ data, key, valueMap, validator });
};

const _parseGenders = (data, key) => {
  const valueMap = { FEMALE: 'Female', MALE: 'Male' };
  return _parseInputValues({ data, key, valueMap });
};

const _parseEthnicities = (data, key) => {
  return _parseInputValues({ data, key, valueMap: EthnicitiesMap });
};

const _parseYesNos = (data, key) => {
  const valueMap = {
    TRUE: 'Yes',
    YES: 'Yes',
    1: 'Yes',
    FALSE: 'No',
    NO: 'No',
    0: 'No'
  };
  const validator = (val) => {
    if (/^\+?\d+$/.test(val)) { // if the value is an integer
      return _inRange(parseInt(val, 10), 0, 2); // it must be in the range
    } else {
      return true;
    }
  };
  return _parseInputValues({ data, key, valueMap, validator });
};

const _parseInputValues = ({ data, key, valueMap, validator }) => {
  const values = _map(data, d => _trim(d[key]));
  const options = _keys(valueMap);
  const fuse = new Fuse(options, { shouldSort: true, threshold: 0.1 });
  const invalidValues = [];
  _each(values, (value, i) => {
    if (validator && !validator(value)) {
      invalidValues.push(value);
      return;
    }

    if (!value) {
      return;
    }

    const parsed = valueMap[options[fuse.search((value || '').toUpperCase())[0]]];
    if (parsed !== undefined) {
      data[i][key] = parsed;
    } else {
      invalidValues.push(value);
    }
  });

  return invalidValues;
};

const RequiredFields = ['firstName', 'lastName', 'studentID', 'gradeLevel'];
const classCodeEnabledRequiredFields = ['firstName', 'lastName'];

const ColumnNameMap = {
  FIRSTNAME: 'firstName',
  FNAME: 'firstName',
  MIDDLENAME: 'middleName',
  MNAME: 'middleName',
  LASTNAME: 'lastName',
  LNAME: 'lastName',
  STUDENTID: 'studentID',
  USERID: 'studentID',
  SID: 'studentID',
  UID: 'studentID',
  LANGUAGE: 'language',
  GRADELEVEL: 'gradeLevel',
  PASSWORD: 'password',
  GENDER: 'gender',
  LIMITEDENGLISHPROFICIENCY: 'engProf',
  LIMITEDENGLISH: 'engProf',
  ETHNICITY: 'ethnicity',
  RACE: 'ethnicity',
  ECONOMICALLYDISADVANTAGED: 'disadv',
  DISADVANTAGED: 'disadv',
  SPECIALEDSTATUS: 'special',
  SPECIALED: 'special',
  SPECIAL: 'special'
};

const GradeMap = {
  KINDERGARTEN: 'Kindergarten',
  K: 'Kindergarten',
  'GRADE 1': 'Grade 1',
  '1ST GRADE': 'Grade 1',
  FIRST: 'Grade 1',
  'GRADE 2': 'Grade 2',
  '2ND GRADE': 'Grade 2',
  SECOND: 'Grade 2',
  'GRADE 3': 'Grade 3',
  '3RD GRADE': 'Grade 3',
  THIRD: 'Grade 3',
  'GRADE 4': 'Grade 4',
  '4TH GRADE': 'Grade 4',
  FOURTH: 'Grade 4',
  'GRADE 5': 'Grade 5',
  '5TH GRADE': 'Grade 5',
  FIFTH: 'Grade 5',
  'GRADE 6': 'Grade 6',
  '6TH GRADE': 'Grade 6',
  SIXTH: 'Grade 6',
  'GRADE 7': 'Grade 7',
  '7TH GRADE': 'Grade 7',
  SEVENTH: 'Grade 7',
  'GRADE 8': 'Grade 8',
  '8TH GRADE': 'Grade 8',
  EIGHTH: 'Grade 8',
  'GRADE 9': 'Grade 9',
  '9TH GRADE': 'Grade 9',
  NINTH: 'Grade 9',
  'GRADE 10': 'Grade 10',
  '10TH GRADE': 'Grade 10',
  TENTH: 'Grade 10',
  'GRADE 11': 'Grade 11',
  '11TH GRADE': 'Grade 11',
  ELEVENTH: 'Grade 11',
  'GRADE 12': 'Grade 12',
  '12TH GRADE': 'Grade 12',
  TWELFTH: 'Grade 12'
};

const EthnicitiesMap = {
  11: 'Hispanic / Latino',
  'HISPANIC / LATINO': 'Hispanic / Latino',
  HISPANIC: 'Hispanic / Latino',
  LATINO: 'Hispanic / Latino',
  12: 'American Indian / Alaska Native',
  'AMERICAN INDIAN / ALASKA NATIVE': 'American Indian / Alaska Native',
  'AMERICAN INDIAN': 'American Indian / Alaska Native',
  'ALASKA NATIVE': 'American Indian / Alaska Native',
  13: 'Asian',
  ASIAN: 'Asian',
  14: 'Black / African American',
  'BLACK / AFRICAN AMERICAN': 'Black / African American',
  BLACK: 'Black / African American',
  'AFRICAN AMERICAN': 'Black / African American',
  15: 'Native Hawaiian / Other Pacific Islander',
  'NATIVE HAWAIIAN / PACIFIC ISLANDER': 'Native Hawaiian / Pacific Islander',
  'NATIVE HAWAIIAN': 'Native Hawaiian / Pacific Islander',
  'PACIFIC ISLANDER': 'Native Hawaiian / Pacific Islander',
  16: 'White',
  WHITE: 'White',
  17: 'Two or More Races',
  'TWO OR MORE RACES': 'Two or More Races',
  MULTIPLE: 'Two or More Races'
};
