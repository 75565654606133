import "babel-polyfill"
import 'vuetify/dist/vuetify.min.css'

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import PlatformData from '@explorelearning/platform-data';
import App from './App'
import router from './router'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import store from './state'

window.gitRevision = GIT_REVISION

new PlatformData.State({ namespace: 'platformData' }).register(store);

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
Vue.axios.defaults.withCredentials = true

import { plugin as UrlsPlugin } from '@explorelearning/urls';
Vue.use(UrlsPlugin);

import Vuetify from 'vuetify'
Vue.use(Vuetify)

import VeeValidate from 'vee-validate'
import VeeValidateConfig from './utils/VeeValidateConfig'
Vue.use(VeeValidate, VeeValidateConfig)

import VueTippy, { TippyComponent } from 'vue-tippy'
Vue.use(VueTippy, {
	directive: 'tippy', // => v-tippy
	flipDuration: 0,
	popperOptions: {
	  modifiers: {
		preventOverflow: {
		  enabled: true
		}
	  }
	}
})
Vue.component('tippy', TippyComponent);

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import i18n from './lang'
Vue.use(i18n)

import VueGtag from "vue-gtag"
Vue.use(VueGtag, { config: { id: 'GTM-TZBD7TT' } }, router)

new Vue({
	el: '#app',
	router,
	store,
	i18n,
	components: { App },
	template: '<App/>',
	render: h => h(App)
})
