<template>
	<v-flex>
		<v-flex headline grey--text text--darken-2 class="tabHeading hidden-sm-and-up">{{$t('profile.historyTab')}}</v-flex>
		<v-flex v-if="subscriptions.length">
			<v-data-table :headers='headers' :items='orders' hide-actions>
				<template slot="items" slot-scope="props">
		      <td class="text-xs-left">{{ (new Date(props.item.orderedOn)).toLocaleDateString("en-US",dateFormat) }}</td>
		      <td class="text-xs-right">${{ props.item.orderTotal.toFixed(2) }}</td>
		      <td class="text-xs-right"><v-btn flat icon @click="generateInvoice(props.item)"><v-icon>open_in_new</v-icon></v-btn></td>
		    </template>
			</v-data-table>
		</v-flex>
		<v-flex v-else class="text-xs-center mt-5">
			{{$t('profile.noPurchases')}}
		</v-flex>
		<v-layout>
			<v-flex class="text-xs-center grey--text mt-5 mb-5">
				{{$t('profile.purchaseExceptionPre')}} <router-link class="sans-decoration" :to="{name:'Support', query:{type:'billing'}}">{{$t('profile.purchaseExceptionLink')}}</router-link> {{$t('profile.purchaseExceptionPost')}}
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
	import APIs from 'mixins/APIs'
	import PDFDocument from 'mixins/InvoicePrinter'
	import LoginDetector from 'mixins/LoginDetector'
	import { mapGetters } from 'vuex'
	export default {
		name: 'PurchaseHistory',
		mixins: [APIs, LoginDetector],
		data(){
			return {
				dateFormat: {month: 'long', day: 'numeric', year: 'numeric'},
				headers: [
					{text: 'Purchase Date', value:'date', sortable: false, align: 'left'},
					{text: 'Order Total', value:'total', sortable: false, align: 'right'},
					{text: 'Invoice', value: 'print', sortable: false, align: 'right'}
				],
				orders: []
			}
		},
		computed: {
			...mapGetters('platformData/profile', ['profile']),
			subscriptions(){
				if(this.$store.state.store.orderHistory){
					return this.$store.state.store.orderHistory
				} else {
					return []
				}
			},
		},
		created (){
			this.$http.get(this.getOrderHistoryAPI(), this.jtiHeader).then(response => {
				let orders = response.data.ArrayList
				orders.sort(function(a,b){
					let dateA = new Date(a.orderedOn)
					let dateB = new Date(b.orderedOn)
					if (dateA > dateB){
					return -1
				} else if (dateA < dateB){
					return 1
				} else {
					return 0
				}
				})
					this.$store.commit('setOrderHistory', orders)
					this.orders = orders
				}, error => {
					console.log(error)
				})
		},
		methods: {
			generateInvoice(order){
				// Create File
				let filename = this.$t('profile.invoiceName')+' '+order.orderID
				let doc = new PDFDocument({ filename })

				// Header
				let title = this.$t('profile.invoiceHeader')+' #'+order.orderID
				let printMsg = this.$t('profile.printMsg')
				let header = { title, printMsg }
				doc.drawInvoiceHeader(header)

				// Order Date
				let orderDate = this.$t('profile.orderDate')+': '
				doc.write(orderDate, 'md-b')
				let textWidth = Number(doc.getTextWidth(orderDate).toFixed())
				doc.moveCursor({x: textWidth, y:0})
				let date = new Date(order.orderedOn).toLocaleDateString("en-US",this.dateFormat)
				doc.write(date, 'md')
				doc.moveCursor({x:-textWidth, y:20})

				// Order ID
				let orderNumber = this.$t('profile.orderNumber')+': '
				doc.write(orderNumber, 'md-b')
				textWidth = Number(doc.getTextWidth(orderNumber).toFixed())
				doc.moveCursor({x: textWidth, y:0})
				doc.write(String(order.orderID), 'md')
				doc.moveCursor({x:-textWidth, y:20})

				// Order Total
				let orderTotal = this.$t('profile.orderTotal')+': $'+order.orderTotal.toFixed(2)
				doc.write(orderTotal, 'md-b')
				doc.moveCursor({x:0, y:60})

				// Itemized Table
				doc.write(this.$t('profile.orderItems'), 'md-b')
				doc.moveCursor({x:0, y:20})
				let headers = [
					{title: this.$t('profile.orderItem'), dataKey: 'item'},
					{title: this.$t('cart.cost'), dataKey: 'cost'}
				]
				let config = {
					columnStyles: { cost: { halign: 'right' } },
					createdHeaderCell(cell) {
					if (cell.raw.dataKey === 'cost') {
					cell.styles.halign = 'right';
					}
				}
				}
				let rows = []
				let self = this;
				order.orderLineItems.forEach(function(lineItem){
					let row = {}
					let item = lineItem.orderItem.replace(/([A-Z])/g, ' $1')
					if(item.indexOf('Purchase')+1 || item.indexOf('Renewal')+1){
						item += ' ('+lineItem.seats+' '+self.$t('cart.students')+' '+self.$t('profile.for')+' '+lineItem.fullYears+' '+self.$t('cart.years')+' @ $'+lineItem.promoPricePerSeat.toFixed(2)+' '+self.$t('profile.perPer')+')'
					} else if (item.indexOf('Expansion')+1){
						item += ' ('+lineItem.additionalSeats+' '+self.$t('cart.students')+' @ $'+lineItem.pricePerSeatForDuration.toFixed(2)+')'
					}
					if(lineItem.promoCode){
						item +=' ['+self.$t('form.promoCode')+': '+lineItem.promoCode+']'
					}
					row.item = item
					let cost = lineItem.total-lineItem.salesTax
					cost = '$'+cost.toFixed(2)
					row.cost = cost
					rows.push(row)
				})
				doc.table({headers, rows, config})
				doc.moveCursor({x:0,y:(order.orderLineItems.length+1)*30+20})

				// Tax
				doc.formatText({preset: 'md'})
				let tax = this.$t('confirm.tax')+': $'+order.orderTax.toFixed(2)
				textWidth = doc.getTextWidth(tax).toFixed()
				let offset = doc.width-textWidth-(doc.padding*2)
				doc.moveCursor({x:offset,y:0})
				doc.write(tax, 'md')
				doc.moveCursor({x:-offset,y:20})

				// Order Total
				let total = this.$t('confirm.total')+': $'+order.orderTotal.toFixed(2)
				textWidth = doc.getTextWidth(total).toFixed()
				offset = doc.width-textWidth-(doc.padding*2)
				doc.moveCursor({x:offset,y:0})
				doc.write(total, 'md')
				doc.moveCursor({x:-offset,y:60})

				// Calculate Offset
				doc.formatText({preset: 'md-b'})
				let alignmentOffset = 0
				let labels = [this.$t('profile.payee'),this.$t('profile.payment')]
				if(order.billingAddress){
					labels.push(this.$t('address.header'))
				}
				if(order.shippingAddress){
					labels.push(this.$t('address.shipping'))
				}
				labels.forEach(function(label){
					let labelOffset = Number(doc.getTextWidth(label).toFixed())
					if(labelOffset>alignmentOffset){
						alignmentOffset = labelOffset
					}
				})
				alignmentOffset = alignmentOffset + Number(doc.getTextWidth(': ').toFixed())


				// Payee
				let user = this.profile
				let payee = this.$t('profile.payee')+': '
				doc.write(payee, 'md-b')
				doc.moveCursor({x: alignmentOffset, y:0})
				payee = user.firstName+' '+user.lastName+' ('+user.username+')'
				doc.write(payee, 'md')
				doc.moveCursor({x:-alignmentOffset,y:20})

				// Credit Card
				let cardInfo = this.$t('profile.payment')+': '
				doc.write(cardInfo, 'md-b')
				doc.moveCursor({x: alignmentOffset, y:0})
				cardInfo = order.cardType.charAt(0).toUpperCase()+order.cardType.slice(1).toLowerCase()+' (XXXX-XXXX-XXXX-'+order.cardLastDigits+')'
				doc.write(cardInfo, 'md')
				doc.moveCursor({x:-alignmentOffset,y:20})

				// Billing Address
				if(order.billingAddress){
					let address = order.billingAddress
					let label = this.$t('address.header')+': '
					doc.write(label, 'md-b')
					doc.moveCursor({x: alignmentOffset, y:0})
					doc.write(address.address1, 'md')
					doc.moveCursor({x:0,y:15})
					if(address.address2){
						doc.write(address.address1, 'md')
						doc.moveCursor({x:0,y:15})
					}
					doc.write(address.city+', '+address.state+' '+address.postalCode, 'md')
					doc.moveCursor({x:0,y:15})
					doc.write(address.countryName)
					doc.moveCursor({x:-alignmentOffset,y:20})
				}

				// Shipping Address
				if(order.shippingAddress){
					let address = order.shippingAddress
					let label = this.$t('address.shipping')+': '
					doc.write(label, 'md-b')
					doc.moveCursor({x: alignmentOffset, y:0})
					doc.write(address.address1, 'md')
					doc.moveCursor({x:0,y:15})
					if(address.address2){
						doc.write(address.address1, 'md')
						doc.moveCursor({x:0,y:15})
					}
					doc.write(address.city+', '+address.state+' '+address.postalCode, 'md')
					doc.moveCursor({x:0,y:15})
					doc.write(address.countryName)
					doc.moveCursor({x:-alignmentOffset,y:20})
				}

				// Footer
				let copywrite = '© '+(new Date()).getFullYear()+' '+this.$t('profile.invoiceFooter')
				doc.formatText({preset: 'b'})
				textWidth = Number(doc.getTextWidth(copywrite).toFixed())
				offset = doc.width/2-textWidth/2
				doc.setCursor({x:offset, y:doc.height-doc.padding*2})
				doc.write(copywrite)

				doc.download()
			}
		}
	}
</script>

<style lang="less">
	.histLogo {
		width: 20px;
		margin-top: 3px;
		margin-left: 10px;
	}
</style>
