<template>
	<v-flex>
		<v-flex headline grey--text text--darken-2 class="tabHeading hidden-sm-and-up">{{$t('profile.subscriptionTab')}}</v-flex>
		<Messaging outline></Messaging>
		<v-flex v-if="$store.state.store.subscriptions && $store.state.store.subscriptions.length">
			<v-layout v-for="subscription in $store.state.store.subscriptions" :key="subscription.subscriptionID" class="mb-3">
				<v-flex xs2 md1 lg1 class="text-sm-center text-md-left">
					<img :src="productImgPath(subscription.productID)" 
						:alt="'ExploreLearning' +productDisplayName(subscription.productID)+' Logo'" 
						class="subLogo"
						:class="[
							{'greyed-out':showOptIn&&optOuts.includes(subscription.productID)},
							productDisplayName(subscription.productID).toLowerCase()+'-leaf'
						]"
					>
				</v-flex>
				<v-flex xs9 md7 lg8 class="pl-2 pr-2">
					<v-flex subheading class="subTitle" data-test-id="account-subscriptions-subheading">
						<span v-if="profile.roleID==4">{{productDisplayName(subscription.productID)}} {{$t('profile.parent')}}</span>
						<span v-else>
							<span v-if="isExpired(subscription)">{{$t('cart.expired')}}</span>
							<span v-if="subscription.pending">{{$t('cart.pending')}}</span>						
							{{productDisplayName(subscription.productID)}} {{getLicenseTypeName(subscription.licensingTypeID)}} {{$t('profile.license')}}
							<v-icon 
								v-if="isEndpointSubFreemium(subscription)" 
								@click="freemiumDialog=true" 
								color="info"
								data-test-id="account-subscriptions-freemium-help-icon">
									help_outline
							</v-icon>
						</span>
					</v-flex>
					<v-flex v-if="profile.roleID!=4" class="subOrg">
						{{subscription.subscriptionName}}
						<span v-if="subscription.licensingTypeID !== 1" class="grey--text">
							[{{subscription.studentsUsed}} of {{subscription.studentsCap}} {{$t('profile.students')}}]
						</span>
					</v-flex>
					<v-flex v-if="subscription.licensingTypeID !== 10" class="grey--text">
						{{(new Date(subscription.startsOn)).toLocaleDateString("en-US",dateFormat)}} - {{(new Date(subscription.expiresOn)).toLocaleDateString("en-US",dateFormat)}}
					</v-flex>
					<v-flex v-if="showOptIn && optOuts.includes(subscription.productID)" class="caption grey--text text--darken-2">
						(Disabled due to your <router-link class="grey--text text--darken-2" :to="{name:'OptIn'}">product selections</router-link>)
					</v-flex>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-layout v-else>
			<v-flex class="text-xs-center grey--text my-5">
				{{$t('profile.noSubs')}}
			</v-flex>
		</v-layout>
		<v-flex class="text-xs-center">
			<v-btn v-if="showOptIn" large color="primary" class="elevation-0" :to="{name:'OptIn'}">
				Product Selections
			</v-btn>
			<v-btn outline large color="primary" :to="{name:'Quote', params: {type:'purchase'}}">
				{{$t('profile.contactSales')}}
			</v-btn>
		</v-flex>
		<FreemiumExplainedDialog
            :value="freemiumDialog"
            @closeDialog="freemiumDialog=false"
        />
	</v-flex>
</template>

<script>
	import APIs from 'mixins/APIs'
	import FreemiumExplainedDialog from 'components/modals/FreemiumExplainedModal'
	import Messaging from 'components/layout/Messaging'
    import ProductInfo from 'mixins/ProductInfo'
	import SubscriptionHelper from 'mixins/SubscriptionHelper'
	import LoginDetector from 'mixins/LoginDetector'
	import { mapGetters } from 'vuex'
	import Constants from 'mixins/Constants'
  	export default {
		name: 'Subscriptions',
		components : {FreemiumExplainedDialog, Messaging},
		mixins: [APIs, ProductInfo, SubscriptionHelper, LoginDetector, Constants],
		data(){
			return {
				dateFormat: {month: 'short', day: 'numeric', year: 'numeric'},
				dialog : {
					blockExpansion : false,
					currentSeats : 0,
					display : false,
					homeInfo : false,
					labelStudents : '',
					labelYears : '',
					numStudents : null,
					numYears : null,
					seatMax : Number.POSITIVE_INFINITY,
					seatMin : 1,
					width : 500
				},
				duration: [],
				freemiumDialog: false,
			}
  		},
		computed: {
			...mapGetters('platformData/profile', ['profile']),
			displaySupport(){
				let displaySupport = false
				if(this.$store.state.store.subscriptions && this.$store.state.store.subscriptions.length){
					this.$store.state.store.subscriptions.forEach(sub=>{
						let type = sub.licensingTypeID
						if(type === 1 || type === 5 || type === 6 || type === 7){
							displaySupport = true
						}
					})
				}
				return displaySupport
			},
		},
		async created (){
			await this.$http.get(this.getSubscriptionsAPI(), this.jtiHeader).then(response => {
				this.$store.commit('setSubscriptions', response.data.ArrayList)
			}, error => {
				console.log(error)
			})
		},
		methods: {
			showDialog(labelYears, labelStudents){
				this.dialog.labelYears = labelYears
				this.dialog.labelStudents = labelStudents
			},
			isEndpointSubFreemium(sub) {
				if(sub){
                const isExemption = this.licenseTypeIsExpiredExempted(sub.productID, sub.licensingTypeID)
                return sub.expired && isExemption
				} else {
					return false
				}
			},
			isExpired(sub){
				if(this.licenseTypeIsExpiredExempted(sub.productID, sub.licensingType)){
					return false
				} else {
					return sub.expired
				}
			}
		}
  	}
</script>

<style scoped lang="less">
	.subLogo{
		width: 50px;
		margin-top: 5px;
	}
	.subTitle {
		font-weight: 500 !important;
		position: relative;
		.v-icon{
			font-size: 20px;
			cursor: pointer;
		}
	}
	.subOrg {
		color: #1976D2;
		text-transform: uppercase;
		font-weight: 350;
	}
	.subIcon {
		text-align: center;
		vertical-align: top;
		color: black;
		margin: 10px 8px 0;
		display: inline-block;
		cursor: pointer;
		float: right;
	}
	.disabled {
		color:#A6A6A6 !important;
		cursor: default;
		i {
			color:#A6A6A6 !important;
		}
		.error--text {
			color:#A6A6A6 !important;
		}
	}
	.v-alert {
		border-radius: 4px !important;
	}
	.greyed-out {
        filter: grayscale(1);
    }
	.greyed-out.frax-leaf{
		opacity: 0.4;
	}
    .greyed-out.reflex-leaf{
        opacity: 0.7;
    }
    .greyed-out.gizmos-leaf{
        opacity: 0.45;
    }
    .greyed-out.science4us-leaf{
        opacity: .9;
    }
</style>
