<template>
	<form @submit.prevent="updateProfile">
		<v-flex headline grey--text text--darken-2 class="tabHeading hidden-sm-and-up">{{$t('profile.passwordTab')}}</v-flex>
		<Messaging outline></Messaging>
		<PasswordValidationIndicator :password="password" class="mb-4"/>
		<v-layout wrap>
			<v-flex xs12>
				<v-text-field
					v-model="password"
					:label="$t('form.passwordUpdate')"
					v-validate="veeValidatePasswordRules"
					name="password"
					:error-messages="errors.collect('password')"
					:append-icon="passwordVisible ? 'visibility_off' : 'visibility'"
					@click:append="() => (passwordVisible = !passwordVisible)"
					:type="passwordVisible ? 'text' : 'password'"
					ref="password"
					>
	      		</v-text-field>
			</v-flex>
			<v-flex xs12>
				<v-text-field
					v-model="passwordConfirm"
					:label="$t('form.passwordConfirm')"
					v-validate="'required|confirmed:password'"
					name="password confirmation"
					:error-messages="errors.collect('password confirmation')"
					type="password">
				</v-text-field>
			</v-flex>
			<v-flex xs12 class="text-xs-right">
				<v-btn :disabled="!this.profile"  color="primary" large type="submit">
					{{$t('navigation.update')}}
				</v-btn>
			</v-flex>
		</v-layout>
	</form>
</template>

<script>
	import APIs from 'mixins/APIs'
	import ErrorHandling from 'mixins/ErrorHandling'
	import Messaging from 'components/layout/Messaging'
	import PasswordValidation from 'mixins/PasswordValidation'
	import PasswordValidationIndicator from 'components/layout/PasswordValidationIndicator'
	import LoginDetector from 'mixins/LoginDetector'
	import { mapGetters } from 'vuex'
	export default {
		name: 'PasswordUpdate',
		components: {
			Messaging,
			PasswordValidationIndicator
		},
		mixins: [APIs, ErrorHandling, PasswordValidation, LoginDetector],
		data(){
			return {
				email: null,
				firstName: null,
				lastName: null,
				middleName: null,
				password: '',
				passwordConfirm: null,
				passwordVisible: false,
				productOptOuts : [],
				titleID: 0,
				userName: null,
				languageID: null,
				isValidEmail: null,
				isSubscribedToEmails: null,
				isDeleted: null
			}
		},
		computed: {
			...mapGetters('platformData/profile', ['profile']),
		},
		methods: {
			updateProfile: async function(){
				var valid = await this.$validator.validateAll()
				if(valid){
					var updatedProfile = {
						username : this.profile.username,
						firstname : this.profile.firstName,
						lastname : this.profile.lastName,
						email : this.profile.email,
						password : this.password,
					}
					if(updatedProfile.titleID == null){
						updatedProfile.titleID = 0
					}
					this.$http.put(this.getProfileUpdateAPI(), updatedProfile, this.jtiHeader).then(() => {
						this.$store.commit('setMessageSuccess', this.$t('success.passwordUpdate'))
						this.password = ''
						this.passwordConfirm = null
					}, error => {
						if (error.response.status == 404 || error.response.status == 0) {
							this.$store.commit('setMessageError', this.$t('error.404'))
						} else {
							this.handleError(error.response)
						}
					})
				}
			}
		}
	}
</script>
