import VeeValidate from 'vee-validate'
const VeeValidateConfig = {
	events: 'submit',
	dictionary: {
		en: {
			messages: {
				confirmed: function(fieldName){
					return "The "+fieldName+" does not match."
				},
				digits:function(fieldName, params){
					return "The "+fieldName+" field must be numeric and contain exactly "+params[0]+" digits."
				},
				email:function(fieldName, params){
					if(params[0]=='multi'){
						return "Contains invalid email address."
					} else {
						return "The "+fieldName+" field must be a valid email address."
					}

				}
			}
		}
	}
}
VeeValidate.Validator.extend('minDigitChars', {
	getMessage(field, args){
		return 'Must contain ' + args[0] + ' number character.'
	},
	validate(value, args){
		let matchingChars = 0
		for(let i = 0; i < value.length; i++){
			let char = value.charAt(i)
			if(/[0-9]/.test(char)){
				++matchingChars
			}
		}
		const count = args[0]
		return matchingChars >= count
	}
})
VeeValidate.Validator.extend('minLowercaseChars', {
	getMessage(field, args){
		return 'Must contain ' + args[0] + ' lowercase character.'
	},
	validate(value, args){
		let matchingChars = 0
		for(let i = 0; i < value.length; i++){
			let char = value.charAt(i)
			if(/[a-z]/.test(char)){
				++matchingChars
			}
		}
		const count = args[0]
		return matchingChars >= count
	}
})
VeeValidate.Validator.extend('minSpecialChars', {
	getMessage(field, args){
		return 'Must contain ' + args[0] + ' special character.'
	},
	validate(value, args){
		const specialChars = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~"
		let matchingChars = 0
		for(let i = 0; i < value.length; i++){
			let char = value.charAt(i)
			if(specialChars.includes(char)){
				++matchingChars
			}
		}
		const count = args[0]
		return matchingChars >= count
	}
})
VeeValidate.Validator.extend('minUppercaseChars', {
	getMessage(field, args){
		return 'Must contain ' + args[0] + ' uppercase character.'
	},
	validate(value, args){
		let matchingChars = 0
		for(let i = 0; i < value.length; i++){
			let char = value.charAt(i)
			if(/[A-Z]/.test(char)){
				++matchingChars
			}
		}
		const count = args[0]
		return matchingChars >= count
	}
})
VeeValidate.Validator.extend('noTags', {
	getMessage: 'HTML is not allowed.',
	validate(value){
		const htmlPattern = /\<[^\ ].*\>/g
		return !htmlPattern.test(value)
	}
})
VeeValidate.Validator.extend('noDiacritics', {
	getMessage(field){
		return 'Diacritics are not allowed in '+field+'.'
	},
	validate(value){
		const diacritics = "áàâäãéèëêíìïîóòöôõúùüûñçăşţ"
		let isClean = true
		for(let char of value){
			if(diacritics.includes(char.toLowerCase())){
				isClean = false
			}
		}
		return isClean
	}
})
VeeValidate.Validator.extend('noEscapes', {
	getMessage: 'Special characters are not allowed.',
	validate(value){
		const specialChars = '@<>#%}{+|\\^~[]`;/?:=&$'
		let isClean = true
		for(let char of value){
			if(specialChars.includes(char)){
				isClean = false
			}
		}
		return isClean
	}
})
VeeValidate.Validator.extend('noEscapesExceptAt', {
	getMessage: 'Special characters are not allowed.',
	validate(value){
		const specialChars = '<>#%}{+|\\^~[]`;/?:=&$'
		let isClean = true
		for(let char of value){
			if(specialChars.includes(char)){
				isClean = false
			}
		}
		return isClean
	}
})
VeeValidate.Validator.extend('noSpaces', {
	getMessage: 'Spaces are not allowed.',
	validate(value){
		const space = ' '
		let isClean = true
		for(let char of value){
			if(char == space){
				isClean = false
			}
		}
		return isClean
	}
})

export default VeeValidateConfig