<template>
    <UserManagmentModal
        :value="value"
        :headline="isAdmin ? $t('classMgmt.addStudentToRosterHeader') : $t('classMgmt.addStudentNewHeader')"
        :subHeadline="isAdmin ? $tc('classMgmt.institution', 1) + ': ' + (selectedClass ? selectedClass.name : '') : $tc('classMgmt.class', 1) + ': ' + (selectedClass ? selectedClass.name : '')"
        @closeDialogs="closeDialogs()"
        id="AddNewStudentModal"
    >
        <div v-if="!isAdmin">
            <v-stepper alt-labels linear v-model="stepID">
                <v-stepper-header id="createStudentStepper" v-show="showStepperHeader">
                    <v-stepper-step step="1" :complete="stepID>1">
                        {{$t('classMgmt.studentInfo')}}
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="2" :complete="stepID>2">
                        {{assignmentAccessStepText}}
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3" :color="stepID>2?'primary':'grey'" complete complete-icon="info">
                        <span :class="stepID>2?'':'grey--text'">Login Info</span>
                    </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                    <v-divider class="ml-0"></v-divider>
                    <v-stepper-content step="1" class="pa-0" id="AddNewStudentModalStudentInfoPane">
                        <form v-if="stepID==1" @submit.prevent="incrementCurrentStep()">
                            <v-card-text class="mb-12" >
                                <v-flex class="mx-4 mb-3">
                                    <Messaging outline></Messaging>
                                </v-flex>
                                <v-layout>
                                    <v-flex xs4 class="pr-1">
                                        <v-text-field
                                            v-model="newUser.firstName"
                                            :label="$t('form.firstName')+' *'"
                                            v-validate="'required|max:30|noEscapes'"
                                            name="first name"
                                            :error-messages="errors.collect('first name')"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs4 class="pr-1">
                                        <v-text-field
                                            v-model="newUser.middleName"
                                            :label="$t('form.middleName')"
                                            v-validate="'max:30|noEscapes'"
                                            name="middle name"
                                            :error-messages="errors.collect('middle name')"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs4>
                                        <v-text-field
                                            v-model="newUser.lastName"
                                            :label="$t('form.lastName')+' *'"
                                            v-validate="'required|max:30|noEscapes'"
                                            name="last name"
                                            :error-messages="errors.collect('last name')"
                                        ></v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-text-field
                                        v-if="!newUser.isClassCodeEnabled"
                                        v-model="newUser.sisUserId"
                                        :label="$t('form.studentID')+' *'"
                                        v-validate="'required|max:50'"
                                        name="student ID"
                                        :error-messages="errors.collect('student ID')"
                                    >
                                        <template #append>
                                            <StudentIdToolTip small/>
                                        </template>
                                    </v-text-field>
                                </v-layout>
                                <v-layout wrap>
                                    <v-flex xs6 class="pr-1">
                                        <v-select
                                            v-model="newUser.gradeId"
                                            :items="gradeLevelIDs"
                                            item-text="label"
                                            item-value="id"
                                            :label="$t('classMgmt.gradeLevel')+' *'"
                                            v-validate="'required'"
                                            name="grade level"
                                            :error-messages="errors.collect('grade level')"
                                        ></v-select>
                                    </v-flex>
                                    <v-flex xs6 class="pr-1">
                                        <v-select
                                            v-model="newUser.languageId"
                                            :items="languages"
                                            item-text="label"
                                            item-value="id"
                                            label="Language"
                                            v-validate="'required'"
                                            name="language"
                                            :error-messages="errors.collect('language')"
                                        >
                                            <template #append>
                                                <LanguageToolTip small></LanguageToolTip>
                                            </template>
                                        </v-select>
                                    </v-flex>
                                </v-layout>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn 
                                    :disabled="loadingStudents"
                                    id="AddNewStudentModalNextBtn" 
                                    color="primary" 
                                    type="submit" 
                                    class="elevation-0">
                                    <v-icon v-show="loadingStudents" class="mr-2">fas fa-circle-notch fa-spin</v-icon>
                                    {{$t('classMgmt.next')}}
                                </v-btn>
                            </v-card-actions>
                        </form>
                    </v-stepper-content>
                    <v-stepper-content step="2" class="pa-0" id="AddNewStudentModalAccessPane">
                        <form v-if="stepID==2" @submit.prevent="createStudent(newUser)">
                            <v-card-text>
                                <v-alert
                                    :value="newUser.assignmentId && hasGradeLevelLessThan([newUser],3) || newUser.sectorId && hasGradeLevelLessThan([newUser],4)"
                                    color="warning"
                                    icon="warning"
                                    outline
                                    class="mx-4 mb-3"
                                >
                                    <span v-if="newUser.assignmentId && hasGradeLevelLessThan([newUser],3)">{{$t('classMgmt.reflexNotRecommended')}}</span>
                                    <span v-if="newUser.sectorId && hasGradeLevelLessThan([newUser],4)">{{$t('classMgmt.fraxNotRecommended')}}</span>
                                </v-alert>
                                <v-layout wrap>
                                    <v-flex
                                        v-for="dropdown in productDropdowns"
                                        v-bind:key="dropdown.id"
                                        xs12
                                    >
                                        <v-select
                                            v-model="newUser[dropdown.newUserItems]"
                                            :items="getDropDownOptions(dropdown.dropDownOptions)"
                                            item-text="label"
                                            item-value="id"
                                            :label="'Set '+productDisplayName(dropdown.id)+' '+$tc('classMgmt.' + dropdown.assignmentType, localizationConstants.SINGLE)"
                                            v-validate="'required'"
                                            :name="dropdown.displayName + ' ' + dropdown.assignmentType"
                                            :error-messages="errors.collect(dropdown.displayName + ' ' + dropdown.assignmentType)"
                                            :disabled="seatInfo(dropdown.id).studentDelta<1 || Boolean(dropdown.autoEnroll)"
                                            :item-disabled="hasPrerequisite"
                                            :persistent-hint="seatInfo(dropdown.id).studentDelta<1 || Boolean(dropdown.autoEnroll)"
                                            :hint="productHint(dropdown)"
                                        >
                                            <template slot="prepend">
                                               <img :src="productImgPath(dropdown.id)" :alt="productDisplayName(dropdown.id) + ' Logo'" />
                                            </template>
                                            <template #item="assignemnt">
                                                <v-list-tile-content>
                                                    <v-list-tile-title>{{ assignemnt.item.label }}</v-list-tile-title>
                                                </v-list-tile-content> 
                                                <v-list-tile-avatar>
                                                    <span v-if="hasPrerequisite(assignemnt.item)">
                                                        <v-icon small color="error">do_disturb_on</v-icon>
                                                    </span>
                                                </v-list-tile-avatar>
                                            </template>
                                            <template v-if="optionsHavePrerequisites(getDropDownOptions(dropdown.dropDownOptions))" #append-item>
                                                <v-divider class="mt-2 ml-0"></v-divider>
                                                <v-list-tile>
                                                    <v-list-tile-content class="caption grey--text">
                                                        <span>
                                                            <v-icon small color="error">do_disturb_on</v-icon>
                                                            Sector 2 can only be assigned after students have completed Sector 1.
                                                        </span>
                                                    </v-list-tile-content>
                                                </v-list-tile>
                                            </template>
                                        </v-select>
                                    </v-flex>
                                </v-layout>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn id="AddNewStudentModalBackBtn" flat @click="stepID--" class="elevation-0">
                                    {{$t('classMgmt.back')}}
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn id="AddNewStudentModalCreateBtn" color="primary" type="submit" class="elevation-0" :disabled="creatingNewStudent">
                                    <v-icon v-show="!creatingNewStudent">check</v-icon>
                                    <v-icon v-show="creatingNewStudent">fas fa-circle-notch fa-spin</v-icon>
                                    &nbsp;{{$t('classMgmt.addNewStudentButton')}}
                                </v-btn>
                            </v-card-actions>
                        </form>
                    </v-stepper-content>
                    <v-stepper-content step="3" class="pa-0" id="AddNewStudentModalSuccessPane">
                        <v-list>
                            <v-subheader>
                                {{newUser.firstName ? newUser.firstName.toUpperCase() : ''}} {{newUser.lastName ? newUser.lastName.toUpperCase() : ''}}'<span v-if="!finalLetterIsS(newUser.lastName)">S</span>&nbsp;{{$t('classMgmt.loginInfo')}}
                                <v-spacer/>
                                <v-btn id="AddNewStudentModalPrintCardBtn" @click="onPrint('cards',newUser.id)" class="mr-0 elevation-0">
                                    <v-icon>print</v-icon>&nbsp;Print Login Card
                                </v-btn>
                            </v-subheader>
                            <v-list-tile avatar>
                                <v-list-tile-avatar>
                                    <v-icon>link</v-icon>
                                </v-list-tile-avatar>
                                <v-list-tile-content>
                                    <v-list-tile-sub-title>{{$t('classMgmt.loginPage')}}</v-list-tile-sub-title>
                                    <v-list-tile-title><a :href="getStudentLoginURL()" target="_blank">{{getStudentLoginURL()}}</a></v-list-tile-title>
                                </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile avatar>
                                <v-list-tile-avatar>
                                    <v-icon>account_circle</v-icon>
                                </v-list-tile-avatar>
                                <v-list-tile-content>
                                    <v-list-tile-sub-title>{{$t('classMgmt.username')}}<span v-if="!newUser.isClassCodeEnabled">*</span></v-list-tile-sub-title>
                                    <v-list-tile-title>{{newUser.isClassCodeEnabled ? newUser.username : profile.username}}</v-list-tile-title>
                                </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile avatar v-if="!newUser.isClassCodeEnabled">
                                <v-list-tile-avatar>
                                    <v-icon>class</v-icon>
                                </v-list-tile-avatar>
                                <v-list-tile-content>
                                    <v-list-tile-sub-title>{{$tc('classMgmt.class', localizationConstants.SINGLE)}}</v-list-tile-sub-title>
                                    <v-list-tile-title>{{selectedClass.name}}</v-list-tile-title>
                                </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile avatar :class="{'mb-3':newUser.isClassCodeEnabled}">
                                <v-list-tile-avatar>
                                    <v-icon>security</v-icon>
                                </v-list-tile-avatar>
                                <v-list-tile-content>
                                    <v-list-tile-sub-title>{{$t('classMgmt.password')}}</v-list-tile-sub-title>
                                    <v-list-tile-title>{{newUser.password}} <span class="caption grey--text text--darken-1">({{$t('classMgmt.passwordAutomaticallyGenerated')}})</span></v-list-tile-title>
                                </v-list-tile-content>
                            </v-list-tile>
                            <v-list-tile v-if="!newUser.isClassCodeEnabled">
                                <v-list-tile-content>
                                    <v-list-tile-sub-title class="caption">{{$t('classMgmt.useTeacherUsername')}}</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                            <v-divider class="ml-0"></v-divider>
                        </v-list>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn id="AddNewStudentModalRestartBtn" color="primary" :disabled="exceedsStudentCap" @click="setup">
                                <v-icon>person_add</v-icon>&nbsp;{{$t('classMgmt.addAnotherStudent')}}
                            </v-btn>
                        </v-card-actions>
                    </v-stepper-content>
                    <v-stepper-content step="4" class="pa-0" id="transferStudentModalAlert">
                        <v-layout class="pa-3 ml-2">
                                <v-icon color="info">info</v-icon>
                                <v-card-text>
                                    <strong>{{transferAlertMessage}}</strong>
                                </v-card-text>
                        </v-layout>
                        <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" @click="cancelTransfer">
                                    {{$t('classMgmt.cancel')}}
                                </v-btn>
                                <v-btn color="primary" @click="transferStudent(newUser)" v-show="transferStudentAlert == 1">
                                    {{$t('classMgmt.transfer')}}
                                </v-btn>
                                <v-btn color="primary" @click="addToClass()" v-show="transferStudentAlert == 2">
                                    {{$t('classMgmt.addToClass')}}
                                </v-btn>
                            </v-card-actions>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </div>
        <div v-else>
            <v-stepper alt-labels linear v-model="stepID">
                <v-stepper-items>
                    <v-stepper-content step="1" class="pa-0" id="AddNewStudentModalStudentInfoPane">
                        <form @submit.prevent="createStudent(newUser)">
                            <v-card-text class="mb-12" id="AddNewStudentModalAdminPane">
                                <v-flex class="mx-4 mb-3">
                                    <Messaging outline></Messaging>
                                </v-flex>
                                <v-layout>
                                    <v-flex xs4 class="pr-1">
                                        <v-text-field
                                            v-model="newUser.firstName"
                                            :label="$t('form.firstName')+' *'"
                                            v-validate="'required|max:30|noEscapes'"
                                            name="first name"
                                            :error-messages="errors.collect('first name')"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs4 class="pr-1">
                                        <v-text-field
                                            v-model="newUser.middleName"
                                            :label="$t('form.middleName')"
                                            v-validate="'max:30|noEscapes'"
                                            name="middle name"
                                            :error-messages="errors.collect('middle name')"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs4>
                                        <v-text-field
                                            v-model="newUser.lastName"
                                            :label="$t('form.lastName')+' *'"
                                            v-validate="'required|max:30|noEscapes'"
                                            name="last name"
                                            :error-messages="errors.collect('last name')"
                                        ></v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-text-field
                                        v-model="newUser.sisUserId"
                                        :label="$t('form.studentID')+' *'"
                                        v-validate="'required|max:50'"
                                        name="student ID"
                                        :error-messages="errors.collect('student ID')"
                                    >
                                        <template #append>
                                            <StudentIdToolTip small/>
                                        </template>
                                    </v-text-field>
                                </v-layout>
                                <v-layout wrap>
                                    <v-flex xs6 class="pr-1">
                                        <v-select
                                            v-model="newUser.gradeId"
                                            :items="gradeLevelIDs"
                                            item-text="label"
                                            item-value="id"
                                            :label="$t('classMgmt.gradeLevel')+' *'"
                                            v-validate="'required'"
                                            name="grade level"
                                            :error-messages="errors.collect('grade level')"
                                        ></v-select>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-select
                                            v-model="newUser.specialEdId"
                                            :items="yesNoNA"
                                            item-text="label"
                                            item-value="id"
                                            label="Special Ed"
                                        ></v-select>
                                    </v-flex>
                                    <v-flex xs6 class="pr-1">
                                        <v-select
                                            v-model="newUser.languageId"
                                            :items="languages"
                                            item-text="label"
                                            item-value="id"
                                            label="Language"
                                            v-validate="'required'"
                                            name="language"
                                            :error-messages="errors.collect('language')"
                                        >
                                            <template #append>
                                                <LanguageToolTip small></LanguageToolTip>
                                            </template>
                                        </v-select>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-select
                                            v-model="newUser.engProfId"
                                            :items="yesNoNA"
                                            item-text="label"
                                            item-value="id"
                                            label="Limited English Proficiency"
                                        ></v-select>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex xs6 class="pr-1">
                                        <v-select
                                            v-model="newUser.genderId"
                                            :items="genders"
                                            item-text="label"
                                            item-value="id"
                                            label="Gender"
                                        ></v-select>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-select
                                            v-model="newUser.ethnicityId"
                                            :items="ethnicities"
                                            item-text="label"
                                            item-value="id"
                                            label="Race / Ethnicity"
                                            class="text-no-wrap text-truncate"
                                        ></v-select>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex xs12>
                                        <v-select
                                            v-model="newUser.disadvId"
                                            :items="yesNoNA"
                                            item-text="label"
                                            item-value="id"
                                            label="Economically Disadvantaged"
                                        ></v-select>
                                    </v-flex>
                                </v-layout>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn id="AddNewStudentModalAdminCreateBtn" color="primary" type="submit" class="elevation-0">
                                    <v-icon v-show="!creatingNewStudent">check</v-icon>
                                    <v-icon v-show="creatingNewStudent">fas fa-circle-notch fa-spin</v-icon>
                                    &nbsp;{{$t('classMgmt.addNewStudentButton')}}
                                </v-btn>
                            </v-card-actions>
                        </form>
                    </v-stepper-content>
                    <v-stepper-content step="4" class="pa-0" id="transferStudentModalAlert">
                        <v-layout class="pa-3 ml-2">
                                <v-icon color="info">info</v-icon>
                                <v-card-text>
                                    <strong>{{transferAlertMessage}}</strong>
                                </v-card-text>
                        </v-layout>

                        <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" @click="cancelTransfer">
                                    {{$t('classMgmt.cancel')}}
                                </v-btn>
                                <v-btn color="primary" @click="transferStudent(newUser)">
                                    {{$t('classMgmt.transfer')}}
                                </v-btn>
                            </v-card-actions>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </div>
    </UserManagmentModal>
</template> 

<script>
    import APIs from 'mixins/APIs'
    import Constants from 'mixins/Constants'
    import LanguageToolTip from 'components/classMgmt/LanguageTooltip'
    import Messaging from 'components/layout/Messaging'
    import PrintHelper from 'mixins/PrintHelper'
    import ProductInfo from 'mixins/ProductInfo'
    import StudentIdToolTip from 'components/classMgmt/StudentIDTooltip'
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import { mapGetters, mapActions } from 'vuex'

	export default {
        name: 'AddNewStudentModal',
        components: { LanguageToolTip,  Messaging, UserManagmentModal, StudentIdToolTip },
        mixins: [APIs, Constants, PrintHelper, ProductInfo],
        props: {
            allStudents: Array,
            exceedsStudentCap: Boolean,
            hasGradeLevelLessThan: Function,
            isDistrictAdmin: Boolean,
            loadingStudents: Boolean,
            multiProduct: Boolean,
            paginationOptions: Object,
            productId: Number,
            seatInfo: Function,
            selectedClass: Object,
            selectedSchool: Object,
            students: Array,
            studentsInSelectedClass: Array,
            subMaxSector: Number,
            subscriptionId: Number,
            subscriptions: Array,
            tenantID: Number,
            value: Boolean,
        },
        data() {
            return {
                addStudentSuccess : false,
                newUser : {languageId: this.selectedSchool ? this.selectedSchool.settings.defaultLanguageId : null},
                stepID : 1,
                existingStudent : [],
                transferStudentAlert : 0,
                currentStudentInRosterName : '',
                allFetchedRosterStudents : [],
                creatingNewStudent : false
            }
        },
		computed : {
            ...mapGetters('platformData/profile', ['isAdmin']),
            ...mapGetters('platformData/profile', ['profile']),
            assignmentAccessStepText(){
                let assignmentTypes = []
                let finalText = ""
                this.subscriptions.forEach(sub => { 
                    if (this.productSettings[sub.productId] && !assignmentTypes.includes(this.productSettings[sub.productId].assignmentType)){
                        assignmentTypes.push(this.productSettings[sub.productId].assignmentType)
                    }
                })
                assignmentTypes.forEach(type => { 
                    if (finalText.length < 1){
                        finalText = this.$tc('classMgmt.' + type, this.localizationConstants.SINGLE)
                    } else {
                        finalText = this.$t('classMgmt.productSettings')
                    }
                })
                return finalText
            },
            availableSectors(){
                let upperLimit = this.subMaxSector
                if(!Number.isInteger(upperLimit)){
                    upperLimit = 0
                }
                return this.sectorIDs.slice(0,upperLimit+1)
            },
            productDropdowns(){
                let products = []
                let dropDowns = []
                this.subscriptions.forEach(sub => { 
                    products.push(this.productSettings[sub.productId])
                    products[products.length-1].id = sub.productId
                })

                //remove products that will fail due to class codes
                if (this.skipAutoLicensing) {
                    products = products.filter(sub => sub.id != 3)
                } if (this.newUser.isClassCodeEnabled) {
                    products = products.filter(sub => sub.id == 3)
                }

                for (let i = 0; i<this.productDisplayOrder.length; i++){
                    if (products.find(product => {return product.id==this.productDisplayOrder[i]})){
                        dropDowns.push(products.find(product => {return product.id==this.productDisplayOrder[i]}))
                    }
                }
                return dropDowns
            },
            localizationConstants() {
                return {
                    NONE: 0,
                    SINGLE: 1,
                    MULTIPLE: 2
                }
            },
            platform(){
                 return this.$store.state.platform
            },
            showStepperHeader() {
                return this.stepID < 4
            },
            existingStudentSchoolName() {
                return this.existingStudent.length ? this.existingStudent[0].institutionName : ''
            },
            existingStudentSchoolId() {
                return this.existingStudent.length ? this.existingStudent[0].institutionId : ''
            },
            existingStudentName() {
                return this.existingStudent.length ? `${this.existingStudent[0].firstName} ${this.existingStudent[0].lastName}` : ''
            },
            skipAutoLicensing() {
                if(this.isAdmin){
                    return true
                } else {
                    return this.selectedSchool.isClassCodeEnabled && !this.selectedClass.isClassCodeEnabled
                }
            },            
            transferAlertMessage() {
                if (this.transferStudentAlert == 1) {
                    let alert = this.$t('error.transferAlert').replace('{school}', this.existingStudentSchoolName)
                    return alert.replace('{student}', this.existingStudentName)
                } else if (this.transferStudentAlert == 2) {
                    return this.$t('error.addToClassAlert').replace('{student}', this.currentStudentInRosterName);
                } else if (this.transferStudentAlert == 3) {
                    let alert = this.$t('error.adminTransferAlert').replace('{school}', this.existingStudentSchoolName)
                    return alert.replace('{student}', this.existingStudentName)
                } else {
                    return ''
                }
            },
            transferHeader() {
                if (!this.isAdmin) {
                    if(this.transferStudentAlert == 2) {
                        return this.stepID == 4 ? this.$t('classMgmt.addToClassStudentHeader') : this.$t('classMgmt.addStudentNewHeader')
                    } else {
                        return this.stepID == 4 ? this.$t('classMgmt.transferStudentHeader') : this.$t('classMgmt.addStudentNewHeader')
                    }
                } else {
                    return this.stepID == 4 ? this.$t('classMgmt.transferStudentHeader') : this.$t('classMgmt.addStudentToRosterHeader')
                }
            },
        },
        methods: {
            ...mapActions('platformData/students', ['createStudents', 'fetchStudentsById', 'transferStudents', 'fetchStudents', 'updateStudents']),
            ...mapActions('platformData/classes', ['addStudentsToClass']),
            async addToClass() {
                let targetInstitutionId = this.selectedSchool ? this.selectedSchool.id : null
                let classId = this.selectedClass ? this.selectedClass.id : null
                let payloadId = this.existingStudent[0].id 
                let newUser = this.existingStudent[0]
                let skipAutoLicensing = this.skipAutoLicensing

                let fetchedUser = await this.fetchStudentsById({ studentIds: [this.existingStudent[0].sisUserId], tenantId: this.tenantID });
                await this.addStudentsToClass({institutionId: targetInstitutionId, classId, studentIds: [payloadId], skipAutoLicensing})
                
                //must reset keys to match expected object keys for step 3
                Object.defineProperties(newUser, {
                    firstname: {
                        value: fetchedUser[0].firstName
                    },
                    lastname: {
                        value: fetchedUser[0].lastName
                    },
                    password: {
                        value: fetchedUser[0].passwordClear
                    },
                    loginID: {
                        value: fetchedUser[0].id
                    }
                })
                this.newUser = newUser   
                await this.$emit("updateModel", targetInstitutionId);
                this.stepID = 3
                this.$emit('trackAddStudents', 'manual_entry', 'roster', 1)
                this.$emit("handleSuccess", newUser.firstname + ' ' + newUser.lastname + ' ' + this.$t('classMgmt.successfullyAddedToClass'))
            },
            closeDialogs() {
                this.$emit('closeDialogs')
            },
            cancelTransfer() {
                this.stepID = 1
            },
            async transferStudent(student) {
                if(!this.isAdmin){
                    student.reflexJson = JSON.stringify({AssignmentID:String(student.assignmentId)})
                }
                let targetInstitutionId = this.selectedSchool ? this.selectedSchool.id : null
                let currentInstitutionId = this.existingStudentSchoolId
                let tenantId = this.tenantID
                let classId = this.selectedClass ? this.selectedClass.id : null
                let payloadId = this.existingStudent[0].id
                let skipAutoLicensing = this.skipAutoLicensing
                let self = this;

                (async function() {
                    const transferPayload = [{loginID: payloadId, institutionID: currentInstitutionId}]
                    await self.transferStudents({institutionId: targetInstitutionId, students: transferPayload})
                    let newUser = self.existingStudent[0]
                    if(!self.isAdmin){
                        await self.addStudentsToClass({institutionId: targetInstitutionId, classId, studentIds: [payloadId], skipAutoLicensing})
                        await self.$emit("updateModel", targetInstitutionId);
                        for (let i = 0; i < self.subscriptions.length; i++){
                            if (student[self.productSettings[self.subscriptions[i].productId].newUserItems]){
                                const subscriptionIds = [self.selectedSchool.subscriptions.find(sub => sub.productId === self.subscriptions[i].productId && sub.isActive && !sub.isExpired).id]
                                await self.platform.addUsersToSubscription({tenantId, institutionId: targetInstitutionId, subscriptionIds , loginIds: [payloadId]})
                                //add student assignments to reflex and frax
                                const studentToUpdate = {...student, id: payloadId}
                                if (self.subscriptions[i].productId == 1 && student.assignmentId) {
                                    await self.updateStudents({institutionId: targetInstitutionId, tenantId, students: [studentToUpdate]});
                                } else if (self.subscriptions[i].productId == 2 && student.sectorId) {
                                    await self.$emit('updateSectors', [studentToUpdate], student.sectorId, true);
                                }
                                newUser[self.productSettings[self.subscriptions[i].productId].newUserItems] = student[self.productSettings[self.subscriptions[i].productId].newUserItems]
                            }
                        }
                        //must reset keys to match expected object keys for step 3
                        Object.defineProperties(newUser, {
                            firstname: {
                                value: newUser.firstName
                            },
                            lastname: {
                                value: newUser.lastName
                            },
                            password: {
                                value: newUser.passwordClear
                            },
                            loginID: {
                                value: newUser.id
                            }
                        })
                        self.newUser = newUser                   
                        await self.$emit("updateModel", targetInstitutionId);
                        self.stepID = 3
                        self.$emit('trackAddStudents', 'manual_entry', 'transfer', 1)
                        self.$emit("handleSuccess", newUser.firstname + ' ' + newUser.lastname + ' ' + self.$t('classMgmt.successfullyAddedToClass'))
                    } else {
                        await self.$emit("updateModel", targetInstitutionId);
                        self.$emit('trackAddStudents', 'manual_entry', 'transfer', 1)
                        self.$emit("handleSuccess", self.$t('classMgmt.transferSuccess'))
                        self.closeDialogs()
                    }
                })()
            },
            async createStudent(student){
                this.creatingNewStudent = true
                let valid = await this.$validator.validateAll()
                if(valid){
                    if(!this.isAdmin){
                        student.reflexJson = JSON.stringify({AssignmentID:String(student.assignmentId)})
                    }
                    let students = [student]
                    let institutionId = this.selectedSchool ? this.selectedSchool.id : null
                    let tenantId = this.tenantID
                    let classId = this.selectedClass ? this.selectedClass.id : null
                    let studentId = student.sisUserId
                    let skipAutoLicensing = this.skipAutoLicensing
                    let self = this;
                    let response
                    try {
                        response = await self.createStudents({institutionId, tenantId, students})
                        if(student.sectorId){
                            await self.$emit('updateSectors', students, student.sectorId, true)
                        }
                    } catch (error) {
                        console.log(error)
                        if(error.message.indexOf('406')){
                            self.existingStudent = await self.fetchStudentsById({ studentIds: [studentId], tenantId });  
                            if(self.existingStudent[0].isRostered) {
                                //rostered students cannot be transferred
                                self.setStudentIsRosteredErrorMsg()
                                self.stepID = 1
                            } else if(!self.isAdmin) {
                                if (self.checkForLastNameMatch()) {
                                    //if ID and last name match
                                    self.transferStudentAlert = 1;                   
                                    self.stepID = 4
                                } else {
                                    //if ID and last name DONT match
                                    self.setStudentsInTenantErrorMsg()
                                    self.stepID = 1
                                }
                            } else {
                                if (self.isDistrictAdmin) {                                        
                                    self.setAdminStudentsInTenantErrorMsg()
                                } else {
                                    //have to get all the students in the roster for admins so we can check and give the right messaging
                                    self.allFetchedRosterStudents = await self.fetchStudents({institutionId})
                                    if (self.checkForStudentsInRoster(self.existingStudent[0].sisUserId, self.allFetchedRosterStudents)) {
                                        let alert = self.$t('error.idInRoster').replace('{name}', `"${self.existingStudentName}"`)
                                        self.$store.commit('setMessageError', alert)
                                    } else {
                                        //no ID match in roster, so student is in teh Tenant, so check if last names match
                                        if (self.checkForLastNameMatch()) {
                                            //ID and Last name match student in another school, so give em the transfer warning
                                            self.transferStudentAlert =  3;  
                                            self.stepID = 4
                                        } else {
                                            //ID and last name do not match, so give em the IDinTenant error
                                            self.setAdminStudentsInTenantErrorMsg()
                                        }
                                    }
                                }
                            }
                        }
                        return
                    }
                    let newUser = response[0]
                    const studentIds = [newUser.id]
                    if(!self.isAdmin){
                        
                        await self.addStudentsToClass({institutionId,classId,studentIds, skipAutoLicensing})
                        for (let i = 0; i < self.subscriptions.length; i++){
                            if (student[self.productSettings[self.subscriptions[i].productId].newUserItems]  && (!skipAutoLicensing || self.subscriptions[i].productId != 3 )){
                                const subscriptionIds = [self.selectedSchool.subscriptions.find(sub => sub.productId === self.subscriptions[i].productId && sub.isActive && !sub.isExpired).id]
                                await self.platform.addUsersToSubscription({ tenantId, institutionId, subscriptionIds, loginIds: studentIds })
                                newUser[self.productSettings[self.subscriptions[i].productId].newUserItems] = student[self.productSettings[self.subscriptions[i].productId].newUserItems]
                            }
                        }
                    }
                    self.newUser = newUser
                    await self.$emit("updateModel", institutionId)
                    await self.$emit('trackAddStudents', 'manual_entry', 'new', 1)
                    if (!self.isAdmin) {
                        self.stepID = 3
                        await self.$emit("handleSuccess", newUser.firstName + ' ' + newUser.lastName + ' ' + self.$t('classMgmt.successfullyAddedToClass'))
                    } else {
                        await self.$emit("handleSuccess", newUser.firstName + ' ' + newUser.lastName + ' ' + self.$t('classMgmt.successfullyCreated'))
                        await self.closeDialogs()
                    }
                } else {
                    this.editStudentModalRequiredAlert = true
                }
                this.creatingNewStudent = false
            },
            finalLetterIsS(word){
                if(word){
                    let finalChar = word.charAt(word.length-1)
                    if(finalChar.toUpperCase()=='S'){
                        return true
                    }
                }
                return false
            },
            getDropDownOptions(optionsString){
                return this[optionsString]
            },
            hasPrerequisite(selectItem){
                return Boolean(selectItem.prerequisite)
            },
            hasProductSubscription(subscriptions,prodID){
                let hasSub = false
                subscriptions.forEach(sub => {
                    if(sub.id){
                        let prodId = sub.productID || sub.productId
                        if(prodId==prodID){
                            hasSub = true
                        }
                    }
                })
                return hasSub
            },
            async incrementCurrentStep(){
                let valid = await this.$validator.validateAll()
                let self = this;
                if(valid && !this.isAdmin){
                    if (this.checkForStudentsInClass(this.newUser.sisUserId)) {
                        //if studentID is in the class, give em the IDinClass error
                        this.stepID = 1   
                        this.setStudentsInClassErrorMsg(this.newUser.sisUserId)                                           
                    } else if (self.checkForStudentsInRoster(this.newUser.sisUserId, this.allStudents)){
                        //if ID is in the school roster, set the existing student, then check if the last name matches the student with the matching ID
                        self.setExistingStudentFromRoster()           
                        if (self.checkForLastNameMatch()) {
                            //if the last name and ID match send em to the transfer warning step
                            this.transferStudentAlert =  2;  
                            this.stepID = 4
                        } else {
                            //if the last name doesnt match but the ID does exist in teh school roster, give em the IDinRoster error
                            this.setStudentsInRosterErrorMsg(this.newUser.sisUserId)
                        }                        
                    } else {
                        //if the ID isn't in the class or roster, let em carry on
                        this.productDropdowns.forEach(product=>{
                            if (this.seatInfo(product.id).studentDelta < 1) {
                                this.newUser[product.newUserItems] = 0
                            } else if(product.autoEnroll){
                                this.newUser[product.newUserItems] = product.autoEnroll
                            } 
                        })
                        this.stepID++
                    }            
                } else if (valid && this.isAdmin) {
                    this.stepID++
                }
            },
            optionsHavePrerequisites(assignments){
                let hasPrerequisite = false
                assignments.forEach(assignment => {
                    hasPrerequisite = hasPrerequisite || this.hasPrerequisite(assignment)
                })
                return hasPrerequisite
            },
            productHint(product){
                if(this.seatInfo(product.id).studentDelta<1){
                    return this.$t('classMgmt.studentCapReached',{product:this.productDisplayName(product.id),count:this.seatInfo(product.id).studentsCap})
                } else if(product.autoEnroll){
                    return this.$t('classMgmt.autoEnroll',{product:this.productDisplayName(product.id)})
                }
                return null
            },
            setup() {
                this.newUser = {
                    languageId: this.selectedSchool.settings.defaultLanguageId, 
                    assignmentId: (this.seatInfo(1).studentDelta<1) ? 0 : null, 
                    isClassCodeEnabled: this.selectedSchool.isClassCodeEnabled && this.selectedClass.isClassCodeEnabled
                }
                this.stepID = 1
                this.errors.clear()
            },
            setStudentsInClassErrorMsg(userID) {
                function isIdPresent(user) {
                    return user.sisID === userID;
                }
                
                const currentStudent = this.studentsInSelectedClass.find(isIdPresent)
                const currentStudentName = `${currentStudent.firstName} ${currentStudent.lastName}`;
                let alert = this.$t('error.idInClass').replace('{name}', `"${currentStudentName}"`)
                this.$store.commit('setMessageError', alert)
            },
            setStudentsInRosterErrorMsg(userID) {
                function isIdPresent(user) {
                    return user.sisUserId === userID;
                }
                
                const currentStudent = this.allStudents.find(isIdPresent)
                const currentStudentName = `${currentStudent.firstName} ${currentStudent.lastName}`;
                let alert = this.$t('error.idInRoster').replace('{name}', `"${currentStudentName}"`)
                this.$store.commit('setMessageError', alert)
            },
            setStudentsInTenantErrorMsg() {
                let alert = this.$t('error.idInTenant').replace('{name}', `"${this.existingStudentName}"`)
                this.$store.commit('setMessageError', alert)
            },
            setAdminStudentsInTenantErrorMsg() {
                let alert = this.$t('error.adminIdInTenant').replace('{name}', `"${this.existingStudentName}"`)
                this.$store.commit('setMessageError', alert)
            },
            setStudentIsRosteredErrorMsg() {
                let alert = this.$t('error.studentIsRosteredTransfer').replace('{name}', `"${this.existingStudentName}"`)
                this.$store.commit('setMessageError', alert)
            },
            setExistingStudentFromRoster() {
                let self = this
                function isIdPresent(user) {
                    return user.sisUserId === self.newUser.sisUserId;
                }
                const currentStudent = this.allStudents.find(isIdPresent)
                this.existingStudent = [currentStudent];
                this.currentStudentInRosterName = `${currentStudent.firstName} ${currentStudent.lastName}`                    
            },
            checkForStudentsInClass(userID) {
                const idsArray = this.studentsInSelectedClass.map(student => student.sisID);
                return idsArray.includes(userID)
            },
            checkForStudentsInRoster(userID, students) {
                const idsArray = students.map(student => student.sisUserId);
                return idsArray.includes(userID)
            },
            checkForLastNameMatch() {      
                return this.existingStudent[0].lastName.toUpperCase() === this.newUser.lastName.toUpperCase()
            }
        },
        watch: {
            value(val) {
                if (val){
                    this.setup()
                }
            }
        }
	}
</script>

<style lang="less" scoped>
    #createStudentStepper {
        box-shadow: none;
        .v-stepper__step{
            padding: 25px 15px 15px
        }
        .v-divider{
            margin-top: 36px
        }
    }
</style>