<template>
    <div id="wrapper" :class=theme v-if="loaded">
      <Navbar v-if="!fullPage"></Navbar>
      <ExploreLearning v-if="elSkin||theme=='el'"></ExploreLearning>
      <Reflex v-else-if="theme==='reflex'"></Reflex>
      <Frax v-else-if="theme==='frax'||theme==='frax2'"></Frax>
      <Gizmos v-else-if="theme==='gizmos'"></Gizmos>
      <S4U v-else-if="theme==='s4us'"></S4U>
      <div v-else>
        <!-- Default Skin -->
        <router-view></router-view>
      </div>
      <FooterBar v-if="!fullPage"/>
    </div>
</template>

<script>
  import Navbar from 'components/layout/Navbar'
  import FooterBar from 'components/layout/TheFooter'
  import ExploreLearning from 'components/themes/ExploreLearning'
  import EnvironmentDetector from 'mixins/EnvironmentDetector'
  import Frax from 'components/themes/Frax'
  import Gizmos from 'components/themes/Gizmos'
  import LoginDetector from '../mixins/LoginDetector'
  import Reflex from 'components/themes/Reflex'
  import S4U from 'components/themes/S4U'
  import Tracking from 'mixins/Tracking'
  import { mapActions, mapState } from 'vuex';

  export default {
    name: 'ThemeSwitcher',
    components: {
      Navbar,
      FooterBar,
      ExploreLearning,
      Frax,
      Gizmos,
      Reflex,
      S4U
    },
    mixins: [EnvironmentDetector, Tracking, LoginDetector],
    data () {
			return {
        loaded: false,
        navbarAuth: null,
			}
		},
    computed: {
      ...mapState('platformData/app', ['claims', 'jti']),
      elSkin(){
        return this.$route.meta.elSkin
      },
      fullPage(){
        return this.$route.meta.fullPage || this.$route.query.embedded
      },
      isStudent(){
        return this.claims.roleID == 2 
      },
      theme(){
        return this.getEnvironment()
      }
    },
    methods: {
      ...mapActions('platformData/app', ['initApp', 'fetchModel', 'updateAuthClaims']),
      ...mapActions('platformData/profile', ['fetchProfile']),
    },
    watch: {
      async loggedIn(val) {
        this.loaded = false
        if(val) {
          try {
            // check if loggedin and make sure it loads when logging in
            this.navbarAuth = await this.initApp({ authType: 'generic'})
            if(!this.isStudent){
              await this.fetchModel()
              this.$store.commit('setModelLoaded', true)
              await this.fetchProfile()
            }
            this.loaded = true
          } catch {
            this.loaded = true
          }  
        }          
      },
      async jti(val)  {
        // const currClaims = this.navbarAuth.getClaims();
        // this.navbarAuth.setClaims({ ...currClaims, jti: val })
        await this.updateAuthClaims({ claims: {...this.claims, jti: val} })
      }
    },
    async created() {
      // Tracking Has to Happen Inside Vue-Router
      this.setTrackingInfo()  

      if(this.loggedIn) {
        try {
          // check if loggedin and make sure it loads when logging in
          this.navbarAuth = await this.initApp({ authType: 'generic'})
          if(!this.isStudent){
            await this.fetchModel()
            this.$store.commit('setModelLoaded', true)
            await this.fetchProfile()
          }
          this.loaded = true
        } catch {
          this.loaded = true
        }            
      } else {
        this.loaded = true 
      }
      
    }
  }
  </script>

  <style lang="less">
  #wrapper{
    height:100%;
    background: #333;
  }
  .reflex-green {
    color: #71BF4B !important;
  }
  .frax-purple {
    color: #6450E8 !important;
  }
  .gizmos-blue {
    color: #00A1E4 !important;
  }
  .s4u-yellow {
    color: #F2BB14 !important;
  }
  .override-info-bg.info {
    background-color: inherit !important;
  }
</style>
