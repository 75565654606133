import APIs from 'mixins/APIs'
export default {
	data(){
		return {
			userNameAvalible : true,
			checkingUserName : false,
			usernameUnavailableError : {
				field: 'username',
				msg: this.$t('error.usernameUnavailable'),
				scope: 'usernameCheck'
			}
		}
	},
	mixins: [APIs],
	methods: {
		async checkAvalbility(displayMsg = true){
  			if(this.userName){
  				this.checkingUserName = true
  				await this.$http.post(this.getUsernameAvalibility(),{username:this.userName}).then(response => {
  					if(response.data.Boolean){
						if(displayMsg){
							this.$store.commit('setMessageError', null)
							this.$store.commit('setMessageSuccess', this.$t('success.usernameAvailable'))
						}
						this.userNameAvalible = true
    				} else {
						if(displayMsg){
							this.$store.commit('setMessageSuccess', null)
							this.$store.commit('setMessageError', this.$t('error.usernameUnavailable'))
						}
						this.userNameAvalible = false
    				}
    				this.checkingUserName = false
				}).catch(() => {
					if(displayMsg){
						this.$store.commit('setMessageSuccess', null)
						this.$store.commit('setMessageError', this.$t('error.404'))
					}
					this.userNameAvalible = false
					this.checkingUserName = false
  				})
  			} else {
  				this.$store.commit('setMessageError', null)
  			}
  		}
	}
}
