<template>
    <v-dialog
        v-model="value"
        width="840"
        @input="closeModal"
    >
        <v-card>
            <v-card-title class="headline grey lighten-2">
                {{$t('home.trial.headline')}}
                <v-spacer></v-spacer>
                <v-btn flat icon @click="closeModal"><v-icon>close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <Messaging />
                <div v-if="schoolSelectionMode">
                    <form @submit.prevent="trialSignUp(selectedTrialProductID)">
                        <v-layout>
                            <v-flex class="body-1 my-2">
                                {{$t('home.trial.schoolSelectionInfo')}}
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex xs12>
                                <v-select
                                    v-model="selectedInstitutionID"
                                    :label="$t('home.trial.selectSchool')"
                                    :items="institutions"
                                    item-text="name"
                                    item-value="id"
                                    v-validate="'required'"
                                    name="school"
                                    :error-messages="errors.collect('school')">
                                </v-select>
                            </v-flex>   
                        </v-layout>
                        <v-layout>
                            <v-flex xs12 class="text-xs-right" >
                                <v-btn color="primary" class="elevation-0 mr-0" type="submit">
                                    {{$t('home.trial.startTrial')}}
                                </v-btn>
                            </v-flex>   
                        </v-layout>
                    </form> 
                </div>
                <div v-else>
                    <v-layout>
                        <v-flex class="body-1 my-2">
                            <p>{{$t('home.trial.intro')}}</p>
                        </v-flex>
                    </v-layout>
                    <v-layout>
                        <table class="text-xs-left">
                            <tr class="grey--text">
                                <th>Product</th>
                                <th>Description</th>
                                <th>Grade Level</th>
                            </tr>
                            <tr v-for='productID in productsToShow' :key="productID">
                                <td class="py-3 pr-1">
                                    <img :src="productSettings[productID].logoFull" :alt=" productDisplayName(productID)+' Logo'"/>
                                </td>
                                <td>{{productSettings[productID].description}}</td>
                                <td>Grades {{productSettings[productID].gradeBand}}</td>
                                <td class="text-xs-right">
                                    <v-btn color="primary" outline small class="mx-0 pt-1" :to="{name:'Demo',params:{environment:UrlFriendlyProductName(productID)}}" target="_blank">
                                        {{$t('home.trial.learnMore')}}
                                    </v-btn>
                                </td>
                                <td v-if="allowTrials && missingProductIDs.includes(productID)" class="text-xs-right btn-col">
                                    <v-btn @click="trialSignUp(productID)" :disabled="schoolSelectionNeeded" small color="primary" class="mx-0 pt-1 elevation-0">
                                        {{$t('home.trial.takeTrial')}}
                                    </v-btn>
                                </td>
                                <td v-else-if="optedOutProductIDs.includes(productID)" class="text-xs-right btn-col">
                                    <v-btn :to="{name:'OptIn'}" small color="primary" class="mx-0 pt-1 elevation-0 optIn">
                                        {{$t('home.trial.optIn')}}
                                    </v-btn>
                                </td>
                            </tr>
                        </table>
                    </v-layout>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import APIs from 'mixins/APIs'
    import LoginDetector from 'mixins/LoginDetector'
    import Messaging from 'components/layout/Messaging'
    import ProductInfo from 'mixins/ProductInfo'
    import { mapGetters } from 'vuex'

	export default {
        name : 'TrialModal',
        components : {Messaging},
        mixins : [APIs, LoginDetector, ProductInfo],
        props : {
            value: Boolean,
            allowTrials: Boolean,
            institutions: Array,
            missingProductIDs: Array,
            optedOutProductIDs: Array,
        },
        data(){
            return {
                schoolSelectionMode: false,
                selectedInstitutionID: null,
                selectedTrialProductID: null,
                trialTaken: false,
            }
        },
        computed : {
            ...mapGetters('platformData/profile', ['profile']),
            email(){
                return this.profile.email
            },
            productsToShow(){
                let productIDs = []
                this.productDisplayOrder.forEach(productID=>{
                    if(this.missingProductIDs.includes(productID) || this.optedOutProductIDs.includes(productID)){
                        productIDs.push(productID)
                    }
                })
                return productIDs
            },
            schoolSelectionNeeded(){
                return this.institutions>1 && !this.selectedInstitutionID
            }
        },
        methods : {
            closeModal(){
                this.$store.commit('setMessageError', null)
                this.$store.commit('setMessageSuccess', null)
                this.selectedTrialProductID = null
                this.schoolSelectionMode = false
                this.$emit('close')
            },
            async trialSignUp(productID){
                let valid = await this.$validator.validateAll()
                if(valid){
                    this.$store.commit('setMessageError', null)
                    this.$store.commit('setMessageSuccess', null)
                    if(this.institutions.length==1){
                        this.selectedInstitutionID = this.institutions[0].id
                    }
                    if(this.selectedInstitutionID){
                        const institutionID = this.selectedInstitutionID
                        const environment = this.productEviroment(productID)
                        this.selectedTrialProductID = null
                        this.schoolSelectionMode = false
                        this.selectedInstitutionID =  null
                        this.$http.post(this.getSsoTrialAPI(institutionID,environment), {}, this.jtiHeader).then(() => {
                            let email = this.email
                            this.$store.commit('setMessageSuccess', this.$t('home.trial.instructions',{email}))
                            if(typeof window.dataLayer != 'undefined'){
                                var dataObject = {
                                    event : 'trial_2b',
                                    product : environment,
                                    category : 'sso_platform'
                                }
                                window.dataLayer.push(dataObject);
                            }
                            this.trialTaken = true
                        }).catch(() => {
                            this.$store.commit('setMessageError', this.$t('home.trial.errorMsg'))
                        })
                    } else {
                        this.selectedTrialProductID = productID
                        this.schoolSelectionMode = true
                    }
                }
            },
            UrlFriendlyProductName(productID){
                return this.productEviroment(productID)
            },
        }
	}
</script>

<style lang="less" scoped>
    .v-card{
        font-family: 'Nunito';
    }
    .v-btn{
        font-weight: 800;
    }
    table {
        width: 100%;
        td {
            font-weight: 600;
        }
        img {
            height: 30px;
        }
        a {
            text-transform: none;
        }
        .v-input--switch {
            float: right;
            margin-right: -5px;
        }
        .btn-col{
            width: 100px;
            padding-left: 5px;
        }
        .optIn{
            width: 100%;
            text-transform: uppercase;
        }
    }
</style>