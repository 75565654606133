<template>
	<div>
		<v-layout wrap>
			<Headline :headline="$t('chooseStudent.header')" :subHeadline="teacherName+' / '+className"></Headline>
		</v-layout>
	  	<v-layout wrap>
		    <v-flex v-for="student in students" :key="student.username" xs12 sm6 md4 xl3>
				<v-tooltip 
					top 
					:disabled="hasAssignment(student)" 
					color='white' 
					content-class="no-login elevation-15"
				>
					<template #activator="{ on }" >
						<v-btn @click="selectStudent(student)" 
							color="white" 
							class="option"
							v-on="on"
							round 
						>
							{{student.name}}
						</v-btn>
					</template>
						<div class='no-login'>
							<div class="sorry" :class="getEnvironment()">
								{{$t('studentLogin.sorry',{product:productName})}}
							</div>
							<div class="instructions">
								{{$t('studentLogin.instructions',{product:productName,assignmentType:assignmentType})}}
							</div>
						</div>
				</v-tooltip>
		    </v-flex>
	  	</v-layout>
		<v-layout wrap>
			<v-flex xs12 mt-4>
				<router-link :to="{name:'AppClass'}" >
					<v-btn flat round dark large>
					<i class="material-icons">keyboard_arrow_left</i>
					{{$t('chooseStudent.back')}}
					</v-btn>
				</router-link>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
	import APIs from 'mixins/APIs'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import Headline from 'components/layout/Headline'
	import ProductInfo from 'mixins/ProductInfo'
	export default {
		name: 'SelectStudent',
		metaInfo : {
			title : 'Name'
		},
		components: {
			Headline
		},
		mixins: [APIs, EnvironmentDetector, ProductInfo],
		computed: {
			assignmentType(){
				return this.productAssignmentType(this.getProductCode())
			},
			className: function(){
				return this.$store.state.studentLogin.class.className
			},
			productName(){
				return this.productDisplayName(this.getProductCode())
			},
			students: function(){
                return this.$store.state.studentLogin.students
			},
			teacherName: function(){
				return this.$store.state.studentLogin.teacherName
			}
		},
		created: function(){
			if(!this.$store.state.studentLogin.students.length){
				this.$router.replace({name: 'AppClass'})
			}
		},
		methods: {
			hasAssignment(student){
				return student.licenses.includes(this.getProductCode())
			},
			selectStudent : function(student){
				if(this.hasAssignment(student)){
					this.$store.commit('setStudent', student)
					this.$router.push({ name: 'AppPassword'})
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.v-tooltip__content {
		border-radius: 10px;
		width: 320px;
		::after {
			content: " ";
			position: absolute;
			top: 100%; /* At the bottom of the tooltip */
			left: 50%;
			margin-left: -10px;
			border-width: 10px;
			border-style: solid;
			border-color: white transparent transparent transparent;
		}
	}
	.menuable__content__active {
		opacity: 1!important;
	}
	.no-login {
		font-size: 16px;
		color: black;
		padding: 5px 15px;
		div {
			margin: 10px 0;
		}
		.sorry {
			font-weight: 600;
		}
		.instructions {
			font-weight: 400;
		}
		.reflex{
			color:#6BB74C;
		}
		.frax{
			color:#6450E8;
		}
	}
</style>
