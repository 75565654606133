import APIs from 'mixins/APIs'
import ProductInfo from 'mixins/ProductInfo'
import { mapGetters } from 'vuex'
export default {
    mixins: [APIs,ProductInfo],
	computed: {
        ...mapGetters('platformData/subscriptions', ['subscriptionList']),
        ...mapGetters('platformData/teachers', ['teacherList']),
        ...mapGetters('platformData/profile', ['profile']),
        activeSubscriptions(){
            if(this.subscriptions){
                return this.subscriptions.filter(sub => {
                    if(Boolean(sub.productId) && this.licenseTypeIsExpiredExempted(sub.productId, sub.licensingTypeId)){
                        return sub.isActive
                    } else {
                        return sub.isActive && !sub.isExpired
                    }
                })
            } else {
                return []
            }
        },
        optedInSubscriptions(){
            return this.optOuts 
                ? this.activeSubscriptions.filter(sub => {
                    return !this.optOuts.includes(sub.productID)
                })
                : this.activeSubscriptions
        },
        optOuts(){
            return this.profile.productsOptOut
        },
        optedInSubscriptionsWithoutDuplicates() {
            const subs = []
            this.activeSubscriptions.forEach(sub => {
                if (!this.optOuts.includes(sub.productID) && !subs.map(s => s.productId).includes(sub.productId)) {
                    subs.push(sub);
                }
            })
            this.sortByIsFreemium(subs);
            return subs
        },
        showOptIn(){
			let isTeacher = this.profile.roleID == 3
			let isRostered = this.profile.isRostered
            let isMultiProduct = this.activeSubscriptions.length > 1
			let currentlyQualifies = isTeacher && isRostered && isMultiProduct
            let currentlyHasOptOuts = this.optOuts 
                ? Boolean(this.optOuts.filter(productID => {
                    return productID != 0
                }).length)
                : false
			return currentlyQualifies || currentlyHasOptOuts
		},
        subscriptions(){
            let orderedSubs = []
            if (this.subscriptionList) {
                this.productDisplayOrder.forEach(prodID => {
                    let subs = this.getSubscriptions(prodID, this.subscriptionList)
                    if(subs){
                        subs.forEach(sub => orderedSubs.push(sub))
                    }
                })
            }
            return orderedSubs
    	}
	},
    methods : {
        hasSubscription(product, subscriptions=this.optedInSubscriptions){
            return  Boolean(this.getSubscription(product, subscriptions))
        },
        isFreemium(sub){
            if(sub){
                const isExemption = this.licenseTypeIsExpiredExempted(sub.productId, sub.licensingTypeId)
                return sub.isExpired && isExemption
            } else {
                return false
            }
        },
        getProductIDs(subscriptions){
            let IDs = []
            subscriptions.forEach(sub => {
                if(!IDs.includes(sub.productId)){
                    IDs.push(sub.productId)
                }
            })
            return IDs
        },
        getSubscription(product, subscriptions=this.activeSubscriptions){
            if(subscriptions){
                if(typeof product=='string'){
                    product = this.getProductCode(product)
                }
                return subscriptions.find(sub => {
                    return sub.productId == product
                })
            } else {
                return []
            }
        },
        getSubscriptions(product, subscriptions=this.subscriptionList){
            if(subscriptions){
                if(typeof product=='string'){
                    product = this.getProductCode(product)
                }
                return subscriptions.filter(sub => {
                    return sub.productId == product
                })
            } else {
                return []
            }
        },
        getActiveOptedInSubscriptions(product, subscriptions=this.subscriptionList) {
            if(subscriptions && this.optedInSubscriptions){
                if(typeof product=='string'){
                    product = this.getProductCode(product)
                }
                return this.optedInSubscriptions.filter(sub => {
                    return sub.productId == product
                })
            } else {
                return []
            }
        },
        getActiveInstitutionSubscriptions(product, institution) {
            if(institution && this.optedInSubscriptions){
                if(typeof product=='string'){
                    product = this.getProductCode(product)
                }
                return institution.subscriptions.filter(sub => {
                    return sub.productId == product && sub.isActive
                })
            } else {
                return []
            }
        },
        sortByIsFreemium(subs) {
            subs.sort((a, b) => {
                // Prioritize sorting by .isFreemium property
                if (this.isFreemium(a) !== this.isFreemium(b)) {
                return this.isFreemium(a) ? 1 : -1;
                }
                // If .isFreemium is the same, maintain original order
                return 0;
            });
        }
    }   
}
