<template>
    <v-dialog
        v-model="value"
        width="600"
        persistent
    >
        <v-card id="firstClass">
            <v-card-text class="pa-4">
                <p class="display-1 mb-4" style="font-family: 'Nunito' !important">{{$t('home.firstClass.welcome')}}</p>
                <p class="subheading font-weight-bold">{{$t('home.firstClass.setup')}}</p>
                <form @submit.prevent="onFormSubmit">
                    <v-flex class="mt-4">
                        <v-text-field
                            v-model="displayName"
                            :label="$t('form.displayName')+': ('+$t('profile.displayNameTip')+')'"
                            :placeholder="displayNamePlaceHolder"
                            ref="displayName"
                            autofocus
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex v-if="!isClassCodeEnabledUser" class="mt-4">
                        <v-text-field
                            v-model="className"
                            :label="$t('home.firstClass.label')+':'"
                            :placeholder="$t('home.firstClass.default')"
                            v-validate="'required'"
                            name="class name"
                            :error-messages="errors.collect('class name')"
                            clearable
                        >
                            <template #append-outer>
                                <v-btn
                                    color="primary"
                                    class="elevation-0 ml-3"
                                    type="submit"
                                    :disabled="submitDisabled"
                                    large
                                >
                                    {{$t('home.firstClass.btn')}}
                                </v-btn>
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-layout v-else justify-end mt-3>
                            <v-btn                                    
                                    color="primary"
                                    class="elevation-0 ml-auto"
                                    type="submit"
                                    :disabled="submitDisabled"
                                    large
                                >
                                    {{$t('home.firstClass.btn')}}
                            </v-btn>
                    </v-layout>
                </form>
                <p class="subheading" v-if="!isClassCodeEnabledUser"><v-icon color="info">info</v-icon>&nbsp;{{$t('home.firstClass.info')}}</p>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import APIs from 'mixins/APIs'
    import Constants from 'mixins/Constants'
    import LoginDetector from 'mixins/LoginDetector'
    import ProductInfo from 'mixins/ProductInfo'
    import { mapActions, mapGetters } from 'vuex';
	export default {
        name : 'FirstClassModal',
        components : {},
        mixins : [APIs, Constants, LoginDetector, ProductInfo],
        props : {
            value: Boolean,
            institutions: Array,
            productIDs: Array,
            user: Object,
            hasCCDisabledClass: Boolean,
            hasCCEnabledClass: Boolean,
            hasCCEnabledProduct: Boolean,
            hasCCDisabledProduct: Boolean,
            selectedSchoolID: Number
        },
        data(){
            return {
                displayName: null,
                className: this.$t('home.firstClass.default'),
                submitDisabled: false
            }
        },
        computed : {
            ...mapGetters('platformData/profile', ['profile']),
            displayNamePlaceHolder(){
                if(this.profile.displayName){
                    return this.profile.displayName
                } else {
                    let name = ''
                    if(this.user.titleID){
                        name = name + this.getTitle(this.user.titleID)
                    } else {
                        name = name + this.user.firstName
                    }
                    name = name + ' ' + this.user.lastName
                    return name
                }
            },
            ownerID(){
                return this.user.id
            },
            isClassCodeEnabledUser() {
                return this.$store.getters.getIsClassCodeEnabledUser 
            }
        },
        methods : {
            ...mapActions('platformData/classes', ['createClass']),
            ...mapActions('platformData/app', ['fetchModel']),
            ...mapActions('platformData/profile', ['fetchProfile']),
            async onFormSubmit(){
                this.submitDisabled = true
                if (!this.institutions.length) {await this.fetchModel()}
                var valid = await this.$validator.validateAll()
                if(valid){
                    // const platform = this.$store.state.platform
                    let data
                    if (this.isClassCodeEnabledUser) {
                        let CCData = {
                            institutionId: this.selectedSchoolID,
                            productId: this.productIDs[0],
                            ownerId: this.ownerID,
                            name: 'My First Gizmos Class',
                            isClassCodeActive: true,
                            isClassCodeEnabled: true
                        }

                        let nonCCData = {
                            institutionId: this.selectedSchoolID,
                            productId: this.productIDs[0],
                            ownerId: this.ownerID,
                            name: 'My First Class',
                            isClassCodeActive: true,
                            isClassCodeEnabled: false
                        }

                        if(this.hasCCDisabledProduct && this.hasCCEnabledProduct) {
                            if (!this.hasCCEnabledClass && !this.hasCCDisabledClass) {
                                await this.createClass(nonCCData)
                                await this.createClass(CCData)
                            } else if (!this.hasCCEnabledClass && this.hasCCDisabledClass) {
                                await this.createClass(CCData)
                            } else if (this.hasCCEnabledClass && !this.hasCCDisabledClass){
                                await this.createClass(nonCCData)
                            }
                        } else if (this.hasCCDisabledProduct && !this.hasCCEnabledProduct) {
                            await this.createClass(nonCCData)
                        } else {
                            await this.createClass(CCData)
                        }
                    } else {
                        data = {
                            institutionId: this.selectedSchoolID,
                            productId: this.productIDs[0],
                            ownerId: this.ownerID,
                            name: this.className,
                            isClassCodeActive: true,
                            isClassCodeEnabled: false
                        }
                        await this.createClass(data)
                    }
                    
                    if(this.displayName !== this.profile.displayName){
                        var updatedProfile = {
                            displayName : this.displayName,
                            username : this.user.username,
                            firstname : this.user.firstName,
                            middlename : this.user.middleName,
                            lastname : this.user.lastName,
                            titleID : this.user.titleID,
                            email : this.user.email,
                            languageID : this.user.languageID,
                            isValidEmail : this.user.isValidEmail,
                            isSubscribedToEmails : this.user.isSubscribedToEmails,
                            isDeleted : this.user.isDeleted,
                            productID : this.productIDs[0],
                            productsOptOutJson: JSON.stringify(this.user.productsOptOut)
                        }
                        this.$http.put(this.getProfileUpdateAPI(), updatedProfile, this.jtiHeader).then(() => {
                            this.$store.commit('setLastName', this.displayName)
                        }, error => {
                            console.log(error)
                        })
                    }
                    await this.fetchModel()
                    await this.fetchProfile()
                    let msg = this.$t('home.firstClass.success',{name:this.className})
                    if(this.isClassCodeEnabledUser){
                        msg = this.$t('home.firstClass.genericSuccess',{name:this.className})
                    }
                    this.$store.commit('setMessageSuccess', msg) 
                    this.$emit('classCreated')
                }
                this.submitDisabled = false
            }
        },
        mounted(){
            this.$refs.displayName.focus()
        }
	}
</script>

<style lang="less" scoped>
    .v-card{
        font-family: 'Nunito';
    }
    .products {
        display: flex;
        
        align-items: center;
        flex-wrap: wrap;
        img {
            height: 35px;
            padding-right: 20px;
        }
    }
    .v-btn{
        font-weight: 800;
        margin-top: -15px;
    }
</style>