<template>
  <div class="student-import-dropzone">
    <vue-dropzone
      v-if="!hasFile"
      id="importDropzone"
      ref="dropzone"
      url="URL is 'required' but not needed"
      :accepted-file-types="'.csv'"
      :auto-process-queue="false"
      :language="{ dictDefaultMessage }"
      @vdropzone-file-added="onFileAdded"
    />

    <template v-if="hasFile">
      <div class="student-import-dropzone__summary">
        <div class="student-import-dropzone__summary-header">
          <h4>CSV File - {{ students.length }} students found</h4>
          <div class="student-import-dropzone__filename">
            <i class="material-icons">account_circle</i>
            <span>Source: {{ fileName }}</span>
          </div>
        </div>

        <div class="student-import-dropzone__summary-destination">
          <h4>{{ isClassCodeEnabledStudentProp ? destinationClass.name : destinationInstitution.name }}</h4>
          <div>Destination</div>
        </div>
      </div>

      <template v-if="alert.title">
        <div :class="getMessageClass(isValid ? 'warn' : 'bad')">
          <i class="material-icons">{{ isValid ? 'warning' : 'error' }}</i>
          <div>
            <h4>{{ alert.title }}</h4>
            <div v-if="alert.message">{{ alert.message }}</div>
            <ul v-if="studentDataErrors">
              <li v-for="error in studentDataErrors" :key="error">
                {{ error }}
              </li>
            </ul>
            <div v-if="!isValid">Please correct the file and try again.</div>
            <hr v-else>
          </div>
        </div>
      </template>

      <div v-if="isValid && !nothingToDo" class="student-import-dropzone__results">
        <div v-if="showMatchingPwordsAlert" class="student-import-dropzone__password-match-warning">
          <v-alert
            :value="true"
            type="warning"
          >
            This file will set the same password for multiple students. <strong>This is not recommended</strong>, because the students will be able to log into each other's accounts.
          </v-alert>
        </div>
        <div
          v-for="rm in resultMessages"
          v-show="(rm.key === 'add' && classID) || rm.rows.length"
          :key="rm.key"
        >
          <div :key="rm.key" :class="rm.cssClass">
            <i class="material-icons">{{ rm.icon }}</i>
            <div class="student-import-dropzone__results-text">
              <h4>{{ rm.text }}</h4>
              <span class="student-import-dropzone__results-text-subheader">{{ rm.subtext }}</span>
            </div>

            <div
              v-if="rm.rows && rm.rows.length"
              :key="rm.key"
              class="student-import-dropzone__results-toggle-container"
              @click="showTable = showTable === rm.key ? '' : rm.key"
            >
              <!--
                @slot for rendering the buttons for showing a table of students below each message
                  @binding {boolean} expanded Whether or not the dropdown is expanded
                -->
              <slot name="dropdown-button" :expanded="showTable === rm.key">
                <button class="student-import-dropzone__results-toggle">
                  {{ showTable === rm.key ? 'close' : 'open' }}
                </button>
              </slot>
            </div>
          </div>

          <!--
            @slot for rendering table of parsed student information.
              @binding {string} uid The unique identifier for the table being rendered
              @binding {object[]} rows Array of objects containing the data for a single student
              @binding {string} columns An object keyed with column names with each corresponding value being an object with column definition information
              @binding {string} show Whether or not the Dropzone component thinks this table should be shown
            -->
          <slot
            name="student-table"
            :uid="rm.key"
            :rows="rm.rows"
            :columns="rm.columns"
            :show="showTable === rm.key && rm.rows.length"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import * as SFI from 'components/classMgmt/StudentFileImporter';
import VueDropzone from 'vue2-dropzone';
import Papa from 'papaparse';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';
import _isEqual from 'lodash/isEqual';
import Lookup from '@explorelearning/lookup';

/**
 * Dropzone component for importing students via a csv file
 */
export default {
  components: { VueDropzone },
  props: {
    /**
     * All of the students in the currently loaded platform model (and any extra loaded from the school roster during the import process)
     */
    modelStudents: { type: Array, required: true },

    /**
     * The data object for the institution being imported into. Needs just an `id` and a `name` property.
     */
    destinationInstitution: { type: Object, required: true },

    /**
     * Callback functions to handle specific import events
     */
    callbacks: { type: Object, required: true },

    /**
     * Keyed object with values of messaging to display to the user
     */
    messages: { type: Object, required: true },

    /**
     * The (optional) class being imported into. Just needs `classId` and `studentIds` properties.
     */
    destinationClass: { type: Object, required: false, default: null },

    /**
     * Whether or not to display the full set of demographic options, used for users with the admin role
     */
    fullOptions: { type: Boolean, default: false },

    /**
     * Whether or not rostering is enabled for this institution (meaning the user should be prevented from creating new students)
     */
    isRostered: { type: Boolean, default: false },

    /**
     * Whether or not the dropzone currently has the data from a parsed file displayed
     * @sync
     */
    hasFile: { type: Boolean, default: false },

    /**
     * Whether or not the currently displayed file information is valid
     * @sync
     */
    isValid: { type: Boolean, default: false },

    /**
     * Whether or not the data in the csv file has no differences from the existing students in our system
     * @sync
     */
    // eslint-disable-next-line vue/no-unused-properties
    noChanges: { type: Boolean, default: false },
    /**
     * available spots that a teacher can add, must not exceed this number with students being added
     */
    availableSpots: { type: Number, default: null },
    /**
     * whether or not the school and class are set as classCodeEnabled: used to set whether students will be classCodeEnabled
     */
    isClassCodeEnabledStudentProp: { type: Boolean, default: false }
  },
  emits: [
    'add-students-to-class',
    'add-students-to-local-model',
    'add-students-to-product'
  ],
  data() {
    return {
      students: [],
      fileName: '',
      alert: { title: '', message: '' },
      existingStudents: [],
      showTable: '',
      showMatchingPwordsAlert: false
    };
  },
  computed: {
    innerIsValid: {
      get() {
        return this.isValid;
      },
      set(val) {
        /**
         * The update event for the `isValid` prop. Triggered whenever the `innerIsValid` computed property is set.
         * @type {boolean}
         */
        this.$emit('update:isValid', val);
      }
    },
    innerHasFile: {
      get() {
        return this.hasFile;
      },
      set(val) {
        /**
         * The update event for the `hasFile` prop. Triggered whenever the `innerHasFile` computed property is set.
         * @type {boolean}
         */
        this.$emit('update:hasFile', val);
      }
    },
    destinationInstitutionID() {
      return this.destinationInstitution.id;
    },
    dictDefaultMessage() {
      const msg = (this.messages || {}).dropzoneMessage || '';
      return `<div>${msg}</div>`;
    },
    columns() {
      let columns = {
        firstName: { name: 'First Name' },
        middleName: { name: 'Middle Name' },
        lastName: { name: 'Last Name' },
        userID: { name: 'User ID' },
        language: { name: 'Language' },
        grade: { name: 'Grade Level' }
      };

      if (this.isClassCodeEnabledStudentProp) {
        delete columns.userID;
      }

      if (this.fullOptions) {
        columns = {
          ...columns,
          password: { name: 'Password' },
          gender: { name: 'Gender' },
          ethnicity: { name: 'Ethnicity' },
          engProf: { name: 'Limited English Proficiency' },
          disadv: { name: 'Economically Disadvantaged' },
          special: { name: 'Special ED Status' }
        };
      }

      return columns;
    },
    updatingStudentColumns() {
      let columns = {
        firstname: { name: 'First Name' },
        middlename: { name: 'Middle Name' },
        lastname: { name: 'Last Name' },
        sisUserID: { name: 'User ID' },
        grade: { name: 'Grade Level' }
      };

      if (this.fullOptions) {
        columns = {
          ...columns,
          passwordClear: { name: 'Password' },
          gender: { name: 'Gender' },
          ethnicity: { name: 'Ethnicity' },
          engProf: { name: 'Limited English Proficiency' },
          disadv: { name: 'Economically Disadvantaged' },
          special: { name: 'Special ED Status' }
        };
      }

      columns.institutionName = { name: 'Current Institution' };

      return columns;
    },
    existingStudentColumns() {
      return {
        ...this.updatingStudentColumns,
        lastLoginOn: { name: 'Last Login' }
      };
    },
    destinationType() {
      return (!this.classID || isNaN(this.classID)) ? 'school' : 'class';
    },
    classID() {
      const { classID, classId, id } = this.destinationClass || {};
      return classID || classId || id || false;
    },
    classRosterIDs() {
      const { studentIDs, studentIds } = this.destinationClass || {};
      return studentIDs || studentIds || [];
    },
    studentCapError() {
      return this.availableSpots < this.students.length;
    },
    studentsToAdd() { // student accounts to add to class roster
      if (this.destinationType === 'school') {
        return []; // if we're in a school roster context, return an empty array
      }

      return this.students.filter(s => {
        const { id } = this.modelStudents.find((student) => {
          return this.upperCompare(student.sisUserId, s.userID);
        }) || {};
        return !this.classRosterIDs.includes(id);
      });
    },
    studentsToTransfer() {
      // checks the existing student's institution id with the current active institution ID to see if the student will be transfered
      return this.existingStudents.filter((existingStudent) => {
        return existingStudent.institutionID !== this.destinationInstitutionID;
      });
    },
    studentsToOverwrite() {
      // checks each value of the student's info from the file with the corresponding property of the existing student to see if their is any difference between the two
      // TODO: Fix this function returning an empty array and remove eslint disable comment
      // eslint-disable-next-line array-callback-return
      return this.existingStudents.filter((existingStudent) => {
        const studentFromFile = this.students.find((student) => {
          return this.upperCompare(student.userID, existingStudent.userID);
        });

        let fileData = {
          firstname: studentFromFile.firstName,
          middlename: studentFromFile.middleName,
          lastname: studentFromFile.lastName,
          gradeLevelID: Lookup.inverse.grade[studentFromFile.grade],
          languageID: Lookup.inverse.language[studentFromFile.language],
          passwordClear: studentFromFile.password
        };

        if (this.fullOptions) {
          const { gender, ethnicity, engProf, disadv, special } = studentFromFile;
          fileData = {
            ...fileData,
            genderID: Lookup.inverse.gender[gender],
            ethnicityID: Lookup.inverse.ethnicity[ethnicity],
            limitedEnglishProficiencyID: Lookup.inverse.engProf[engProf],
            ecoDisadvantagedID: Lookup.inverse.disadv[disadv],
            specialEdStatusID: Lookup.inverse.special[special]
          };
        }

        for (const prop in fileData) {
          const value = fileData[prop];
          const hasValue = (value !== undefined && value !== '');
          if (hasValue && !_isEqual(existingStudent[prop] + '', value + '')) {
            return true;
          }
        }
      });
    },
    studentsToCreate() {
      const existingIDs = this.existingStudents.map(s => s.userID);
      return this.students.filter((student) => {
        const studentID = student.userID;
        return !existingIDs.find(id => this.upperCompare(id, studentID));
      });
    },
    resultMessages() {
      const totalCount = this.students.length;
      const addCount = this.studentsToAdd.length;
      const createCount = this.studentsToCreate.length;
      const transferCount = this.studentsToTransfer.length;
      const updateCount = this.studentsToOverwrite.length;

      const addMessage = {
        key: 'add',
        cssClass: this.getMessageClass('good'),
        icon: 'add_circle_outline',
        text: `
          ${addCount} student${addCount === 1 ? '' : 's'} will be added to the
          class
        `,
        subtext: this.isClassCodeEnabledStudentProp
          ? 'Students in class code classes can only be created, not updated or transferred using file import.'
          : `${totalCount - addCount}
            ${(totalCount - addCount) === 1 ? 'is' : 'are'}
            already in the class`,
        columns: this.columns,
        rows: this.studentsToAdd
      };

      const createMessage = {
        key: 'create',
        cssClass: this.getMessageClass('info'),
        icon: 'business',
        text: `
          ${createCount} new student${createCount === 1 ? '' : 's'} will be
          added to the school roster
        `,
        subtext: `
          ${totalCount - createCount - transferCount}
          ${(totalCount - createCount - transferCount) === 1 ? 'is' : 'are'}
          already on the school roster
        `,
        columns: this.columns,
        rows: this.studentsToCreate
      };

      const updateMessage = {
        key: 'edit',
        cssClass: this.getMessageClass('info'),
        icon: 'edit',
        text: `
          ${updateCount} student${updateCount === 1 ? '' : 's'} will be
          updated
        `,
        columns: this.updatingStudentColumns,
        rows: this.studentsToOverwrite
      };

      const transferMessage = {
        key: 'trans',
        cssClass: this.getMessageClass('warn'),
        icon: 'swap_horiz',
        text: `
          ${transferCount} student${transferCount === 1 ? '' : 's'} will be
          transferred from other schools
        `,
        columns: this.existingStudentColumns,
        rows: this.studentsToTransfer
      };

      const messages = [createMessage, updateMessage, transferMessage];
      if (this.destinationType === 'class' && addCount > 0) {
        messages.unshift(addMessage);
      }

      if (this.isClassCodeEnabledStudentProp) {
        messages.splice(1, 2);
      }

      return messages;
    },
    nothingToDo() {
      return !this.studentsToAdd.length &&
        !this.studentsToCreate.length &&
        !this.studentsToOverwrite.length &&
        !this.studentsToTransfer.length;
    },
    alertOptions() {
      return {
        dataErrors: {
          title: 'The file provided has data errors.',
          message: this.messages.dataErrorAlert
        },
        existingStudentIDs: {
          title: 'The file provided has data inconsistencies. No changes have been made.',
          message: this.messages.existingStudentIDsAlert
        },
        noStudents: {
          title: 'No students were found in the file provided.',
          message: this.messages.noStudentsAlert
        },
        noDiacritics: {
          title: 'Diacritics are not allowed in passwords',
          message: ''
        },
        rosteredData: {
          title: 'The file provided has data errors.',
          message: this.messages.rosteredDataAlert
        },
        rosteredTransfer: {
          title: 'The file provided has data errors.',
          message: this.messages.rosteredTransferAlert
        },
        createNotAllowed: {
          title: 'The file provided has data errors.',
          message: this.messages.createNotAllowedAlert
        },
        capExceeded: {
          title: 'Too many students were found in the file provided.',
          message: this.messages.studentCapReachedAlert
        },
        nothingToDo: {
          title: 'Your file matches current student information.',
          message: this.messages.nothingToDoAlert
        },
        characterCount: {
          title: 'Your file includes data elements that are too long.',
          message: ''
        }
      };
    }
  },
  watch: {
    isValid(value) {
      this.innerIsValid = value;
    },
    nothingToDo(value) {
      if (value) { this.alert = this.alertOptions.nothingToDo; }
      this.$emit('update:noChanges', value);
    },
    hasFile(value) {
      if (value === false) {
        this.students = [];
        this.existingStudents = [];
        this.innerIsValid = false;
      }
    }
  },
  methods: {
    onSuccess() {
      /**
       * Triggered on a successful import
       * @type {undefined}
       */
      this.$emit('success');
    },
    onError(e) {
      /**
       * Triggered on an unsuccessful import. Emits the error object.
       * @type {object}
       */
      this.$emit('error', e);
    },
    upperCompare(a, b) {
      const formattedA = String(a || '').toUpperCase().trim();
      const formattedB = String(b || '').toUpperCase().trim();
      return formattedA === formattedB;
    },
    getMessageClass(type) {
      const element = 'student-import-dropzone__message';
      return `${element} ${element}--${type}`;
    },
    onFileAdded(file) {
      this.alert = '';
      this.studentDataErrors = [];

      Papa.parse(file, { skipEmptyLines: true, complete: this.onParseComplete });
      this.fileName = file.name;
      this.$refs.dropzone.removeAllFiles();
    },
    async onParseComplete(json) {
      this.innerHasFile = true;
      this.innerIsValid = false;
      let errors;
      const headers = json.data.shift();
      const { fullOptions, isRostered, alertOptions } = this;
      const { columns, valid, alert } = SFI.parseHeaders.call(this, headers, this.isClassCodeEnabledStudentProp);

      this.alert = alert || '';

      if (!valid) {
        return;
      }

      // format the student data based on the parsed header values
      const data = json.data.map(item => {
        const student = {};
        headers.forEach((prop, i) => { student[prop] = item[i]; });
        return student;
      });

      // validate character counts
      const characterCountErrors = SFI.validateCharacterCounts.call(this, { data, columns });
      if (characterCountErrors.length) {
        this.alert = alertOptions.characterCount;
        this.studentDataErrors = characterCountErrors;
        return;
      }

      // validate no diacritics
      const pwords = data.map(row => row[columns.password]);
      const diacriticsError = SFI.validateNoDiacritics.call(this, pwords);
      if (diacriticsError) {
        this.alert = alertOptions.noDiacritics;
        this.studentDataErrors = diacriticsError;
        return;
      }

      // validate the format of the data provided in the file
      errors = SFI.validateStudentData.call(this, { data, columns, fullOptions, isClassCodeEnabled: this.isClassCodeEnabledStudentProp });
      if (errors.length) { // if there are any formatting errors
        this.alert = alertOptions.dataErrors;
        this.studentDataErrors = errors;
        return;
      }

      // parse the provided data into an array of student objects
      try {
        this.students = SFI.parseStudentData({ data, columns, fullOptions, isClassCodeEnabled: this.isClassCodeEnabledStudentProp });
        if (this.students.length === 0) { // if we couldn't detect any students
          this.alert = alertOptions.noStudents;
          return;
        }
      } catch {
        this.alert = alertOptions.dataErrors;
        return;
      }

      // check for existing student accounts in the scope of the subscription
      await this.checkForExistingStudents().then(e => { errors = e; });
      if (errors.length) { // if protected fields are being updated
        const rosterDataErrors = errors.filter(e => e.hasRosterDataError);
        const rosteredTransferErrors = errors.filter(e => e.hasRosteredTransferError);
        let studentDataErrors = [];
        if (rosterDataErrors.length) {
          this.alert = alertOptions.rosteredData; // rostered data would be updated
          studentDataErrors = rosterDataErrors;
        } else if (rosteredTransferErrors.length) {
          this.alert = alertOptions.rosteredTransfer; // rostered student would be transfered
          studentDataErrors = rosteredTransferErrors;
        } else {
          this.alert = alertOptions.existingStudentIDs; // IDs already exist for students with different last names
          studentDataErrors = errors;
        }

        // check if all the students are in the same institution
        let allOneInstitution = true;
        const firstInst = studentDataErrors[0].student.institutionID;
        studentDataErrors.forEach(s => {
          if (s.student.institutionID !== firstInst) {
            allOneInstitution = false;
          }
        });

        // print the right student data errors
        if (rosterDataErrors.length || rosteredTransferErrors.length) {
          this.studentDataErrors = studentDataErrors.map(({ student: s }) => {
            return `${s.lastname}, ${s.firstname} (${s.sisUserID}) - ${s.institutionName}`;
          });
        } else if (allOneInstitution) {
          this.studentDataErrors = studentDataErrors.map(({ student: s, studentFromFile }) => {
            return `${s.lastname}, ${s.firstname} (${s.sisUserID}) does not match the name found in the file: "${studentFromFile.lastName}, ${studentFromFile.firstName}"`;
          });
        } else {
          this.studentDataErrors = studentDataErrors.map(({ student: s, studentFromFile }) => {
            return `${s.lastname}, ${s.firstname} (${s.sisUserID} - ${s.institutionName}) does not match the name found in the file: "${studentFromFile.lastName}, ${studentFromFile.firstName}"`;
          });
        }

        return;
      }

      // check if we are exceeding the student class cap
      if (this.studentCapError) {
        this.alert = alertOptions.capExceeded;
        this.innerIsValid = false;
        return;
      }

      // check if we need to show matchingPasswords warning
      if (this.studentsToCreate || this.studentsToOverwrite || this.studentsToTransfer) {
        this.showMatchingPwordsAlert = !SFI.validateNoMatchingPasswords(pwords);
      }

      await this.$nextTick();
      if (isRostered && this.studentsToCreate.length) {
        this.alert = alertOptions.createNotAllowed;
        this.studentDataErrors = this.studentsToCreate.map((s) => {
          return `${s.lastName}, ${s.firstName} (${s.userID})`;
        });
        this.innerIsValid = false; // need to explicitly set to false again in case it has been set to true since the nextTick resolved
        return;
      }

      this.innerIsValid = true;
    },
    async checkForExistingStudents() {
      const ids = this.students.map(s => s.userID);
      let students = [];
      try {
        const resp = await this.callbacks.checkForExistingStudents({ ids });
        students = resp.students;
      } catch (error) {
        this.onError(error);
      }

      this.existingStudents = students.map(s => ({
        id: s.id,
        firstname: s.firstName,
        middlename: s.middleName,
        lastname: s.lastName,
        sisUserID: s.sisUserId,
        userID: s.sisUserId,
        password: s.passwordClear,
        passwordClear: s.passwordClear,
        language: Lookup.language[s.languageId],
        grade: Lookup.grade[s.gradeId],
        gender: Lookup.gender[s.genderId],
        ethnicity: Lookup.ethnicity[s.ethnicityId],
        engProf: Lookup.engProf[s.engProfId],
        disadv: Lookup.disadv[s.disadvId],
        special: Lookup.special[s.specialEdId],
        institutionID: s.institutionId,
        institutionName: s.institutionName,
        ecoDisadvantagedID: s.disadvId,
        ethnicityID: s.ethnicityId,
        genderID: s.genderId,
        gradeLevelID: s.gradeId,
        languageID: s.languageId,
        limitedEnglishProficiencyID: s.engProfId,
        specialEdStatusID: s.specialEdId,
        isRostered: s.isRostered
      }));

      // checks the existing student's last name with the last name provided for that user in the file, which is a breaking error
      const hasUserIDError = (existingStudent, studentFromFile) => {
        const existingName = existingStudent.lastname;
        return !this.upperCompare(existingName, studentFromFile.lastName);
      };

      // checks if rostered data (first, middle, last name, and grade level) for the existing student doesn't match the data provided in the file
      const hasRosterDataError = (existingStudent, studentFromFile) => {
        const { firstname, lastname, middlename, grade, isRostered } = existingStudent;
        return isRostered && (
          !this.upperCompare(firstname, studentFromFile.firstName) ||
          !this.upperCompare(lastname, studentFromFile.lastName) ||
          !this.upperCompare(middlename, studentFromFile.middleName) ||
          !this.upperCompare(grade, studentFromFile.grade)
        );
      };

      // checks if rostered data (first, middle, last name, and grade level) for the existing student doesn't match the data provided in the file
      const hasRosteredTransferError = (existingStudent) => {
        const { institutionID, isRostered } = existingStudent;
        return isRostered && !(institutionID === this.destinationInstitutionID);
      };

      const studentsWithErrors = this.existingStudents.map((s) => {
        const studentFromFile = this.students.find((student) => {
          return this.upperCompare(student.userID, s.userID);
        });
        return {
          student: s,
          studentFromFile,
          hasRosterDataError: hasRosterDataError(s, studentFromFile),
          hasRosteredTransferError: hasRosteredTransferError(s),
          hasUserIDError: hasUserIDError(s, studentFromFile)
        };
      }).filter(i => i.hasRosterDataError || i.hasRosteredTransferError || i.hasUserIDError);

      return studentsWithErrors;
    },
    async upload() {
      try {
        const { studentsToTransfer, destinationInstitutionID, modelStudents } = this;
        let createdStudents = [];

        if (!_isEmpty(this.studentsToCreate)) {
          const studentData = this.studentsToCreate;
          const { students } = await this.callbacks.createStudents({ studentData });
          createdStudents = students;
        }

        const transferPayload = studentsToTransfer.map((s) => {
          return { loginID: s.loginID || s.id, institutionID: s.institutionID };
        });
        if (!_isEmpty(transferPayload)) {
          await this.callbacks.transferStudents({
            students: transferPayload,
            destinationId: destinationInstitutionID
          });
        }

        let updatePayload = this.studentsToOverwrite.map((student) => {
          const _student = this.students.find((s) => {
            return this.upperCompare(s.userID, student.sisUserID);
          });
          _student.id = student.loginID || student.id;
          _student.sisUserId = student.userID || student.sisUserID;
          _student.sisUserID = student.userID || student.sisUserID;
          _student.passwordClear = _student.password;
          return _student;
        });

        updatePayload = _uniqBy(updatePayload, up => up.id);

        updatePayload.forEach((item) => {
          Object.keys(item).forEach((key) => {
            const val = item[key];
            if (!val) { // need to make sure that empty values don't get used in update
              delete item[key];
            } else if (Lookup.inverse[key]) { // and set ids for mappable properties
              item[key + 'Id'] = Lookup.inverse[key][val];
            }
          });
        });

        if (!_isEmpty(updatePayload)) {
          // want to merge the items in the update payload here as a convenience so that the callback can pass the full student objects in the update request to the platform (which requires that the student objects have all their properties defined)
          const mergedStudentData = updatePayload.map(student => {
            const modelStudent = modelStudents.find(s => s.id + '' === student.id + '');
            if (!modelStudent) return student; // if we can't find a model student just return the (assumedly complete and formatted) student object
            return Object.keys(modelStudent).reduce((acc, key) => {
              acc[key] = (key in student) ? student[key] : modelStudent[key];
              return acc;
            }, {});
          });

          await this.callbacks.updateStudents({ studentData: mergedStudentData });
        }

        let processedStudents = [...createdStudents, ...this.existingStudents];
        processedStudents.forEach(s => { s.id = s.id || s.loginID; });
        processedStudents = _uniqBy(processedStudents, 'id');

        /**
         * Triggered when the file data has been processed and any calls to the backend have been successfully made. Emits an object with a `students` property containing an array of the student data objects. This student data will need to be used to update the client's local model.
         * @type {object}
         */
         this.$emit('add-students-to-local-model', { students: processedStudents });

        if (this.classID == null) {
          this.innerHasFile = false;
          this.onSuccess();
          return;
        }

        const studentsToAddIDs = this.studentsToAdd.map((studentToAdd) => {
          const student = processedStudents.find((s) => {
            const toAddID = studentToAdd.userID;
            return studentToAdd.userID ? this.upperCompare((s.userID || s.sisUserID), toAddID) : studentToAdd.id === s.id;
          });
          return student.id || student.loginID;
        });
        if (!_isEmpty(studentsToAddIDs)) {
          /**
           * Triggered if there are any new student ids found which need to be added to the class being imported into. Emits and object with a `studentIds` property containing an array of the student ids which need to be added.
           * @type {object}
           */
           this.$emit('add-students-to-class', { studentIds: studentsToAddIDs });
        }

        // TODO: update to detect based on a provided product ID
        const productStudents = studentsToTransfer.filter((student) => {
          try {
            return !!parseInt(JSON.parse(student.reflexJson).AssignmentID);
          } catch {
            return false;
          }
        });
        if (!_isEmpty(productStudents)) {
          /**
           * Triggered if there are any students that don't have a reflex assignment, and thus need to be added to the reflex product. Emits an object with a `studentIds` property containing an array of ids for students to add to the product.
           * @type {object}
           */
           this.$emit('add-students-to-product', {
            studentIds: productStudents.map(s => s.id)
          });
        }

        this.innerHasFile = false;
        this.onSuccess();
      } catch (error) {
        this.innerIsValid = false;
        this.onError(error);
      }
    }
  }
};
</script>
