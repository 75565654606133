<template>
    <div class="unified-login-skin">
        <div class="panel" :class="{currentEnvironment}" :style="{'max-width':width+'px'}">
            <slot></slot>
        </div>
        <component :is="footerComponent" />
    </div>
</template>

<script>
    import EnvironmentDetector from 'mixins/EnvironmentDetector'
    import HomeFooter from 'components/home/HomeFooter'
	export default {
		name: 'UnifiedLoginPanel',
        components: {HomeFooter},
        mixins: [EnvironmentDetector],
        computed: {
            footerComponent(){
                return this.$route.meta.fComp
            },
            logo(){
                return this.$route.meta.logo
            },
            width(){
				return this.$route.meta.cardWidth
			}
        }
	}
</script>

<style lang="less">
    
    @radius: 20px;
    @nav-color: #007bff;
    @nav-color-light: #DDE9FA;
    @base-color: rgba(255,255,255,.95);
    @bg-color: rgba(255,255,255,.95);

    @color-el-orange: #F58220;
    @color-el-blue: #2f4b7d;
    @color-gizmos-blue: #00A1E4;
    @color-reflex-green: #71BF4B;
    @color-frax-purple: #6450E8;
    @color-s4u-yellow: #F2BB14;

    .el-orange{
        .panel {
            box-shadow: 0px 15px 30px 0 rgba(0, 0, 0, 0.35);
        }
    }
    .el-orange-text {
        color: @color-el-orange;
    }

    .unified-login-skin{
        font-family: 'Nunito';
        color: #333;
        #logo {
            display: block;
            margin: 0 auto;
            width: 280px;
        }
        .list-enter-active, .list-leave-active {
            transition: all 3s;
        }
        .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
            opacity: 0;
        }
        .overflow-hidden {
            overflow: hidden;
        }
        .bounce-enter-active {
            animation: bounce-in .5s;
        }
        .bounce-leave-active {
            animation: bounce-in .5s reverse;
        }
        @keyframes spinner {
            to {transform: rotate(360deg);}
            }
            @keyframes pop-in {
            0% {
                transform: scale(.75);
            }
            100% {
                transform: scale(1);
            }
        }
        .panel {
            @radius: 20px;
            border-radius: @radius;
            margin: 60px auto;
            background: rgba(255,255,255,0.95);
            position: relative;
            .el-logo {
                width: 80px;
                margin-bottom: 1.5em;
                svg {
                    fill: @color-el-orange;
                }
            }
            .messaging{
                padding: 0 !important;
            }
            .links {
                margin: .75em 0 -0.75em 0;
                font-size: 1.1.em;
                font-weight: 600;
                a {
                    color: @color-gizmos-blue;
                    text-decoration: none;
                }
            }
            .footer {
                border-radius: 0 0 @radius @radius;
                margin-top: -16px;
                padding: 1.3em 1em .3em 1em;
                background: @color-el-orange;
                font-size: .9em;
                color: rgba(255,255,255,0.7);
                text-align: center;
                a {
                    color: rgba(255,255,255,0.7);
                    font-size: .9em;
                    text-decoration: none;
                    &:hover {
                        color: #fff;
                    }
                }
            }
            .body {
                border-radius: @radius;
                transition: all .5s;
                .cols {
                    display: flex;
                    align-items: flex-start;
                    .back {
                        display: block;
                        padding: .5em .75em;
                        font-size: 1.2em;
                        background: #eee;
                        color: #888;
                        border-radius: .75em 0 .75em 0;
                        &:hover {
                            color: @color-gizmos-blue;
                        }
                    }
                }
            }
            .header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1.5em;
            }
            .inner {
                padding: 1.7em 3em;
                display: flex;
                flex: 1;
                flex-direction: column;
                align-items: center;
                .ul-title {
                    color: #333;
                    width: 100%;
                    font-size: 1.2em;
                    margin-top: 1.4em;
                    margin-bottom: .5em;
                    display: flex;
                    &.cols {
                        margin: .75em 0 1.4em 0;
                        justify-content: space-between;
                    }
                }
            }
            .products {
                display: flex;
                margin-bottom: 1em;
                .product {
                    margin-right: .75em;
                    max-width: 30px;
                    overflow: hidden;
                    transition: all 1s;
                    svg path[fill="#000"] { fill: #ddd; }
                    &.active, &:hover {
                        max-width: 200px;
                        &.gizmos svg path[fill="#000"] { fill: @color-gizmos-blue; }
                        &.reflex svg path[fill="#000"] { fill: @color-reflex-green; }
                        &.frax svg path[fill="#000"] { fill: @color-frax-purple; }
                        &.s4u svg path[fill="#000"] { fill: @color-s4u-yellow; }
                    }
                    &:last-child {
                        margin: 0;
                    }
                    
                }
                &.active-gizmos .product.gizmos {
                    max-width: 200px;
                    svg path[fill="#000"] { fill: @color-gizmos-blue; }
                }
                &.active-reflex .product.reflex {
                    max-width: 200px;
                    svg path[fill="#000"] { fill: @color-reflex-green; }
                }
                &.active-frax .product.frax {
                    max-width: 200px;
                    svg path[fill="#000"] { fill: @color-frax-purple; }
                }
                &.active-s4u .product.s4u {
                    max-width: 200px;
                    svg path[fill="#000"] { fill: @color-s4u-yellow; }
                }
                &:hover {
                    .product {
                        max-width: 200px;
                    }
                }
            }
        }
        .chooser {
            .el-logo {
                width: 26px;
                margin: 0;
            }
            .inner {
                padding: 1.7em;
            }
            .products {
                margin: 0;
            }

            .student-list {
                width: 100%;
                display: grid;
                grid-template-columns: auto auto auto auto;
                gap: .75em;
                padding-top: .7em;
                animation: fadein .3s linear;
                .student {
                    min-width: 0;
                    color: @color-gizmos-blue;
                    border-radius: 6px;
                    font-weight: 700;
                    text-decoration: none;
                    text-align: center;
                    padding: .3em .5em .2em .5em;
                    background: #efefef;
                    margin-bottom: .3em;
                    cursor: pointer;
                    .icon {
                        color: #ccc;
                        display: block;
                        font-size: 1.5em;
                        margin:-0.75em auto 0 auto;
                        width: 1.3em;
                        height: 1.3em;
                        border-radius: 50%;
                        background: #efefef;
                    }
                    .names {
                        position: relative;
                        font-size: 1em;
                        font-weight: 700;
                        .name {
                            display: block;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        .full {
                            opacity: 0;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: .75em;
                            white-space: nowrap;
                        }
                    }
                    &:last-child {
                        margin: 0;
                    }
                    &:hover {
                        transform: scale(1.2);
                        box-shadow: 0px 4px 10px 0 rgba(0,0,0,0.4);
                        border-radius: 4px;
                        background: @color-gizmos-blue;
                        color: #fff;
                        .icon {
                            background: @color-gizmos-blue;
                            color: inherit;
                        }
                        .names .name { opacity : 0; }
                        .names .full { opacity : 1; }
                    }
                }
            }
            .user {
                color: @color-gizmos-blue;
                text-align: center;
                animation: fadein .3s linear;
                .fas {
                    display: block;
                    font-size: 4em;
                    margin-bottom: .15em;
                }
                .name {
                    display: block;
                    font-size: 2em;
                    font-weight: 600;
                }
                .info {
                    background: none !important;
                    display: block;
                    font-size: 1em;
                    color: #aaa;
                    font-weight: 600;
                    
                }
                &.sm {
                    .fas { font-size: 2.5em; }
                    .name { font-size: 1.5em; }
                }
            }
            .icons {
                display: grid;
                grid-template-columns: auto auto auto auto auto;
                gap: .35em;
                width: 100%;
                padding-top: 1em;
                animation: fadein .5s linear;
                button {
                    border: none;
                    cursor:pointer;
                    background: @color-gizmos-blue;
                    border-radius: 7px;
                    outline:  none;
                    transition: all .2s;
                    img {
                        width: 30px;
                        height: 30px;
                    }
                    &:hover {
                        // background: fade(@color-gizmos-blue, 80%);
                        transform: scale(1.4);
                        box-shadow: 0px 4px 10px 0 rgba(0,0,0,0.4);
                    }
                }
            }
            .selectedIcons {
                @size: 52px;
                display: flex;
                flex-wrap:wrap;
                .selectedIcon, .selectedIconPlaceHolder{
                    border: 2px dashed transparent;
                    background: @color-gizmos-blue;
                    border-radius: 10px;
                    outline:  none;
                    padding: 0 1.1em;
                    margin-right: .5em;
                    img {
                        width: @size;
                        height: @size;
                    }
                }
                .selectedIconPlaceHolder {
                    border-color: #ddd;
                    color: #ddd;
                    background: none;
                    span {
                        display: block;
                        font-size: 1.8em;
                        font-weight: 700;
                        text-align: center;
                        width: @size;
                        height: @size;
                        line-height: @size;
                    }
                }
            }
            
        }

        form {
            width: 100%;
            .input {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 8px;
                border: 1px solid #bbb;
                padding: .25em;
                background: #fff;
                font-size: 1.1em;
                font-weight: 600;
                color: #111;
                // box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.05);
                label {
                    // border: 1px solid red;
                    width: 1.6em;
                    height: 1.6em;
                    line-height: 1.6em;
                    font-size: 1.2em;
                    margin: 0 0 0 .5em;
                    color: #aaa;
                    &.good {
                        color: @color-reflex-green;
                    }
                    &.bad {
                        color: red;
                    }
                }
                input {
                    flex: 1;
                    height: 40px;
                    padding: .25em .35em;
                    border: none;
                    border-radius: .25em;
                    outline:  none;
                    background: transparent;
                    &:disabled {
                        background: none;
                    }
                    &.sm {
                        max-width: 9em;
                    }
                }
                button {
                    border: none;
                    cursor:pointer;
                    background: none;
                    width: 1.6em;
                    height: 1.6em;
                    line-height: 1.6em;
                    border-radius: 50%;
                    transition: all .2s;
                    color: #fff;
                    background: @color-gizmos-blue;
                    outline:  none;
                    margin: 0 .25em;
                    position: relative;
                    &:hover {
                        background: fade(@color-gizmos-blue, 80%);
                    }
                    &:disabled {
                        color: #7bbeda;
                        background: none;
                        cursor:not-allowed;
                    }
                    &.wait:disabled {
                        cursor:default;
                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            border: .17em solid transparent;
                            border-top-color: @color-gizmos-blue;
                            border-radius: 50%;
                            animation: spinner 1.1s linear infinite;
                        }
                    }
                    &.clearIcons {
                        color: #e69ba8;
                        background: none;
                        &:disabled {
                            opacity: .5;
                        }
                    }
                }
                &.bad {
                    border: 1px solid red;
                }
                &.sm {
                    padding: .35em 0.05em;
                    border-radius: 5px;
                    input {
                        height: 1.4em;
                        padding: .15em .55em;
                    }
                    button {
                        font-size: .95em;
                    }
                }
                &.lg {
                    button {
                        font-size: 1.9em;
                    }
                }
            }
            .flat-bottom {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
            .input + .input {
                border-radius: 0 0 8px 8px;
                border-top: none;
            }
            
        }

        .switcher {
            position: fixed;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            background: rgba(0,0,0,0.7);
            border-radius: 6px 6px 0 0;
            color: rgba(255,255,255,0.8);
            text-align: center;
            font-size: .8em;
            overflow: hidden;
            a {
                display: inline-block;
                padding: 0.25em .5em;
                text-decoration: none;
                &.active {
                    background: #000;
                    color: #fff;
                }
            }
            .url {
                padding: 0.25em .5em;
            }
        }
        .tabs {
            @radius: 20px;
            @sm-radius: 6px;
            @color-bg: #ddd;
            @color-bg-hover: #ccc;
            display: flex;
            justify-content: space-evenly;
            transition: all .2s;  
            min-width: 300px;
            user-select: none;
            a {
                display: flex;
                flex: 1;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: .7em .4em .4em .4em;
                color: #555;
                background: @color-bg;
                text-decoration: none;
                transition: all .1s;
                min-width: 60px;
                position: relative;
                .text, .ul-title {
                    display: block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 100%;
                }
                .ul-title {
                    font-size: 1.5em;
                    font-weight: 550;
                }
                &:hover {
                    color: rgba(255,255,255,.85);
                    background: @color-bg-hover;                
                }
                &:first-child {
                    border-top-left-radius: @radius;
                    border-bottom-right-radius: @radius;
                }
                &:last-child {
                    border-top-right-radius: @radius;            
                }
                &.active + a {
                    border-bottom-left-radius: @radius;
                }
                &.active {
                    color: #555;
                    background: none;
                    cursor: default;
                    z-index:1;
                }
            }   
        }
        .tabs a.active { color: @color-el-orange; } 
        // .gizmos .tabs a.active { color: @color-gizmos-blue; }
        // .reflex .tabs a.active { color: @color-reflex-green; }
        // .frax .tabs a.active { color: @color-frax-purple; }

        .main-nav {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 2em;
            margin:0 0 4em 0;
            > a {
                display: block;
                svg {
                    width: 120px;
                    margin:5px 40px 0 0;
                }
            }
            nav {     
                display: flex;
                min-width: 20px;
                a {
                    transition: all .5s;     
                    display: flex;
                    color: #fff;
                    text-decoration: none;
                    padding: 6px 12px;
                    border-radius: 6px;
                    min-width: 20px;
                    &:hover {
                        background: rgba(255,255,255,0.2);
                    }
                    span {
                        font-size: 90%;
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .fas {
                        font-size: 1.2em;
                        margin: 0 6px;
                    }
                }
                
            }
        }

        #app {
            animation: fadein 1.5s;
        }

        @keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }
    }
     .tippy-tooltip {
        font-family: 'Nunito';
        font-size: .9rem;
        line-height: 1.5;
        font-weight: 900;
    }
</style>
