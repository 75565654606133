<template>
	<form @submit.prevent="updateProfile">
		<v-flex headline grey--text text--darken-2 class="tabHeading hidden-sm-and-up">{{$t('profile.profileTab')}}</v-flex>
		<v-flex offset-lg1 lg10 xs12>
			<v-alert
				:value="isRostered"
				color="info"
				icon="info"
				outline
				class="ma-1 rosteringInfo"
			>
				Your account is managed by your institutions's Student Information System.
				As such, you will be unable to change some details about your account through our system.
				To learn more, please visit our <a :href="getHelpCenterURL('article/Reflex-Rostering-FAQ')" target="_blank">help center</a>.
			</v-alert>
			<Messaging offset-lg1 lg10 xs12 outline></Messaging>
		</v-flex>
		<v-layout wrap>
			<v-flex offset-lg1 lg1 md2 sm6 xs12 >
				<v-autocomplete
					v-model="titleID"
					v-bind:items="userTitles"
					item-value="id"
					item-text="label"
					:label="$t('form.title')">
				</v-autocomplete>
			</v-flex>
			<v-flex lg3 md3 sm6 xs12 >
				<v-text-field
					data-test-id="profile-first-name"
					v-model="firstName"
					:label="$t('form.firstName')+'*'"
					v-validate="'required|max:30|noTags|noEscapes'"
					name="first name"
					:error-messages="errors.collect('first name')"
					:disabled="isRostered">
				</v-text-field>
			</v-flex>
			<v-flex lg3 md3 sm6 xs12>
				<v-text-field
					v-model="middleName"
					:label="$t('form.middleName')"
					v-validate="'max:30|noTags|noEscapes'"
					name="middle name"
					:error-messages="errors.collect('middle name')"
					:disabled="isRostered">
				</v-text-field>
			</v-flex>
			<v-flex lg3 md4 sm6 xs12>
				<v-text-field
					v-model="lastName"
					:label="$t('form.lastName')+'*'"
					v-validate="'required|max:30|noTags|noEscapes'"
					name="last name"
					:error-messages="errors.collect('last name')"
					:disabled="isRostered">
				</v-text-field>
			</v-flex>
		</v-layout>
		<v-layout wrap>
			<v-flex offset-lg1 lg5 sm6 xs12>
				<v-text-field
					v-model="username"
					:label="$t('form.userName')+'*'"
					v-validate="'required|min:4|max:50|noTags|noEscapesExceptAt|noSpaces'"
					name="username"
					:error-messages="errors.collect('username')">
				</v-text-field>
			</v-flex>
			<v-flex lg5 sm6 xs12>
				<v-text-field
					v-model="email"
					data-test-id="profile-email"
					:label="$t('form.email')+'*'"
					v-validate="'required|email|max:100|is_not:user@explorelearning.com'"
					name="email"
					:error-messages="errors.collect('email')"
					:disabled="isRostered">
				</v-text-field>
			</v-flex>
		</v-layout>
		<v-layout wrap>
			<v-flex offset-lg1 lg10 xs12>
				<v-text-field
					v-model="displayName"
					data-test-id="profile-display-name"
					:label="$t('form.displayName')"
					v-validate="'max:60'"
					name="display name"
					:error-messages="errors.collect('display name')"
					:disabled="isRostered">
				</v-text-field>
			</v-flex>
		</v-layout>
		<v-layout wrap>
			<v-flex offset-lg1 lg5 sm6 xs12>
				<small>{{$t('form.requiredFields')}}</small>
			</v-flex>
			<v-flex lg5 sm6 xs12 class="text-xs-right">
				<v-btn :disabled="!formChanged || formSubmitInProgress || !userNameAvalible" color="primary" large type="submit" class="mr-0">
					{{$t('navigation.update')}}
				</v-btn>
			</v-flex>
		</v-layout>
	</form>
</template>

<script>
	import APIs from 'mixins/APIs'
	import Constants from 'mixins/Constants'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import ErrorHandling from 'mixins/ErrorHandling'
	import Forwarding from 'mixins/Forwarding'
	import LoginDetector from 'mixins/LoginDetector'
	import Messaging from 'components/layout/Messaging'
	import SubscriptionHelper from 'mixins/SubscriptionHelper'
	import UserNameAvalibility from 'mixins/UserNameAvalibility'
	import AutoFill from 'mixins/AutoFill'
	import { mapActions, mapGetters } from 'vuex'
	export default {
		name: 'Profile',
		components: {
			Messaging
		},
		mixins: [APIs, Constants, EnvironmentDetector, ErrorHandling, Forwarding, LoginDetector, SubscriptionHelper, UserNameAvalibility, AutoFill],
		data(){
			return {
				email: "",
				firstName: "",
				formSubmitInProgress: false,
				lastName: "",
				middleName: "",
				titleID: 0,
				username: "",
				displayName: "",
				languageID: null,
				isValidEmail: null,
				isSubscribedToEmails: null,
				isDeleted: null,
				isRostered : null,
				productsOptOut : [],
			}
		},
		computed: {
			...mapGetters('platformData/profile', ['profile']),
			formChanged(){
				let hasChanged = false
				if(this.email && (this.$route.query.tab=='profile' || this.$route.query.tab==undefined)){
					if(this.username !== this.profile.username){
						this.checkAvalbility(false)
						hasChanged = hasChanged || this.userNameAvalible
					}
					hasChanged = hasChanged || this.email != this.profile.email
					hasChanged = hasChanged || this.titleID != this.profile.titleID
					hasChanged = hasChanged || this.firstName != this.profile.firstName
					hasChanged = hasChanged || this.middleName != this.profile.middleName
					hasChanged = hasChanged || this.lastName != this.profile.lastName
					hasChanged = hasChanged || this.displayName != this.profile.displayName
				}
				return hasChanged
			},
			schoolInfo(){
				let subs = this.$store.state.store.subscriptions
				if(subs){
					let currentProductID = this.getProductCode(this.getEnvironment())
					let currentSub = subs.filter(function(sub){
						return sub.productID==currentProductID
					})
					currentSub = subs
					if(currentSub.length){
						return currentSub[0].subscriptionName
					}
				}
				return null
			}
		},
		watch: {
			username(val){
				if(val.endsWith('-g2pmd')){
					this.errors.add({
						field: 'username',
						msg: 'Update your username',
						scope: 'migrationDuplicate'
					})
				} else {
					this.errors.clear('migrationDuplicate')
				}
			},
			userNameAvalible(val){
				if(this.username !== this.profile.username && !val){
					this.errors.add(this.usernameUnavailableError)
				} else {
					this.errors.clear('usernameCheck')
				}
			}
		},
		methods: {
			...mapActions('platformData/profile', ['fetchProfile']),
			updateProfile: async function(){
				var valid = await this.$validator.validateAll()
				if(valid){
					this.formSubmitInProgress = true
					var updatedProfile = {
						username : this.username.trim(),
						firstname : this.firstName,
						middlename : this.middleName,
						lastname : this.lastName,
						titleID : this.titleID,
						email : this.email.trim(),
						displayName : this.displayName,
						languageID : this.languageID,
						isValidEmail : this.isValidEmail,
						isSubscribedToEmails : this.isSubscribedToEmails,
						isDeleted : this.isDeleted,
						productID : this.getProductCode(),
						productsOptOutJson: JSON.stringify(this.productsOptOut)
					}
					if(updatedProfile.titleID == null){
						updatedProfile.titleID = 0
					}
					await this.$http.put(this.getProfileUpdateAPI(), updatedProfile, this.jtiHeader).then(response => {
						if(this.decodeXclaims(response) && this.decodeXclaims(response).jti){
							this.$store.commit('setJTI', this.decodeXclaims(response).jti)
						}
						this.$store.commit('setMessageError', null)
						this.$store.commit('setMessageWarning', 'HIDE')
						this.$store.commit('setMessageSuccess', this.$t('success.profileUpdate'))
						if(this.$route.query.forward){
							this.forward({name:'Home'})
						}
					}, error => {
						this.handleError(error.response)
					})
					await this.fetchProfile()
					this.formSubmitInProgress = false
				}
			}
		},
		created() {
			this.autoFill()
		}
  }
</script>

<style lang="less">
	.rosteringInfo{
		border-radius: 5px !important;
	}
</style>
