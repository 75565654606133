<template>
	<div>
		<form @submit.prevent="sendCode">
			<v-layout wrap>
				<Headline :headline="$t('regCode.header')"></Headline>
			</v-layout>	
			<v-layout wrap v-if="isInviteCode">
				<LoginInstructions :product="currentEnvironment" message="regCode.info"></LoginInstructions>
			</v-layout>
			<v-layout wrap>
				<Messaging outline></Messaging>
			</v-layout>
			<v-layout v-if="!verifiedCode" wrap>
				<v-flex xs12>
					<v-text-field
			      	v-model="regCodeRaw"
			      	:label="$t('form.regCode')"
			      	v-validate="'required'"
			      	name="registration code"
			      	:error-messages="errors.collect('registration code')">
			      </v-text-field>
				</v-flex>
				<v-flex xs12 class="text-xs-right">
					<v-btn color="primary" large raised type="submit">
						{{$t('navigation.submit')}}
					</v-btn>
				</v-flex>
			</v-layout>
			<v-layout v-else wrap>
				<v-flex xs12 v-if="loggedIn">
					<v-btn @click="linkAccount()" color="primary" large block class=mb-4>
						{{$t('regCode.linkTo',{username:profile.username})}}
					</v-btn>
					<v-btn @click="logout()" color="primary" flat large block>
						{{$t('regCode.linkDifferent')}}
					</v-btn>
				</v-flex>
				<v-flex xs12 v-else>
					<v-btn @click="loginAndReturn()" color="primary" large block class=mb-4>
						{{$t('regCode.haveAccount')}}
					</v-btn>
					<v-btn :to="{name:'RegAccountInfo'}" color="primary" large block flat>
						{{$t('regCode.makeAccount')}}
					</v-btn>
				</v-flex>
			</v-layout>
		</form>
	</div>
</template>

<script>
	import APIs from 'mixins/APIs'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import ErrorHandling from 'mixins/ErrorHandling'
	import Forwarding from 'mixins/Forwarding'
	import Headline from 'components/layout/Headline'
	import LoginDetector from 'mixins/LoginDetector'
	import LoginInstructions from 'components/layout/LoginInstructions'
	import Messaging from 'components/layout/Messaging'
	import ProductInfo from 'mixins/ProductInfo'
	import RegCodeTypeValidator from 'mixins/RegCodeTypeValidator'
	import { mapGetters, mapActions } from 'vuex'
	export default {
		name: 'RegCode',
		metaInfo : {
            title : 'Registration Code'
        },
		components: {
			Headline,
			LoginInstructions,
			Messaging
		},
		mixins: [APIs, EnvironmentDetector, ErrorHandling, Forwarding, LoginDetector, ProductInfo, RegCodeTypeValidator],
		data () {
			return {
				badCodes : [],
				goodCodes : [],
				isInviteCode : false,
				numRegCodes: 0,
				productsRegistered: [],
				regCodeRaw : '',
				regCodesReal : [],
				regCodeType : 0,
				ssoSuccess : false,
				verifiedCode : false
			}
		},
		computed: {
			...mapGetters('platformData/profile', ['profile']),
		},
		watch: {
			goodCodes(val){
				if(val.length==this.numRegCodes){
					if(val.length>1){
						this.$store.commit('setMessageError', null)
					}
					this.isInviteCode = true
					this.verifiedCode = true
					this.$store.commit('setRegCode', this.goodCodes)
					this.$store.commit('setProductsRegisteredList', this.productsRegistered)
				}
			}
		},
		methods : {
			...mapActions('platformData/app', ['fetchModel', 'updateAuthClaims']),
			linkAccount: async function(){
				let self = this
				let regCodeURLs = this.goodCodes.map(function(x){return self.getLinkAccountToSubscriptionAPI(x)})
				let promises = regCodeURLs.map(function(x){return self.$http.post(x,{},self.jtiHeader)})
				Promise.all(promises).then(() => {
					this.$http.get(this.getRefreshAPI(),this.jtiHeader).then(() => {
						this.$router.push({name:'Home', params:{newSub:{type:'subscription',productIDs:this.productsRegistered}}})
					}).catch(() => {
						this.$router.push({name:'Home', params:{newSub:{type:'subscription',productIDs:this.productsRegistered}}})
					})
				}).catch(() => {
					this.$store.commit('setMessageError', this.$t('error.unableToLink'))
				})
			},
			async loginAndReturn(){
				this.$store.commit('setForwardingURL', {name:'RegCode', params:{regCode:this.regCodeRaw}})
				this.$router.push({name:'Login', params: {userType:'educator', app:'web', flowOverride:'reg'}})
			},
			async logout(routeName){
				this.$http.get(this.getLogoutAPI(),this.jtiHeader).then(() => {
					if(routeName){
						this.$router.push({name:routeName})
					}
				}, error => {
					console.log(error.response)
				})
			},
			sendCode: async function (){
				var valid = await this.$validator.validateAll()
				if(valid){
					if(!this.regCodesReal.length){
						this.regCodesReal = this.regCodeRaw.split("-")
						this.numRegCodes = this.regCodesReal.length
					}
					let regCode = this.regCodesReal.pop()
					await this.$http.post(this.getValidateRegCodeAPI(regCode),{},this.jtiHeader).then(async response => {
						let regCodeID = response.data.RegCode.regTypeID
						if(this.validateRegCodeType(regCodeID, this.$route.meta.flow)){
							this.$store.commit('setRegCode', regCode)
							this.$store.commit('setRegCodeType', regCodeID)
							if(response.data.RegCode.email){
								this.$store.commit('setEmail', response.data.RegCode.email)
							}
							let regTypesToIntercept = [
								3,  // Admin Invite
								4,  // Teacher Registration (CSA)
								5,  // Teacher Registration (RegCode System)
								8,  // Admin Registration (RegCode System)
								9,  // Admin Invite (CSA)
								14, // SSO Trial Registration
								15, // Teacher Invite
							]
							if(!regTypesToIntercept.includes(regCodeID)){
								// Not Invite or SSO
								this.$router.push({name:'RegAccountInfo'})
							} else if(regCodeID==3 || regCodeID==15){
								// Invited User
								let emailCheck = this.$route.query.emailCheck
								this.$router.push({name:'RegAccountInfo',query:{emailCheck}})
							} else if(regCodeID==14){
								// Check if SSO Free Trial
								await this.$http.post(this.getSsoTrialRegistrationAPI(regCode)).then(async response =>{
									let userInfo = this.decodeXclaims(response)
									this.$store.commit('setJTI', userInfo.jti)
									this.$store.commit('setMessageError', null)
									this.$store.commit('setMessageSuccess', this.$t('regCode.trialRegistered'))
									await this.$http.get(this.getRefreshAPI(),this.jtiHeader).then(async response=>{
										userInfo = await this.decodeXclaims(response)
										await this.updateAuthClaims({ claims: {...this.claims, jti: userInfo.jti} })
										this.$store.commit('setJTI', userInfo.jti)
										await this.fetchModel()
										this.$router.push({name:'Home', params:{ssoTrial:true}})
									},error=>{
										console.log(error)
									})
								}, error => {
									console.log(error)
									this.$store.commit('setMessageError', this.$t('error.unableToLink'))
								})
							} else {
								// Old RegCode System
								this.goodCodes.push(regCode)
								this.productsRegistered.push(response.data.RegCode.productID)
								if(this.regCodesReal.length){
									this.sendCode()
								}
							}
							this.regCodeType = regCodeID
						} else {
							this.$store.commit('setMessageError', this.$t('error.invalidRegCode'))
						}
					}, error => {
						// Error Messages
						let response = error.response
						if(response.status==404){
							this.$store.commit('setMessageError', this.$t('error.404'))
						} else {
							this.badCodes.push(regCode)
							try {
								this.handleError(error.response)
							} catch {
								this.$store.commit('setMessageError', this.$t('error.invalidRegCode'))
							}
						}
					})
				}
			}
		},
		created(){
			if(this.$route.params.regCode){
				this.regCodeRaw = this.$route.params.regCode
				this.sendCode()
			}
		}
	}
</script>
