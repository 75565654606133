<template>
	<v-layout align-center>
		<v-flex xs12 :md9="hasSidebarImage" :lg10="hasSidebarImage" >
			<form id="schoolInfoForm" @submit.prevent="submit">
				<v-layout wrap>
					<component :is="headlineType" :logo="currentEnvironment" :headline="schoolOrDistrictHeadline" ></component>
					<GrantStepper v-if="flow=='grant'" :currentStep="2"></GrantStepper>
					<TrialStepper :currentStep="2" />
					<Messaging outline></Messaging>
				</v-layout>
				<v-layout wrap v-if="showRadioDistrict" class="mb-2">
					<v-flex xs12 class="mb-3">
						<span class="body-1 grey--text text--darken-2 uppercase">
							{{$t('schoolInfo.role')}}
						</span>
					</v-flex>
					<v-flex xs12 class="radio-district-select">
						<v-radio-group v-model="teacherOrDistrictRadio" row>
							<v-radio :label="$t('schoolInfo.roleTeacher')" value="teacher"></v-radio>
							<v-radio :label="$t('schoolInfo.roleDistrict')" value="district"></v-radio>
						</v-radio-group>
					</v-flex>
				</v-layout>
				<v-layout v-if="showTypeSelect" wrap>
					<v-flex xs12 class="body-1 grey--text text--darken-2">
						<span class="uppercase">{{$t('schoolInfo.accountType')}}</span>
					</v-flex>
					<v-flex xs12>
						<v-select
							outline
							:label="$t('schoolInfo.choose')"
							v-model="selectedAccountType"
							:items="accountTypes"
							item-text="name"
							items-value="value"
							v-validate="'required'"
							name="account type"
							:error-messages="errors.collect('account type')"
							ref="accountType"
						>
							<template slot="item" slot-scope="data">
								<v-list-tile-avatar>
									<v-icon x-large>{{data.item.icon}}</v-icon>
								</v-list-tile-avatar>
								<v-list-tile-content>
									<v-list-tile-title v-text="data.item.name"></v-list-tile-title>
									<v-list-tile-sub-title v-text="data.item.description"></v-list-tile-sub-title>
								</v-list-tile-content>
							</template>
						</v-select>
					</v-flex>
				</v-layout>
				<v-layout wrap v-if="isReflexHomeReg">
					<v-flex>
						<v-alert outline color="info" icon="info" value="true" class="mb-4">
							Reflex for home use is now called <strong>Time4MathFacts</strong><sup>&reg;</sup>. Please visit our sister company <strong>Time4Learning</strong><sup>&reg;</sup> to sign up.
						</v-alert>
					</v-flex>
				</v-layout>
				<v-layout wrap v-else-if="selectedAccountType=='home'">
					<v-flex xs12>
						<span class="body-1 grey--text text--darken-2 uppercase">{{$t('schoolInfo.location')}}</span>
					</v-flex>
					<v-flex xs12 :sm6="displayStates">
						<v-autocomplete
							v-model="countryCode"
							v-bind:items="allCountries"
							item-value="code"
							item-text="name"
							:label="$t('chooseLocation.country')"
							v-validate="'required'"
							name="country"
							:error-messages="errors.collect('country')"
						>
						</v-autocomplete>
					</v-flex>
					<v-flex xs12 sm6 v-if="displayStates">
						<v-autocomplete
							v-model="stateCode"
							v-bind:items="allStates"
							item-value="code"
							item-text="name"
							:label="$t('chooseLocation.state')"
							v-validate="'required'"
							name="state/province"
							:error-messages="errors.collect('state/province')"
						>
						</v-autocomplete>
					</v-flex>
				</v-layout>
				<v-layout wrap v-if="selectedAccountType=='teacher'||blurred" :class="{'blurred':blurred}" @click="selectAccountType()">
					<v-flex xs12>
						<span class="body-1 grey--text text--darken-2 uppercase">{{teacherOrDistrictRadio == 'district' ? $t('schoolInfo.districtDetails') : $t('schoolInfo.schoolDetails')}}</span>
					</v-flex>
					<v-flex v-if="viewType == 1" xs12 sm4 md3 lg2>
						<v-text-field
							v-model="zip"
							key="zip"
							:label="$t('form.zip')"
							persistent-hint
							browser-autocomplete="new-password"
							v-validate="'required|min:3|max:7'"
							name="ZIP/postal code"
							:error-messages="errors.collect('ZIP/postal code')"
							v-on:input="getInstitutions"
							:disabled='blurred'>
						</v-text-field>
					</v-flex>
					<v-flex v-if="viewType !== 1" xs12 :sm6="displayStateField">
						<v-autocomplete
							v-model="countryCode"
							key="countryCode"
							v-bind:items="allCountries"
							item-value="code"
							item-text="name"
							:label="$t('chooseLocation.country')"
							v-validate="'required'"
							name="country"
							:error-messages="errors.collect('country')"
							auto-select-first
						>
						</v-autocomplete>
					</v-flex>
					<v-flex v-if="displayStateField" xs12 sm6>
						<v-autocomplete
							v-model="stateCode"
							key="stateCode"
							v-bind:items="allStates"
							item-value="code"
							item-text="name"
							:label="$t('chooseLocation.state')"
							v-validate="'required'"
							name="state/province"
							:error-messages="errors.collect('state/province')">
						</v-autocomplete>
					</v-flex>
					<v-flex v-if="viewType !== 1 && viewType !== 5 && !nonUSCANCountryCode" xs12 :sm6="displayDistrictField">
						<v-autocomplete
							v-model="schoolType"
							key="schoolType"
							v-bind:items="schoolTypes"
							:label="$t('chooseSchool.institutionTypes')"
							v-validate="'required'"
							name="school type"
							:error-messages="errors.collect('school type')">
						</v-autocomplete>
					</v-flex>
					<v-flex v-if="displayDistrictField" xs12 :sm6="viewType !== 5">
						<v-autocomplete
							v-model="parentPid"
							key="parentPid"
							v-bind:items="allDistricts"
							item-value="pid"
							item-text="displayName"
							:label="$t('form.district')"
							v-validate="'required'"
							name="district"
							:error-messages="errors.collect('district')">
						</v-autocomplete>
					</v-flex>
					<v-flex v-if="showUnknownCityField" xs12>
						<v-text-field
							v-model="unknownCityName"
							key="unknownCityName"
							:label="$t('form.city')"
							v-validate="'required|noTags'"
							name="city name"
							:error-messages="errors.collect('city name')">
						</v-text-field>
					</v-flex>
					<v-flex v-if="showInstitutionDropdown" xs12 :sm8="viewType == 1" :md9="viewType == 1" :lg10="viewType == 1">
						<v-autocomplete
							v-model="pid"
							key="pid"
							v-bind:items="nonUSCANCountryCode ? nonUSCANSchools : allInstitutions"
							item-value="pid"
							item-text="name"
							:label="$t('form.institution')"
							:hint="nonUSCANCountryCode ? nonUSCANSchools.length+' institutions match your criteria' : allInstitutions.length +' institutions match your criteria'"
							:persistent-hint="Boolean(allInstitutions.length || nonUSCANSchools.length)"
							v-validate="'required'"
							name="institution"
							:error-messages="errors.collect('institution')"
							:disabled='blurred'>
						</v-autocomplete>
					</v-flex>
					<v-flex v-if="viewType == 4" xs12>
						<v-text-field
							v-model="unknownName"
							key="unknownName"
							:label="$t('form.institutionName')"
							v-validate="'required|noTags'"
							name="institution name"
							:error-messages="errors.collect('institution name')">
						</v-text-field>
					</v-flex>
					<v-flex xs12 class="text-xs-center">
						<v-btn v-if="viewType !== 1 && !internationalGrant && teacherOrDistrictRadio == 'teacher'" flat small color="primary" @click="changeViewType(1)" :disabled='blurred'>
							{{$t('chooseSchool.switchToZip')}}
						</v-btn>
						<v-btn v-if="viewType !== 2 && viewType !== 5 && !internationalGrant" flat small color="primary" @click="changeViewType(2)" :disabled='blurred'>
							{{$t('chooseSchool.switchToLocation')}}
						</v-btn>
						<v-btn v-if="showNotListed" flat small color="primary" @click="notListed()">
							{{$t('chooseSchool.schoolNotListed')}}
						</v-btn>
						<v-btn v-if="internationalGrant && viewType == 4" flat small color="primary" @click="changeViewType(2)">
							{{$t('chooseSchool.international')}}
						</v-btn>
					</v-flex>
				</v-layout>
				<v-layout v-if="(selectedAccountType||blurred)&&!isGrantClosed&&!isReflexHomeReg" :class="{'blurred':blurred}" wrap class="mt-3">
					<v-flex xs12>
						<span class="body-1 grey--text text--darken-2 uppercase">{{$t('additionalInfo.aboutYou')}}</span>
					</v-flex>
					<v-flex xs12 sm6>
						<v-select
							v-model="titles"
							:items='titlesForAccoutType'
							item-text="label"
							items-value="value"
							:label="labelTitles"
							:disabled='blurred'
							v-validate="roleAndGradeValidation"
							name="role"
							:error-messages="errors.collect('role')"
						>
						</v-select>
					</v-flex>
					<v-flex xs12 sm6>
						<v-select
							v-model="gradeLevels"
							:items='allGradeLevels'
							item-text="label"
							items-value="value"
							:label="labelGradeLevels"
							:disabled='blurred'
							:multiple="teacherOrDistrictRadio != 'district'"
							v-validate="roleAndGradeValidation"
							name="grade"
							:error-messages="errors.collect('grade')"
						>
						</v-select>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs6 v-if="backBtnDestination">
						<v-btn :to="{name:backBtnDestination}" large color="grey lighten-2">
							{{$t('navigation.back')}}
						</v-btn>
					</v-flex>
					<v-flex :xs6="backBtnDestination" :xs12="!backBtnDestination" class="text-xs-right">
						<v-btn v-if="!isReflexHomeReg" color="primary" large type="submit" :disabled="blurred" id="schoolInfoFormSubmit">
							{{submitBtnText}}
						</v-btn>
						<v-btn v-else color="primary" large @click='goToTime4Learning'>
							Visit&nbsp;&nbsp;<img src="../../assets/shared/img/t4mf-logo.png" alt="Time4MathFacts Logo" width="150" style="margin-top:-9px;">
						</v-btn>
					</v-flex>
				</v-layout>
			</form>
		</v-flex>
		<v-flex v-if="hasSidebarImage" md3 lg2 fill-height class="hidden-sm-and-down">
			<img v-if="currentEnvironment=='reflex'" id="cat" src="../../assets/reflex/img/cat.png" alt="Egyptian Cat">
			<img v-else-if="currentEnvironment=='frax'" id="survey-cat" src="../../assets/frax/img/surveycat.png" alt="Survey Cat">
		</v-flex>
	</v-layout>
</template>

<style>
	.blurred {
		filter:blur(2px);
	}
	#survey-cat {
		margin-left: 35px;
		height: 400px;
		-webkit-transform: scaleX(-1);
  		transform: scaleX(-1);
	}
	.radio-district-select {
		margin-top: -30px;
	}
</style>

<script>
	import APIs from 'mixins/APIs'
	import AutoFill from 'mixins/AutoFill'
	import Constants from 'mixins/Constants'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import ErrorHandling from 'mixins/ErrorHandling'
	import Forwarding from 'mixins/Forwarding'
	import GrantHeadline from 'components/layout/GrantHeadline'
	import GrantStepper from 'components/grant/GrantStepper'
	import Headline from 'components/layout/Headline'
	import Location from 'mixins/Location'
	import LoginDetector from 'mixins/LoginDetector'
	import Messaging from 'components/layout/Messaging'
	import Tracking from 'mixins/Tracking'
	import TrialStepper from 'components/registration/TrialStepper'
	import { mapActions } from 'vuex'
  	export default {
		name: 'SchoolInfo',
		metaInfo : {
            title : 'School Information'
        },
		components: {
			GrantHeadline,
			GrantStepper,
			Headline,
			Messaging,
			TrialStepper
		},
		mixins: [APIs, AutoFill, Constants, EnvironmentDetector, ErrorHandling, Forwarding, Location, LoginDetector, Tracking],
		data () {
			return {
				// Account Type
				selectedAccountType : '',
				accountTypes : [
					{name: this.$t('schoolInfo.teacherName'), icon:'school', description: this.$t('schoolInfo.teacherDescription'), value:'teacher'},
					{name: this.$t('schoolInfo.homeName'), icon:'home', description: this.$t('schoolInfo.homeDescription'), value:'home'}
				],
				showTypeSelect : true,
				teacherOrDistrictRadio: 'teacher',
				// Location
				allCountries: [],
				allStates: [],
				countryCode: '',
				displayStates: false,
				stateCode: '',
				// School
				allDistricts: [],
				allInstitutions: [],
				ignoreFirstCountryChange: false,
				ignoreFirstStateChange: false,
				noInstitutions: false,
				nonUSCANSchools: [],
				parentPid : null,
				pid: null,
				schoolType: '',
				unknownCityName: '',		
				unknownName: '',
				zip: null,
				// Roles
				gradeLevels: [],
				titles: '',
				//view type: 1=zip lookup, 2=location lookup, 3=outsideUS/CAN (NOT IN USE), 4=School Not Listed, 5=District Employee
				viewType: 1	
			}
		},
		computed : {
			backBtnDestination(){
				if(this.flow=="quote"){
					return 'Quote'
				} else if(this.flow=="grant"){
					return 'Grant'
				} else if(this.flow=="pre-approved"){
					return 'PreApprovedGrant'
				} else if(this.flow=="conference"){
					return 'Conference'
				} else {
					return null
				}				
			},
			blurred(){
				if(this.selectedAccountType==''){
					return true
				} else {
					return false
				}
			},
			submitBtnText(){
				if(this.flow=='reg' || this.flow=='pre-approved' || this.isGrantClosed || this.flow=="quote" || this.flow=="conference"){
					return this.$t('form.submit')
				} else if (this.flow=='demo'){
					return this.$t('demo.submit')
				} else {
					return this.$t('navigation.next')
				}
			},
			headlineType(){
				if(this.flow=='grant' || this.flow=='pre-approved'){
					return 'GrantHeadline'
				} else {
					return 'Headline'
				}
			},
			isGrantClosed(){
				if(this.flow=='grant' && !this.$store.state.grant.grantOpen){
					return true
				} else {
					return false
				}
			},
			hasSidebarImage(){
				const hasImage = ['reflex','frax']
				return hasImage.includes(this.currentEnvironment)
			},
			// School
			internationalGrant(){
				return this.$store.state.grant.international
			},
			hasStateIfNeeded(){
				if(this.displayStates){
					if(this.stateCode){
						return true
					} else {
						return false
					}
				} else {
					return true
				}
			},
			displayStateField(){
				return this.viewType !== 1 && this.viewType !== 3 && this.displayStates || this.viewType == 5
			},
			displayDistrictField(){
				return !this.nonUSCANCountryCode && this.viewType !== 1 && this.viewType !== 3 && this.schoolType=='public' && !this.noInstitutions || this.viewType == 5
			},
			// Roles
			roleAndGradeValidation(){
				if(this.flow=='conference'){
					return null
				} else {
					return 'required'
				}
			},
			allGradeLevels(){
				if (this.teacherOrDistrictRadio == 'district') {
					return [
						{label: this.$t('additionalInfo.gradeLevel.k2'), value: 'K;1;2'},
						{label: this.$t('additionalInfo.gradeLevel.k5'), value: 'K;1;2;3;4;5'},
						{label: this.$t('additionalInfo.gradeLevel.k8'), value: 'K;1;2;3;4;5;6;7;8'},
						{label: this.$t('additionalInfo.gradeLevel.k12'), value: 'K;1;2;3;4;5;6;7;8;9;10;11;12'},
						{label: this.$t('additionalInfo.gradeLevel.sixEight'), value: '6;7;8'},
						{label: this.$t('additionalInfo.gradeLevel.sixTweleve'), value: '6;7;8;9;10;11;12'},
						{label: this.$t('additionalInfo.gradeLevel.nineTweleve'), value: '9;10;11;12'},
					]
				} else {
					return [
						{label: this.$t('additionalInfo.gradeLevel.prek'), value: 'PK'},
						{label: this.$t('additionalInfo.gradeLevel.k'), value: 'K'},
						{label: this.$t('additionalInfo.gradeLevel.grade1'), value: '1'},
						{label: this.$t('additionalInfo.gradeLevel.grade2'), value: '2'},
						{label: this.$t('additionalInfo.gradeLevel.grade3'), value: '3'},
						{label: this.$t('additionalInfo.gradeLevel.grade4'), value: '4'},
						{label: this.$t('additionalInfo.gradeLevel.grade5'), value: '5'},
						{label: this.$t('additionalInfo.gradeLevel.grade6'), value: '6'},
						{label: this.$t('additionalInfo.gradeLevel.grade7'), value: '7'},
						{label: this.$t('additionalInfo.gradeLevel.grade8'), value: '8'},
						{label: this.$t('additionalInfo.gradeLevel.grade9'), value: '9'},
						{label: this.$t('additionalInfo.gradeLevel.grade10'), value: '10'},
						{label: this.$t('additionalInfo.gradeLevel.grade11'), value: '11'},
						{label: this.$t('additionalInfo.gradeLevel.grade12'), value: '12'},
						{label: this.$t('additionalInfo.gradeLevel.college'), value: '13'},
					]
				}
			},
			labelGradeLevels(){
				if(this.flow=='pre-approved'){
					return "Grade Levels"
				} else {
					return this.teacherOrDistrictRadio == 'district' ? this.$t('additionalInfo.gradesYouWorkWith') : this.$t('additionalInfo.gradeLevels')
				}
			},
			labelTitles(){
				if(this.flow=='pre-approved'){
					return "Titles"
				} else {
					return this.teacherOrDistrictRadio == 'district' ? this.$t('additionalInfo.yourRole') : this.$t('additionalInfo.titles')
				}
			},
			schoolOrDistrictHeadline() {
				return this.teacherOrDistrictRadio == 'district' ? this.$t('schoolInfo.districtHeader') : this.$t('schoolInfo.header')
			},
			showRadioDistrict() {
				return this.flow == 'demo' || this.flow == 'quote' || this.flow == 'conference'
			},
			titlesForAccoutType(){
				if (this.selectedAccountType === 'home') {
					return [
						{label: this.$t('additionalInfo.role.parent'), value:'Parent'},
						{label: this.$t('additionalInfo.role.tutor'), value:'Tutor'},
						{label: this.$t('additionalInfo.role.other'), value:'Other'}
					]
				} else if (this.teacherOrDistrictRadio == 'district') {
					return [
						{label: this.$t('additionalInfo.role.super'), value:'Superintendent'},
						{label: this.$t('additionalInfo.role.asstSuper'), value:'Assistant Superintendent'},
						{label: this.$t('additionalInfo.role.curriculumDirector'), value:'Curriculum Director'},
						{label: this.$t('additionalInfo.role.fedProgramDirector'), value:'Federal Programs Director'},
						{label: this.$t('additionalInfo.role.finDirector'), value:'Finance/Purchasing Director'},
						{label: this.$t('additionalInfo.role.mathDirector'), value:'Math Director'},
						{label: this.$t('additionalInfo.role.scienceDirector'), value:'Science Director'},
						{label: this.$t('additionalInfo.role.respIntDirector'), value:'Response to Intervention Director'},
						{label: this.$t('additionalInfo.role.spEdDirector'), value:'Special Education Director'},
						{label: this.$t('additionalInfo.role.stemCoord'), value:'STEM Coordinator'},
						{label: this.$t('additionalInfo.role.techCoord'), value:'Technology Coordinator'},
						{label: this.$t('additionalInfo.role.title1Coord'), value:'Title I Coordinator'},
						{label: this.$t('additionalInfo.role.otherAdmin'), value:'Other Administrator'},
					]
				} else {
					return [
						{label: this.$t('additionalInfo.role.teacher'), value:'Teacher'},
						{label: this.$t('additionalInfo.role.resourceTeacher'), value:'Resource Teacher'},
						{label: this.$t('additionalInfo.role.intervention'), value:'Response To Intervention Specialist'},
						{label: this.$t('additionalInfo.role.mathChair'), value:'Math Dept Chairperson'},
						{label: this.$t('additionalInfo.role.sciChair'), value:'Science Dept Chairperson'},
						{label: this.$t('additionalInfo.role.mathCoach'), value:'Math Coach'},
						{label: this.$t('additionalInfo.role.sciCoach'), value:'Science Coach'},
						{label: this.$t('additionalInfo.role.principal'), value:'Principal'},
						{label: this.$t('additionalInfo.role.curriculum'), value:'Curriculum Coordinator'},
						{label: this.$t('additionalInfo.role.technology'), value:'Technology Coordinator'},
						{label: this.$t('additionalInfo.role.studentTeacher'), value:'Student Teacher'},
						{label: this.$t('additionalInfo.role.other'), value:'Other'}
					]
				}
			},
			// Time4MathFacts Seperation
			isReflexHomeReg(){
				return this.currentEnvironment=='reflex' && this.selectedAccountType=='home' && this.flow=='reg'
			},
			schoolTypes() {
				return this.viewType == 5 ? [{text:'Public',value:'public'}] : [{text:'Public',value:'public'},{text:'Private',value:'private'}]
			},
			// showDistrictEmployeeButton() {
			// 	if (this.flow == 'reg' || this.flow == 'grant' || this.flow == 'pre-approved') {
			// 		return false
			// 	} else {
			// 		return this.viewType < 3 || this.viewType == 4 
			// 	}
			// },
			showInstitutionDropdown() {
				if (this.viewType > 3) {					
					return false
				} else if (this.viewType == 3) {
					return true
				} else if (this.noInstitutions) {
					return this.viewType == 1
				} else {
					return true
				}
			},
			showNotListed() {
				return this.viewType == 2 || this.viewType == 3
			},
			showUnknownCityField() {
				return this.viewType == 4 && this.flow === 'reg' && this.nonUSCANCountryCode
			},
			nonUSCANCountryCode() {
				return this.countryCode !== 'USA' && this.countryCode !== 'CAN' && this.countryCode !== ''
			}
		},
		watch : {
			// Account Type
			selectedAccountType(type){
				if(type=='home'){
					this.getCountries('allCountries')
				}
			},
			teacherOrDistrictRadio : function(value){
				if (value == 'district') {
					this.changeViewType(5)
				} else if (value == 'teacher') {
					this.changeViewType(1)
				}
			},
			viewType(type) {
				if(this.flow == 'quote' || this.flow == 'demo' || this.flow == 'conference') {
					type < 5 ? this.teacherOrDistrictRadio = 'teacher' : this.teacherOrDistrictRadio = 'district'
				}
			},
			// Location
			allStates(){
				if(this.allStates && this.allStates.length){
					this.displayStates = true
				} else {
					this.displayStates = false
				}
			},
			// School
			countryCode: function(value){
				if(this.selectedAccountType=='home'){
					this.getStates('allStates',this.countryCode)
				} else {
					if(value && !this.autoFillInProgress && this.viewType !== 1){
						if(!this.ignoreFirstCountryChange){
							this.noInstitutions = false
							this.stateCode = null
							this.schoolType = this.viewType !== 5 ? null : 'public'
							this.parentPid = null
							this.pid = null
						}
						this.allStates = []
						this.allDistricts = []
						this.allInstitutions = []
						if (this.nonUSCANCountryCode) {
							this.getInstitutionsByCountryCode(value);
						} else {
							this.getStates('allStates',this.countryCode)
							if(this.schoolType){
								this.institutionSearch()
							}
						}
						this.ignoreFirstCountryChange = false															
					}
				}
			},
			parentPid: function(value){
				if(value && !this.autoFillInProgress && this.viewType !== 1){
						this.allInstitutions=[]
					this.getInstitutionsByParent()
				}
			},
			pid(value){
				if(value && !this.autoFillInProgress && this.viewType !== 1){
					this.unknownName = ''
				}
			},
			schoolType: function(value){
				if(value && !this.autoFillInProgress && this.viewType !== 1){
					this.allDistricts=[]
					this.allInstitutions=[]
					this.institutionSearch()
				}
				if(value=='private'){
					this.parentPid = null
				}
			},
			stateCode: function(value){
				if(value && !this.autoFillInProgress && this.viewType !== 1){
					this.allDistricts=[]
					this.allInstitutions=[]
					if(this.schoolType){
						this.institutionSearch()
					}
					this.ignoreFirstStateChange = false
				}
			},
			// Roles & Grade Levels
			gradeLevels: function(value){
				if(!Array.isArray(value) && this.teacherOrDistrictRadio != 'district'){
					this.gradeLevels = value.split(',')
				}
			}
		},
		methods: {
			...mapActions('platformData/app', ['fetchModel', 'updateAuthClaims']),
			...mapActions('platformData/profile', ['fetchProfile']),
			cleanAllDistricts(dataToClean){
				//add a 'displayName' property to each district and appends the city name to any district that has a duplicate name
				if(dataToClean) {
					dataToClean.forEach(function (district, x){
						for (let i=0; i<dataToClean.length; i++) {
							if (district.name.toUpperCase() === dataToClean[i].name.toUpperCase() && i !== x) {
								district.hasNameMatch = true
							}					
						}
					})		
                	return dataToClean.map(district => ({
						...district,
						displayName: district.hasNameMatch ? `${district.name.toUpperCase()} (${district.city.toUpperCase()})` : district.name.toUpperCase()
					}))
				} else {
					return []
				}
			},
			selectAccountType(){
				if(this.blurred){
					this.$refs.accountType.onClick()
				}
			},
			// School
			getInstitutions(){
				if(this.zip && this.zip.length >= 3){
					if(this.zip.includes('#') || this.zip.includes('%') || this.zip.includes('/')){
						this.$store.commit('setMessageError', this.$t('error.illegalChars'))
					} else {
						this.$store.commit('setMessageError', '')
						this.$http.get(this.getInstitutionsAPI(this.zip), this.jtiHeader).then(response => {
								this.allInstitutions = response.data.ArrayList.sort(this.schoolSort)
								if(response.data.ArrayList.length){
									this.noInstitutions = false
								} else {
									this.noInstitutions = true
								}
						}).catch(() => {
							this.$store.commit('setMessageError', this.$t('error.404'))
						})
					}
				} else {
					this.allInstitutions = []
				}
			},
			getInstitutionsByParent(){
				if(this.parentPid){
					this.$http.get(this.getInstitutionsByParentAPI(this.parentPid), this.jtiHeader).then(response => {
							this.allInstitutions = response.data.ArrayList.sort(this.schoolSort)
					}).catch(() => {
						this.$store.commit('setMessageError', this.$t('error.404'))
					})
				}
			},
			institutionSearch(){
				if(this.countryCode && !this.ignoreFirstCountryChange && !this.ignoreFirstStateChange && this.hasStateIfNeeded){
					let type = this.schoolType
					if(type=='public'){
						type='district'
					}
					this.$http.get(this.getInstitutionSearchAPI(this.countryCode, this.stateCode, type), this.jtiHeader).then(response => {
						if(response.data.ArrayList.length){
							if(type=='district'){							
								this.allDistricts = this.cleanAllDistricts(response.data.ArrayList);							
							} else {
								this.allInstitutions = response.data.ArrayList.sort(this.schoolSort)
							}
							this.noInstitutions = false
						} else {
							this.noInstitutions = true
						}
						this.$validator.reset();
					}, error => {
						if(error.response.status!==400){
							this.$store.commit('setMessageError', this.$t('error.404'))
						}
					})
				}
			},
			async notListed(name){
				this.changeViewType(4)
				this.unknownName=name || ''
				this.noInstitutions = true
			},
			async changeViewType(num) {
				this.viewType = num
				this.allInstitutions=[]
				this.noInstitutions = false
				this.unknownName = ''
				this.pid = null
				//view type: 1=zip lookup, 2=location lookup, 3=outsideUS/CAN (NOT IN USE), 4=School Not Listed, 5=District Employee
				if(num == 1){
					this.getInstitutions()
					this.countryCode = ''
					this.stateCode = null
					this.schoolType = ''
					this.parentPid = ''
				} else if (num == 2 || num == 4) {
					if(this.internationalGrant){
						await this.getCountries('allCountries', true)
					} else {
						await this.getCountries('allCountries')
					}
				} else if (num == 3) {
					this.nonUSCANSchools = []
					this.schoolType = ''
					await this.getNonUsCanCountries('allCountries');
				} else if (num == 5) {
					this.schoolType = 'public'
					this.allCountries = [
						{code:'USA',name:'United States'},
						{code:'CAN',name:'Canada'}
					]
				}				
			},
			// Submit
			submit: async function(){
				var valid = await this.$validator.validateAll()
				if(valid){
					// Store Data
					if(this.selectedAccountType=='home'){
						this.$store.commit('setSchoolType', 'home')
						this.$store.commit('setCountry', this.countryCode)
						if(this.stateCode){
							this.$store.commit('setState', this.stateCode)
						}
					} else {
						// School Account
						if(!this.unknownName){
							if (this.viewType !== 5) {
								//setting info for someone who is not a district employee and their school was listed
								let selectedPid = this.pid
								this.$store.commit('setPID', selectedPid)
								if(this.parentPid){
									this.$store.commit('setParentPID', this.parentPid)
								}
								var selectedInstitute
								this.allInstitutions.forEach(function(institution){
									if(selectedPid == institution.pid){
										selectedInstitute = institution
									}
								})
								if(!selectedInstitute) {
									this.nonUSCANSchools.forEach(function(institution){
										if(selectedPid == institution.pid){
											selectedInstitute = institution
										}
									})
								}					
								this.$store.commit('setState',selectedInstitute.stateCode)
								this.$store.commit('setCountry',selectedInstitute.countryCode)
								if(selectedInstitute.institutionTypeID == 5){
									this.$store.commit('setSchoolType', 'private')
								} else {
									this.$store.commit('setSchoolType', 'public')
								}
							} else {
								//setting info for someone who is a district employee
								let districtPid = this.parentPid
								let superDistrictPid = null
								let district = this.allDistricts.find(x => x.pid == districtPid)
								if (district.parentPID) {
									superDistrictPid = district.parentPID
								}
								this.$store.commit('setParentPID', superDistrictPid)											
								this.$store.commit('setPID', districtPid)
								this.$store.commit('setState', this.stateCode)
								this.$store.commit('setCountry', this.countryCode)
								this.$store.commit('setSchoolType', 'public')								
							}
							this.$store.commit('setUnknownName', null)
						} else {
							//setting info for someone whose school was not listed
							this.$store.commit('setUnknownName', this.unknownName)
							this.$store.commit('setCountry', this.countryCode)
							this.$store.commit('setState', this.stateCode)
							if(this.schoolType){
								this.$store.commit('setSchoolType', this.schoolType)
							} else {
								this.$store.commit('setSchoolType', 'private')
							}
							this.$store.commit('setPID', null)
							this.$store.commit('setParentPID', null)
						}
						if(this.zip){
							this.$store.commit('setZip', this.zip)
						}
					}
					this.$store.commit('setGradeLevels', this.gradeLevels)
	    			this.$store.commit('setTitles', this.titles)
					// Communicate to Back End
					if(this.flow=='reg'){
						let trialInfo = {
							firstName : this.$store.state.formData.firstName,
							lastName : this.$store.state.formData.lastName,
							email : this.$store.state.formData.email,
							phone : this.$store.state.formData.phone,
							phoneExt : this.$store.state.formData.phoneExt,
							promoCode : this.$store.state.formData.trialPromo,
							schoolInfo: this.$store.state.schoolInfo,
							formInfo : this.addTrackingInfo()
						}
						trialInfo.schoolInfo.gradeLevels = this.gradeLevels.join(';')
	      				trialInfo.schoolInfo.titles = this.titles
						if(this.showUnknownCityField) {
							trialInfo.schoolInfo.city = this.unknownCityName
						}
						this.$http.post(this.getTrialCreationAPI(null,this.$store.state.formData.regCode), trialInfo,this.jtiHeader).then(async response => {
							const userInfo = this.decodeXclaims(response)                        	
							await this.updateAuthClaims({ claims: {...this.claims, jti: userInfo.jti} })
							this.$store.commit('setJTI', userInfo.jti)
							await this.fetchProfile()
							await this.fetchModel()
							let accountType = 'trial'
							if(this.$store.state.formData.regCodeType == 12){
								accountType = 'free access'
							}
							if(typeof window.dataLayer != 'undefined'){
								var dataObject = {
									event : 'trial_2b',
									product : this.currentEnvironment,
									category : 'sign_up'
								}
								window.dataLayer.push(dataObject);
							}
							this.$router.push({name:'Home', params:{newSub:{type:accountType,productIDs:[this.getProductCode()]}}})
						}).catch(() => {
							this.$store.commit('setMessageError', this.$t('error.404'))
						})
					} else if(this.flow=='quote' || this.flow=='demo' || this.flow=='conference'){
						let quoteInfo = {
							firstName : this.$store.state.servicesForm.firstName || this.$store.state.formData.firstName,
							lastName : this.$store.state.servicesForm.lastName || this.$store.state.formData.lastName,
							email : this.$store.state.servicesForm.email || this.$store.state.formData.email,
							schoolInfo : this.$store.state.schoolInfo,
							formInfo : this.addTrackingInfo()
						}
						if(this.flow!='conference'){
							quoteInfo.contactTime = this.$store.state.servicesForm.contactTime || this.$store.state.formData.contactTime,
							quoteInfo.title = this.getTitle(this.$store.state.servicesForm.titleID || this.$store.state.formData.titleID),
							quoteInfo.phone = this.$store.state.servicesForm.phone || this.$store.state.formData.phone,
							quoteInfo.phoneExt = this.$store.state.servicesForm.phoneExt || this.$store.state.formData.phoneExt,
							quoteInfo.comments = this.$store.state.servicesForm.comments
						}
						if(Array.isArray(quoteInfo.schoolInfo.gradeLevels)){
							quoteInfo.schoolInfo.gradeLevels = quoteInfo.schoolInfo.gradeLevels.join(';')
						}
						if(this.flow=='demo'){
							quoteInfo.isDemoRequest = true
						} else if(this.flow=='conference'){
							quoteInfo.isConferenceLead = true
						}
						let product = this.$store.state.servicesForm.product
						this.$http.post(this.getQuoteAPI(product), quoteInfo).then(() => {
							this.$store.commit('setAllowConfirm', true)
							if(this.flow=='quote'){
								this.$router.push({name:'QuoteSuccess'})
							} else if(this.flow=='demo') {
								this.$router.push({name:'DemoSuccess'})
							} else {
								this.$router.push({name:'Conference', params: {success:true}})
							}
						}).catch(() => {
							this.$store.commit('setMessageError', this.$t('error.contactSupport'))
						})
					} else if(this.flow=='grant'){
						if(this.$store.state.grant.grantOpen){
							this.$router.push({name:'GrantQuestion', params:{questionID:1}})
						} else {
							let grantInfo = {
								firstName : this.$store.state.servicesForm.firstName || this.$store.state.formData.firstName,
								lastName : this.$store.state.servicesForm.lastName || this.$store.state.formData.lastName,
								email : this.$store.state.servicesForm.email || this.$store.state.formData.email,
								phone : this.$store.state.servicesForm.phone || this.$store.state.formData.phone,
								phoneExt : this.$store.state.servicesForm.phoneExt || this.$store.state.formData.phoneExt,
								formInfo : this.addTrackingInfo(),
								schoolInfo : {
									schoolType : this.$store.state.schoolInfo.schoolType,
									stateCode : this.$store.state.schoolInfo.stateCode,
									countryCode : this.$store.state.schoolInfo.countryCode,
									unknownName : this.$store.state.schoolInfo.unknownName,
									pid : this.$store.state.schoolInfo.pid
								}
							}
							this.$http.post(this.getGrantApplicationClosedAPI(),grantInfo).then(() => {
								this.$store.commit('setAllowConfirm', true)
								this.$router.push({name:'GrantCompleteClosed'})
							}, error => {
								this.handleError(error.response)
							})
						}
					} else if(this.flow=='pre-approved'){
						let grantInfo = {
							firstName : this.$store.state.servicesForm.firstName || this.$store.state.formData.firstName,
							lastName : this.$store.state.servicesForm.lastName || this.$store.state.formData.lastName,
							email : this.$store.state.servicesForm.email || this.$store.state.formData.email,
							phone : this.$store.state.servicesForm.phone || this.$store.state.formData.phone,
							phoneExt : this.$store.state.servicesForm.phoneExt || this.$store.state.formData.phoneExt,
							mobilePhone : this.$store.state.servicesForm.mobile || this.$store.state.formData.mobile,
							salutation : this.getTitle(this.$store.state.servicesForm.titleID || this.$store.state.formData.titleID),
							contactTime : this.$store.state.servicesForm.contactTime || this.$store.state.formData.contactTime,
							principalName : this.$store.state.grant.principalName,
							principalEmail : this.$store.state.grant.principalEmail,
							formInfo : this.addTrackingInfo(),
							schoolInfo: this.$store.state.schoolInfo,
						}
    					grantInfo.schoolInfo.gradeLevels = grantInfo.schoolInfo.gradeLevels.join(';')
						this.$http.post(this.getGrantApplicationPreApprovedAPI(), grantInfo, this.jtiHeader).then(() => {
							this.$store.commit('setAllowConfirm', true)
								this.$router.push({name:'PreApprovedCreated'})
						}, error => {
							this.handleError(error.response)
						})
					}
				}
			},
			// Time4MathFacts Seperation
			goToTime4Learning(){
				window.location.href='https://www.time4mathfacts.com/reflex/'
			}
		},
		async created(){
			if(!this.$store.state.productConfig.enableHomeUsers){
				this.selectedAccountType = "teacher"
				this.showTypeSelect = false
			} else if(this.$store.state.navigation.accountType){
				this.selectedAccountType = this.$store.state.navigation.accountType
				this.showTypeSelect = false
			}
			if(this.flow=='reg'){
				// Registration
				if(!this.loggedIn){
					this.$router.replace({name:'RegAccountInfo', params: {environment: this.getEnvironment()}})
				}
			} else if(this.flow=='quote' || this.flow=='demo' || this.flow=='grant' || this.flow=='pre-approved') {
				// Quote & Grant
				if(!this.$store.state.formData.phone){
					if(this.flow=='quote'){
						this.$router.replace({name:'Quote'})
					} else if(this.flow=='demo'){
						this.$router.replace({name:'Demo'})
					} else if(this.flow=='pre-approved'){
						this.$router.replace({name:'PreApprovedGrant'})
					} else {
						this.$router.replace({name:'Grant'})
					}
				}
			} else if(this.flow=='conference'){
				if(!this.$store.state.formData.email){
					this.$router.replace({name:'Conference'})
				}
			}
			//set district or school employee tab
			if(this.internationalGrant){
				this.noInstitutions = true
				this.changeViewType(2)
			}
			if(this.flow !== 'pre-approved' && this.flow !== 'conference'){
				this.autoFill()
			}
			if(this.parentPid || this.unknownName) {
				// this.zipLookup = false
				this.viewType = 2
				if(this.unknownName){
					this.ignoreFirstCountryChange = true
					if(this.stateCode){
						this.ignoreFirstStateChange = true
					}
					this.notListed(this.unknownName)
				}
			} else if(this.zip){
				this.getInstitutions()
			}
		}
	}
</script>
