<template>
    <div class="chooser">
        <div class="body">
            <div class="cols">
                <router-link :to="backRoute" class="back"><i class="fas fa-arrow-left"></i></router-link>
                <div class="inner card-inner">
                    <div class="header">
                        <el-logo text="Student Login"></el-logo>
                        <products :active="'reflex'"></products>
                    </div>
                     <div class="user">
                        <i class="fas fa-user-circle"></i>
                        <span 
                            class="name"
                            v-tippy="{arrow : true, arrowType : 'round', animation : 'fade'}" 
                            content="Your Name"
                        >
                            {{ student.name }}
                        </span>
                        <span class="info">
                            <span v-tippy="{arrow : true, arrowType : 'round', animation : 'fade'}" content="Your Teacher">
                                {{teacherName}}
                            </span>
                            <span>&middot;</span>
                            <span v-tippy="{arrow : true, arrowType : 'round', animation : 'fade'}" content="Your Class" ref="yourClass">
                                {{className}}
                            </span>
                        </span>
                    </div>
                    <Messaging/>
                    <div style="width: 100%">
                        <h1 class="ul-title">
                            <span>Enter your password.</span>
                        </h1>
                        <form @submit.prevent="login" action="#">
                            <div :class="['input', { bad : formErrors.password }]">
                                <label :class="{ bad: formErrors.password }" for="password"><i class="fas fa-key"></i></label>
                                <input 
                                    v-model="password" 
                                    id="password" 
                                    name="password" 
                                    ref="password" 
                                    type="password" 
                                    placeholder="password" 
                                    value="" 
                                />
                                <button 
                                    :class="{ wait: disableLogin }" 
                                    class="drop-shadow"
                                    :disabled="!password || disableLogin" 
                                    type="submit"
                                    v-tippy="{arrow : true, arrowType : 'round', animation : 'fade'}" 
                                    content="Login"
                                >
                                <i class="fas fa-arrow-right"></i>
</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import APIs from 'mixins/APIs'
    import elLogo from 'components/login/EL-Logo'
    import ErrorHandling from 'mixins/ErrorHandling'
    import Forwarding from 'mixins/Forwarding'
    import Messaging from 'components/layout/Messaging'
    import Products from 'components/login/Products'
    import ProductInfo from 'mixins/ProductInfo'
    import Tracking from 'mixins/Tracking'
    export default {
        name: 'StudentPassword',
        metaInfo : {
            title : 'Password'
        },
        components: { elLogo, Messaging, Products },
        mixins: [APIs, ErrorHandling, Forwarding, ProductInfo, Tracking],
        data () {
            return {
                formErrors: {},
                password: '',
                redirectFailure: false,
                username: '',
            }
        },
        computed : {
            backRoute(){
                if(this.student.licenses && this.student.licenses.length==1 && this.isPreferredProduct(this.student.licenses[0])){
                    return {name: 'ChooseStudent'}
                } else {
                    return {name: 'ChooseProduct'}
                }
            },
            classIdOrNull(){
                if(this.productSettings[this.$store.state.studentLogin.productId].studentLoginRequiresClassID){
                    return this.$store.state.studentLogin.class.classID
                } else {
                    return null
                }
            },
            className : function(){
                return this.$store.state.studentLogin.class.className
            },
            isTouchDevice() {
                return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)
            },            
            product(){
                return this.productSettings[this.$store.state.studentLogin.productId].environment
            },
            disableLogin : function(){
                return this.$store.state.navigation.disableLogin
            },
            student(){
                return this.$store.state.studentLogin.student
            },
            teacherName: function(){
                return this.$store.state.studentLogin.teacherName
            },
        },
        watch : {
            password(val){
                const charLimit = 50
                if(val.length>charLimit){
                    this.formErrors.username=true
                    this.$store.commit('setMessageError', this.$t('error.fieldLength',{fieldName:'Password', charLimit:charLimit}))
                } else {
                    this.$store.commit('setMessageError', '')
                    this.formErrors.username=false
                }
            }
        },
        created : function(){
            if(!this.$store.state.studentLogin.productId){
                this.$router.replace(this.backRoute)
            }
        },
        mounted : function() {
            if(!this.isTouchDevice) {
                this.$refs.password.focus();
            } else {
                this.$nextTick(() => {
                    this.$refs.yourClass.focus();
                    this.$refs.yourClass.blur();
                });   
            }
        },
        methods : {
            isPreferredProduct(productId){
                return this.productSettings[productId].environment==this.currentEnvironment
            },
            login : async function(){
                this.$store.commit('setDisableLogin', true)
                this.$http.post(this.getLoginAPI('app', false, this.product), {"username": this.student.username, "password": this.password}).then(() => {
                    //success
                    this.createTimeStampCookie()
                    const referrerGizmoID = this.extractGizmoId()
                    if(referrerGizmoID){
                        this.go(this.gizmos.reportingApp + '/launch-gizmo/' + referrerGizmoID)
                    } else {
                        const url = this.getStudentAppURL(this.product);
                        const classID = this.classIdOrNull;
                        const query = classID ? { classID } : null;
                        this.$store.commit('setIgnoreReferrer', true)
                        this.forward(url, query) 
                        let self = this;
                        setTimeout(function(){ self.redirectFailure = true }, 5000)
                    }
                }, error => {
                    //failure
                    if(error.response.data.id==14){
                        this.$store.commit('setMessageError', this.$t('invalidPassword'))
                        this.formErrors = {password:true}
                    } else {
                        this.handleError(error.response)
                    }
                    this.$store.commit('setDisableLogin', false)
                })
            }
        }
    }
</script>

<style lang="less" scoped>
	.info {
        width: 350px;
        span {
            display: inline-block;
            max-width: 45%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .drop-shadow:focus {
        box-shadow: 0 0 2px 2px #0072B4;
    }
</style>
