import Constants from 'mixins/Constants'
import LoginDetector from 'mixins/LoginDetector'
import ProductInfo from 'mixins/ProductInfo'
import SubscriptionHelper from 'mixins/SubscriptionHelper'
import ProfileLoader from 'mixins/SubscriptionsLoader'

import { mapGetters, mapState } from 'vuex'

export default {
	mixins: [Constants, LoginDetector, ProductInfo, ProfileLoader, SubscriptionHelper],
	methods: {
		addTrackingInfo(){
			let cleanTrackingInfo = {}
			let trackingInfo = this.$store.state.tracking
			Object.keys(trackingInfo).forEach(function(key){
				if(trackingInfo[key]){
					cleanTrackingInfo[key] = trackingInfo[key]
				}
			})
			return cleanTrackingInfo
		},
		createTimeStampCookie(){
			let now = Date.now()
			this.$cookies.set('lastLoginTimestamp',now)
		},
		formatPid(rawPid){
			let pid = String(rawPid).trim()
			const isInt = Number.isInteger(Number(rawPid))
			if(isInt){
				pid = pid.padStart(8,'0')
			}
			return pid
		},
		getInstitutionTrackingData(subscriptions, institutions, tenantIdKey){
			let institutionData = {
				// =========== Reserved Fields ===========================
				// id:					subscriptions[0].tenantID,			// Pendo
				// name:         											// Optional
				// is_paying:    											// Recommended if using Pendo Feedback
				// monthly_value:											// Recommended if using Pendo Feedback
				// planLevel:    											// Optional
				// planPrice:    											// Optional
				// creationDate: 											// Optional
				// =======================================================
				is_class_code_enabled : 	institutions[0].isClassCodeEnabled,
				pid :  						this.formatPid(institutions[0].pid),
				parent_pid : 				this.formatPid(institutions[0].tenantPid)
			}
			if(tenantIdKey){
				if(subscriptions[0]){
					institutionData[tenantIdKey] = subscriptions[0].tenantID
				} else {
					institutionData[tenantIdKey] = 'No Active Subscriptions'
				}
			}
			return institutionData
		},
		getUserTrackingData(user, subscriptions, institutions, idKey, createdTimestampKey, nameKey){
			let userData =  {
				// ===========  Reserved Fields ==========================
				// id:					'',									// Pendo
				// user_id:				'',									// Intercom
				email:        			user.email,							// Pendo & Intercom
				// name					'',									// Intercom
				// full_name:			'',									// Pendo
				role:					user.roleID,						// Pendo
				// created_at: 			1234567890,							// Intercom
				// =======================================================
				first_name:				user.firstName,
				last_name:				user.lastName,
				is_sso:					this.isSSO,
				is_rostered:			user.isRostered,
				product_opt_out:		user.productsOptOut.join(' ').replace('0','').trim(),
				product_opt_in:			this.getProductIDs(subscriptions).join(' ').trim(),
				is_grizzly_migrated:	user.isGrizzlyMigrated,
				is_grizzly_welcomed:	user.isGrizzlyWelcomed
			}
			if(idKey){
				userData[idKey] = user.id
			}
			if(createdTimestampKey){
				userData[createdTimestampKey] = user.created.timestamp.toString()
			}
			if(nameKey){
				userData[nameKey] = user.firstName+' '+user.lastName
			}
			if(user.roleID == this.roleIDs.ADMIN){
				let isDistrictAdmin = institutions.length >1
				userData.is_district_admin = isDistrictAdmin
				let rostered = null
				if(isDistrictAdmin){
					rostered = this.subscriptionList[0].settings.isRosterEnabled
				} else {
					rostered = institutions[0].isRosterEnabled
				}
				userData.administrates_rostered_institution = rostered
			}
			// Flatten Subscription Information
			for(const productID of this.productDisplayOrder){
				const keyPrefix = this.productDisplayName(productID).toLowerCase() + '_'
				const hasProduct = this.hasSubscription(productID)
				userData[keyPrefix+'user'] = hasProduct
				if(hasProduct){
					const subs = this.getSubscriptions(productID)
					userData[keyPrefix+'subscription_name'] = subs.map(s => s.name).reduce((s, name) => s.concat(s ? '###' : '', name), '');
					userData[keyPrefix+'licensing_type'] = this.getLicenseTypeName(subs.map(s => s.licensingTypeId).sort()[0])
					userData[keyPrefix+'expiration_date'] = subs.reduce((latestSub, currentSub) => {
						// Convert expiresOn to timestamps for comparison
						const latestDate = latestSub ? new Date(latestSub.settings.expiresOn).getTime() : 0;
						const currentDate = new Date(currentSub.settings.expiresOn).getTime();
					
						// Return object with latest expiration date
						return currentDate > latestDate ? currentSub : latestSub;
					}, null).settings.expiresOn.toString();
				}
			}
			return userData
		},
		initializeIntercom(user, subscriptions, institutions){
			// Don't send student data
			if(this.isNotStudent && window.Intercom && INTERCOM_APP_ID){
				// INTERCOM Vars are in GTM Snippet
				let intercomData = {
					api_base: INTERCOM_API_BASE,
					app_id: INTERCOM_APP_ID,
				}
				// Get User/Subscription Data
				const userData = this.getUserTrackingData(user, subscriptions, institutions, 'user_id', 'created_at', 'name')
				// Add Identity Verification
				userData.user_hash = this.profile.userHash
				// Add Additional SSO Fields
				userData.clever_id = user.cleverID
				userData.saml_id = user.samlUserID
				userData.lti_id = user.ltiUserID
				// Get Institution Data
				const institutionData = this.getInstitutionTrackingData(subscriptions, institutions, 'tenant_id')
				// Get UTM Tracking Data
				let utmData = {}
				for(const [key, value] of Object.entries(this.addTrackingInfo())){
					if(key.indexOf('utm_') === 0){
						utmData[key] = value
					}
				}
				// Computer Technical Details - from GTM
				const computerDetails = window.getComputerDetails()
				// Send Data
				intercomData = {...intercomData, ...userData, ...institutionData, ...utmData, ...computerDetails}
				window.Intercom('boot', intercomData);
			}
		},
		initializePendo(user, subscriptions, institutions){
			// Don't send student data
			if(this.isNotStudent && pendo){
				// Use Strings, Numbers, or Booleans for value types.
				let pendoData = {
					visitor: this.getUserTrackingData(user, subscriptions, institutions, 'id', 'date_created', 'full_name'),
					account: this.getInstitutionTrackingData(subscriptions, institutions, 'id')
				}
				// QA Backdoor to Force Guides to Show
				if(this.$route.query.showPendoGuides){
					pendoData.visitor.id = 'QA-'+Math.floor(Math.random() * 100000000)
				}
				// Send Data to Pendo
				if(this.$store.state.tracking.isPendoInitialized){
					// Update Running Instance of Pendo
					pendo.identify(pendoData)
				} else {
					// First Push since Page Load
					pendo.initialize(pendoData)
					this.$store.commit('setIsPendoInitialized', true)
				}
				// Pass Data to GTM
				if(typeof window.dataLayer != 'undefined'){
					window.dataLayer.push(pendoData.visitor);
				}
			}
		},
		isNullString(possibleNull){
			return possibleNull.toLowerCase() == 'null'
		},
		setQueryStringInfo(){
			const trackingQueryStringMapping = {
				fbclid			: 'setFBCLID',
				gclid			: 'setGCLID',
				utm_campaign	: 'setUtmCampaign',
				utm_content 	: 'setUtmContent',
				utm_medium 		: 'setUtmMedium',
				utm_source 		: 'setUtmSource',
				utm_term 		: 'setUtmTerm',
				utm_type 		: 'setUtmType'
			}
			for(let query in trackingQueryStringMapping){
				let trackingVar =  this.$route.query[query]
				// If a tracking code is duplicated in the URL we will choose the non-null value 
				// that appears most frequently. In the case of a tie, we go with the tied value
				// that appears first in the URL.
				if(Array.isArray(trackingVar)){
					let frequencyMap = {}
					let highestFrequency = 0
					trackingVar.forEach(item => {
						if( !this.isNullString(item)){
							if(frequencyMap[item]){
								frequencyMap[item] = frequencyMap[item] + 1
							} else {
								frequencyMap[item] = 1
							}
							if(highestFrequency < frequencyMap[item]){
								highestFrequency = frequencyMap[item]
							}
						}
					})
					for (const key in frequencyMap){
						if(frequencyMap[key] == highestFrequency){
							trackingVar = key
							break
						}
					}
				}
				if(trackingVar && !this.isNullString(trackingVar)){
					this.$store.commit(trackingQueryStringMapping[query],trackingVar)
				}
			}
		},
		setMarketoInfo(){
			if(!this.$store.state.tracking.mktTrk){
				let marketoValue = this.$cookies.get('_mkto_trk')
				if(marketoValue){
					let mktTrk = marketoValue
					this.$store.commit('setMktTrk', mktTrk)
					let munchkinID = JSON.parse('{"' + decodeURI(marketoValue).replace(/"/g, '\\"').replace(/&/g, '","').replace(/:/g,'":"') + '"}').id
					this.$store.commit('setMunchkinID', munchkinID)
				}
	    	}
	  	},
		setTrackingInfo(){
			this.setQueryStringInfo()
			this.setMarketoInfo()
		}
	},
	computed: {
		...mapGetters('platformData/institutions', ['institutionList']),
		...mapGetters('platformData/subscriptions', ['subscriptionList']),
		...mapGetters('platformData/profile', ['profile', 'isSSO']),
		...mapState('platformData/app', ['claims']),
		isNotStudent(){
			return this.loggedIn && this.claims.roleID && this.claims.roleID != this.roleIDs.STUDENT
		},
		modelLoaded(){
			return this.$store.state.classMgmt.modelLoaded
		},
		profileLoadedTracking(){
			return Boolean(this.user.email)
		},
		subscriptionsLoaded(){
			return this.$store.state.store.subscriptionsLoaded
		},
		trackingDataReady(){
			return this.profileLoadedTracking && this.subscriptionsLoaded && this.modelLoaded && this.institutionList && this.institutionList.length
		},
		user(){
			return this.profile
		}
	},
	watch: {
		trackingDataReady(val){
			if(val){
				this.initializePendo(this.user, this.optedInSubscriptions, this.institutionList)
				this.initializeIntercom(this.user, this.optedInSubscriptions, this.institutionList)
			}
		},
		isNotStudent(val){
			if(val){
				this.loadSubscriptions()
			}
		}
	}
}
