<template>
    <UserManagmentModal
        :value="value"
        :headline="invite.email"
        @closeDialogs="closeDialogs()"
        id="ViewInviteModal"
    >
        <v-list>
            <v-flex v-if="userType=='Teachers'">
                <v-subheader>{{$t('classMgmt.productAccess').toUpperCase()}} ({{$t('classMgmt.toggleInstructions')}})</v-subheader>
                <v-flex mb-2>
                    <ProfileProductList
                        @toggleProductAccess="toggleProductAccess"
                        @openMultiSubModal="openMultiSubModal"
                        :ownedProducts="ownedProducts"
                        :user="invite"
                        :hasLiscense="hasLiscense"
                        :hasProductSubscription="hasProductSubscription"
                        :selectedSchool="selectedSchool"
                        :subscriptions="subscriptions"
                        :isTeacher="true"
                        :editable="true"
                    ></ProfileProductList>
                </v-flex>
                <v-divider></v-divider>
            </v-flex>
            <v-subheader>
                {{$t('classMgmt.inviteInfo').toUpperCase()}}
            </v-subheader>
            <v-layout wrap>
                <Messaging outline classes="mx-2"></Messaging>
            </v-layout>
            <v-list-tile avatar v-if="invite.inviteStatus">
                <v-list-tile-avatar>
                    <v-icon>{{invite.inviteStatus.icon}}</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                    <v-list-tile-sub-title>{{$t('classMgmt.inviteStatus')}}</v-list-tile-sub-title>
                    <v-list-tile-title>{{invite.inviteStatus.label}}</v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-avatar v-if="!Boolean(invite.inviteStatus.details)">
                    <InfoToolTip small :msg="invite.inviteStatus.tooltip"/>
                </v-list-tile-avatar>
            </v-list-tile>
            <v-list-tile avatar v-if="creationDate">
                <v-list-tile-avatar>
                    <v-icon>calendar_today</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                    <v-list-tile-sub-title>{{$t('classMgmt.inviteCreated')}}</v-list-tile-sub-title>
                    <v-list-tile-title>{{creationDate.toLocaleDateString(undefined,dateFormat)}}</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>
            <v-list-tile avatar v-if="expirationDate">
                <v-list-tile-avatar>
                    <v-icon>event_busy</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                    <v-list-tile-sub-title>{{$t('classMgmt.inviteExpires')}}</v-list-tile-sub-title>
                    <v-list-tile-title>{{expirationDate.toLocaleDateString(undefined,dateFormat)}}</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>
            <v-list-tile avatar v-if="invite.numberOfInvitesSent>1">
                <v-list-tile-avatar>
                    <v-icon>event</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                    <v-list-tile-sub-title>{{$t('classMgmt.resendDate')}}</v-list-tile-sub-title>
                    <v-list-tile-title>{{resendDate.toLocaleDateString(undefined,dateFormat)}} ({{invite.numberOfInvitesSent}} attempts)</v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-avatar>
                    <InfoToolTip small :msg="$t('classMgmt.resendInfo')"/>
                </v-list-tile-avatar>
            </v-list-tile>
        </v-list>
        <v-divider></v-divider>  
        <v-card-actions>
            <v-btn color="error" flat @click="openDeleteUserDialog()" class="elevation-0" id="deleteInviteBtn">
                <v-icon>delete</v-icon>&nbsp;{{$t('classMgmt.delete')}}
            </v-btn>
            <v-spacer></v-spacer>
            <v-menu offset-y bottom v-if="Boolean(invite.inviteRegCode)">
                <template #activator="{ on }">
                    <v-btn v-on="on" color="primary" flat class="elevation-0 mr-2" id="inviteOtherOptionsBtn">
                        <v-icon>alt_route</v-icon>&nbsp;{{$t('classMgmt.otherOptions')}}&nbsp;&#9662;
                    </v-btn>
                </template>
                <v-card id="addOptions">
                    <v-list class="blue--text">
                        <v-list-tile id="personalEmailBtn" :href="mailToInvite" target="_blank">
                            <v-list-tile-action>
                                <v-icon color="blue">move_to_inbox</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content>
                                {{$t('classMgmt.resendInvitePersonal')}}
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile @click="copyEmail()" id="copyInviteBtn" >
                            <v-list-tile-action>
                                <v-icon color="blue">content_copy</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content>
                                {{$t('classMgmt.copyInvite')}}
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-card>
            </v-menu>
            <v-btn color="primary" @click="resendInvite()" class="elevation-0" id="resendInviteBtn">
                <v-icon>send</v-icon>&nbsp;{{$t('classMgmt.resendInvite')}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import APIs from 'mixins/APIs'
    import Constants from 'mixins/Constants'
    import Messaging from 'components/layout/Messaging'
    import ProfileProductList from 'components/layout/ProfileProductList'
    import InfoToolTip from 'components/classMgmt/InfoTooltip'
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import { mapGetters } from 'vuex'
    export default {
        name: 'ViewInviteModal',
        components: { Messaging, ProfileProductList, InfoToolTip, UserManagmentModal },
        mixins: [APIs, Constants],
        props: {
            hasLiscense: Function,
            ownedProducts: Array,
            selectedSchool: Object,
            subscriptions: Array,
            user: Array,
            userType: String,
            value: Boolean,
        },
        data(){
            return {
                dateFormat : {month:'long', day:'numeric', year:'numeric'}
            }
        },
		computed : {
            ...mapGetters('platformData/profile', ['profile']),
            accountType(){
                return this.userType.slice(0,-1)
            },
            creationDate(){
                if(this.invite.inviteExpiresOn){
                    return new Date(this.invite.created.timestamp)
                } else {
                    return null
                }
            },
            expirationDate(){
                if(this.invite.inviteExpiresOn){
                    return new Date(this.invite.inviteExpiresOn)
                } else {
                    return null
                }
            },
            invite(){
                return this.user[0]
            },
            platform(){
                 return this.$store.state.platform
            },
            mailToInvite(){
                let link = 'mailto:'
                link += this.invite.email
                link += '?subject=' + 'Your ExploreLearning '+ this.accountType +' Account'
                link += '&body=' + this.buildEmailBody('%0D%0A').replace('?','%3F')
                return link
            },
            resendDate(){
                if(this.invite.mostRecentEmailSentOn){
                    return new Date(this.invite.mostRecentEmailSentOn)
                } else {
                    return null
                }
            },
        },
        watch : {
            value(val){
                if(val && this.invite.inviteStatus.details){
                    this.$store.commit('setMessageError', this.invite.inviteStatus.details)
                }
            }
        },
        methods: {
            buildEmailBody(carriageReturn){
                let inviter = {
                    name : this.profile.firstName + ' ' + this.profile.lastName,
                    email : this.profile.email
                }
                let body = 'Welcome to ExploreLearning: The provider of Gizmos®, Reflex®, Science4Us®, and Frax®!'
                body += carriageReturn + carriageReturn
                body += '' + inviter.name + ' (' + inviter.email + ') has invited you to activate your '+ this.accountType.toLowerCase() +' account with ExploreLearning.'
                body += carriageReturn + carriageReturn
                body += 'Access the following URL to set up your account: ' + this.getRegistrationURL(this.invite.inviteRegCode,1)
                body += carriageReturn + carriageReturn
                body += 'Are you new to ExploreLearning? ExploreLearning helps students succeed in math and science!'
                body += ' Use Reflex ('+ this.getMarketingURL('reflex') +') to make learning math facts a breeze.'
                body += ' Frax ('+ this.getMarketingURL('frax') +') will build a deep understanding of fractions.'
                body += ' Engage your students in science concepts with Gizmos ('+ this.getMarketingURL('gizmos') +') and Science4Us ('+this.getMarketingURL('s4us')+').'
                body += carriageReturn + carriageReturn
                body += 'Please contact ExploreLearning Customer Support if you need assistance, '+ this.getCustomerServiceURL() +'.'
                body += carriageReturn + carriageReturn
                body += 'We hope you enjoy ExploreLearning.'
                body += carriageReturn + carriageReturn
                body += 'Regards,'
                body += carriageReturn + carriageReturn
                body += 'ExploreLearning, LLC.'
                body += carriageReturn + '110 Avon Street, Suite 300'
                body += carriageReturn + 'Charlottesville, VA 22902'
                body += carriageReturn + this.getMarketingURL('el')
                return body
            },
            closeDialogs(){
                this.$emit('closeDialogs')
            },
            copyEmail(){
                let body = this.buildEmailBody('\n')
                navigator.clipboard.writeText(body)
                this.$emit('handleSuccess', this.$t('classMgmt.copyInviteSuccess'))
            },
            hasProductSubscription(subscriptions,prodID){
                let hasSub = false
                if (subscriptions)
                {
                    subscriptions.forEach(sub => {
                        if(sub.id){
                            let prodId = sub.productID || sub.productId
                            if(prodId==prodID){
                                hasSub = true
                            }
                        }
                    })
                }
                return hasSub
            },
            openDeleteUserDialog(){
                this.$emit('openDialog', 'deleteConfirmModal', {users: this.user, inviteMode:true})
            },
            openMultiSubModal(productID) {
                this.$store.commit('setMessageError', null)
                this.$emit('openDialog', 'multiSubAccessModal', {users: this.user, productID}, false)
            },
            resendInvite(){
                this.$emit('resendInvite', this.user)
            },
            toggleProductAccess(user, productID){
                this.$emit('toggleProductAccess', [user], productID, null, false, true,)
            }
        }
	}
</script>

<style lang="less" scoped>
</style>