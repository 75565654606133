<template>
	<form @submit.prevent="validate" v-if="display">
		<v-layout wrap>
			<GrantHeadline :headline="$t('grant.contact')"></GrantHeadline>
			<GrantStepper></GrantStepper>
			<Messaging outline></Messaging>
			<v-flex v-if="isPreApproved" xs12>
				<span class="headline">Required</span>
			</v-flex>
			<v-flex xs12 sm2 md1>
				<v-autocomplete
					v-model="titleID"
					v-bind:items="userTitles"
					item-value="id"
					item-text="label"
					:label="$t('form.title')">
				</v-autocomplete>
			</v-flex>
			<v-flex xs12 sm10 md5>
				<v-text-field
					v-model="firstName"
					:label="$t('form.firstName')"
					v-validate="'required|max:30|min:2|noTags|noEscapes'"
					name="first name"
					:error-messages="errors.collect('first name')">
				</v-text-field>
			</v-flex>
			<v-flex xs12 md6>
				<v-text-field
					v-model="lastName"
					:label="$t('form.lastName')"
					v-validate="'required|max:30|min:2|noTags|noEscapes'"
					name="last name"
					:error-messages="errors.collect('last name')">
				</v-text-field>
			</v-flex>
			<v-flex xs12 md6>
				<v-text-field
					v-model="email"
					:label="$t('form.email')"
					browser-autocomplete="new-password"
					v-validate="'required|email|max:100'"
					name="email"
					ref="email"
					:error-messages="errors.collect('email')">
				</v-text-field>
			</v-flex>
			<v-flex v-if="!isPreApproved" xs12 md6>
				<v-text-field
					v-model="emailConfirm"
					:label="$t('form.emailConfirm')"
					v-validate="'required|email|confirmed:email'"
					name="email confirmation"
					:error-messages="errors.collect('email confirmation')">
				</v-text-field>
			</v-flex>
			<v-flex xs8 sm6 :md6="!isPreApproved" :md4="isPreApproved">
				<v-text-field
					v-model="phone"
					:label="$t('form.phoneNum')"
					v-validate="'required|numeric'"
					name="phone number"
					:error-messages="errors.collect('phone number')">
				</v-text-field>
			</v-flex>
			<v-flex xs4 sm6 :md6="!isPreApproved" :md2="isPreApproved">
				<v-text-field
					v-model="phoneExt"
					:label="$t('form.ext')"
					v-validate="'noTags'"
					name="phone extension"
					:error-messages="errors.collect('phone extension')">
				</v-text-field>
			</v-flex>
			<v-flex v-if="isPreApproved" xs12>
				<v-text-field
					v-model="studentCount"
					:label="$t('form.numStudents')"
					v-validate="'required|numeric'"
					name="number of students"
					:error-messages="errors.collect('number of students')">
>
				</v-text-field>
			</v-flex>
		</v-layout>
		<v-layout v-if="isGrantOpen" wrap>
			<v-flex v-if="isPreApproved" xs12 mt-2>
				<span class="headline">Optional</span>
			</v-flex>
			<v-flex xs12 sm6>
				<v-text-field
					v-model="mobile"
					:label="$t('form.mobileNum')"
					v-validate="'numeric'"
					name="cell phone number"
					:error-messages="errors.collect('cell phone number')">
				</v-text-field>
			</v-flex>
			<v-flex xs12 sm6>
				<v-text-field
					v-model="contactTime"
					:label="$t('quote.contactTime')"
					v-validate="'noTags'"
					name="contact time"
					:error-messages="errors.collect('contact time')">
				</v-text-field>
			</v-flex>
			<v-flex v-if="!isPreApproved" xs12>
				<v-text-field
					v-model="studentCount"
					:label="$t('form.numStudents')"
					v-validate="'required|numeric'"
					name="number of students"
					:error-messages="errors.collect('number of students')">
>
				</v-text-field>
			</v-flex>
			<v-flex xs12 md6>
				<v-text-field
					v-model="principalName"
					:label="$t('grant.principalName')"
					v-validate="'max:255|min:2|noTags'"
					name="principal's name"
					:error-messages="errors.collect('principal\'s name')">
>
				</v-text-field>
			</v-flex>
			<v-flex xs12 md6>
				<v-text-field
					v-model="principalEmail"
					:label="$t('grant.principalEmail')"
					v-validate="'email'"
					name="principal's email"
					:error-messages="errors.collect('principal\'s email')">
				</v-text-field>
			</v-flex>
		</v-layout>
		<v-layout v-if="isGrantOpen || isPreApproved" wrap>
			<v-flex xs12>
				<v-select
					v-model="referrer"
					:label="$t('grant.referrer')"
					:items="referrerOptions"
					v-validate="isPreApproved?'':'required'"
					name="referrer"
					:error-messages="errors.collect('referrer')">
				</v-select>
			</v-flex>
			<v-flex v-if="referrer=='Prior Grant'" xs12>
				<v-text-field
					v-model="priorGrant"
					:label="priorGrantLabel"
					:items="referrerOptions"
					v-validate="'required'"
					name="prior grant"
					:error-messages="errors.collect('prior grant')">
				</v-text-field>
			</v-flex>
		</v-layout>
		<v-layout>
			<v-flex xs12 class="text-xs-right">
				<v-btn color="primary" large type="submit" :disabled="isPreApproved&&!isGrantOpen">
					{{$t('navigation.next')}}
				</v-btn>
			</v-flex>
		</v-layout>
	</form>
</template>

<script>
	import APIs from 'mixins/APIs'
	import Constants from 'mixins/Constants'
	import ErrorHandling from 'mixins/ErrorHandling'
	import GrantStepper from 'components/grant/GrantStepper'
	import GrantHeadline from 'components/layout/GrantHeadline'
	import Messaging from 'components/layout/Messaging'
	import ProductInfo from 'mixins/ProductInfo'
	import { mapGetters } from 'vuex'
  export default {
    name: 'GrantInfo',
    metaInfo() {
		return {
			title : this.isGrantOpen || this.isPreApproved ? 'Contact Information' : 'Inquiry Form'
		}
	},
    components: {
    	GrantStepper,
    	GrantHeadline,
    	Messaging
    },
    mixins: [APIs, Constants, ErrorHandling, ProductInfo],
    data () {
		return {
			display :  false,
			titleID : '',
			firstName : '',
			lastName : '',
			email : '',
			emailConfirm: '',
			phone : '',
			phoneExt : '',
			mobile : '',
			studentCount : '',
			contactTime : '',
			principalName : '',
			principalEmail : '',
			referrer : '',
			priorGrant : ''
		}
    },
    computed: {
		...mapGetters('platformData/profile', ['profile']),
    	isGrantOpen(){
    		return this.$store.state.grant.grantOpen
    	},
    	isPreApproved(){
    		return this.flow=='pre-approved'
		},
		priorGrantLabel() {
			return `Please provide the name of the school and district where you previously had a ${this.productDisplayName(this.getProductCode())} Grant`
		},
		referrerOptions(){
			return this.$t('grant.referrerOptions').split('|')
		}
    },
    created(){
    	if(!this.isPreApproved){
	    	if('international' in this.$route.query || this.$store.state.grant.international){
	    		this.$store.commit('setInternationalGrant',true)
	    		this.display = true
	    	} else {
		    	this.$http.get(this.getGrantPeriodAPI()).then(response => {
					if(response.data.GrantPeriod.grantPeriod){
						this.display = true
					} else {
						// Check GrantRegCode
						let grantRegCode = this.$route.params.grantRegCode
						if(grantRegCode){
							this.$http.get(this.getGrantRegCodeValidationAPI(grantRegCode)).then(response => {
								if(response.data.Boolean){
									this.$store.commit('setGrantRegCode',grantRegCode)
									this.display = true
								} else {
									this.grantClosed()
								}
							}).catch(() => {
								this.grantClosed()
							})
						} else {
							this.grantClosed()
						}
					}
				}, error => {
					this.grantClosed()
		      		this.handleError(error)
		      	})
		    }
	    	if(this.profile.email){
	    		this.emailConfirm = this.profile.email
	    	}
	    } else {
	    	// Pre-Approved
			this.$http.get(this.getGrantPeriodAPI()).then(response => {
				if(response.data.GrantPeriod.grantPeriod){
					this.display = true
				} else {
					this.grantClosed()
				}
			}, error => {
				this.grantClosed()
				this.handleError(error)
			})
	    }
    },
    methods: {
    	grantClosed(){
    		this.$store.commit('setGrantOpen',false)
    		if(this.flow=='pre-approved'){
    			this.$store.commit('setMessageError','Note: We are not currently accepting grant applications.')
    		} else {
    			this.$store.commit('setMessageSuccess',this.$t('grant.closedWarning', {product:this.productDisplayName(this.getProductCode())}))
    		}
    		this.display = true
    	},
      	validate: async function (){
    		var valid = await this.$validator.validateAll()
    		if(valid){
    			this.$store.commit('setTitleID',this.titleID)
				this.$store.commit('setServicesFormTitleID',this.titleID)
    			this.$store.commit('setFirstName',this.firstName)
				this.$store.commit('setServicesFormFirstName',this.firstName)
    			this.$store.commit('setLastName',this.lastName)
				this.$store.commit('setServicesFormLastName',this.lastName)
    			this.$store.commit('setEmail',this.email)
				this.$store.commit('setServicesFormEmail',this.email)
    			this.$store.commit('setPhone',this.phone)
				this.$store.commit('setServicesFormPhone',this.phone)
    			this.$store.commit('setPhoneExt',this.phoneExt)
				this.$store.commit('setServicesFormPhoneExt',this.phoneExt)
    			this.$store.commit('setMobile',this.mobile)
				this.$store.commit('setServicesFormMobile',this.mobile)
    			this.$store.commit('setContactTime',this.contactTime)
				this.$store.commit('setServicesFormContactTime',this.contactTime)
    			this.$store.commit('setStudentCount',this.studentCount)
    			this.$store.commit('setPrincipalName',this.principalName)
				this.$store.commit('setPrincipalEmail',this.principalEmail)
				this.$store.commit('setGrantReferrer',this.referrer)
				this.$store.commit('setPriorGrant',this.priorGrant)
				this.$store.commit('setNavAccountType','teacher')
    			if(!this.isPreApproved){
	       			this.$router.push({name:'GrantSchoolInfo'})
	       		} else {
	       			this.$router.push({name:'PreApprovedSchoolInfo'})
	       		}
	      	}
      	}
    }
  }
</script>
