/**
 * Class containing common functionality for all PDF Documents being generated
 * by the admin app.
 **/

import jsPDF from 'jspdf'
import imgs from 'mixins/Imgs'
import _times from 'lodash/times'
import _defaultTo from 'lodash/defaultTo'

export default class PDFDocument {
	constructor({
		filename = 'document',
		lastUpdated = '',
		padding = 32,
		orientation = 'portrait'
	} = {}) {
		this.filename = filename;
		this.lastUpdated = lastUpdated;
		this.padding = padding;

		let ori = { portrait: 'p', landscape: 'l' }[orientation] || 'p';
		this.pdf = new jsPDF(ori, 'pt', 'letter');
		this.width = this.pdf.internal.pageSize.getWidth();
		this.height = this.pdf.internal.pageSize.getHeight();

		this.cursor = { x: padding, y: padding };
	}

	static _iframe = null

	static TextPresets = {
		'h2': { size: 14, type: 'bold', color: [243,150,86] },
		'h3': { size: 14, type: 'bold', color: 130 },
		'h4': { size: 12, type: 'bold', color: 130 },
		'div': { size: 8, type: 'normal', color: 180 },
		'b': { size: 8, type: 'bold', color: 25 },
		'lg': { size: 18, type: 'normal', color: 100 },
		'md': { size: 12, type: 'normal', color: 25 },
		'md-b': { size: 12, type: 'bold', color: 25 },
	}

	static LinePresets = {
		'sm': { width: .25, color: 180 },
		'md': { width: 1.2, color: 0 },
	}

	moveCursor({ x = 0, y = 0 }) {
		this.cursor.x += x;
		this.cursor.y += y;
		return this;
	}

	setCursor({ x, y }) {
		if (typeof x !== 'undefined') {
			this.cursor.x = x;
		}
		if (typeof y !== 'undefined') {
			this.cursor.y = y;
		}
		return this;
	}

	newPage() {
		this.pdf.addPage();
		let x = this.padding;
		let y = this.padding;
		this.cursor = { x, y };
		return this;
	}

	write(text, preset) {
		if (preset) {
			this.formatText({ preset });
		}
		let { x, y } = this.cursor;
		this.pdf.text(_defaultTo(text, ''), x, y);
		return this;
	}

	line({ type = 'horizontal', length }) {
		let { x, y } = this.cursor;
		if (type === 'horizontal') {
			this.pdf.line(x, y, x + length, y);
		} else if (type === 'vertical') {
			this.pdf.line(x, y, x, y + length);
		}
		return this;
	}

	render({ src, width, height, fileType = 'jpeg' }) {
		let { x, y } = this.cursor;
		this.pdf.addImage(src, fileType, x, y, width, height);
		return this;
	}

	table({ headers = [], rows = [], config }) {
		let { padding, cursor } = this;
		let tableConfig = config || {
			styles: { overflow: 'linebreak' },
			margin: { horizontal: padding + 8, bottom: padding * 2 }
		};

		if (tableConfig.startY === undefined) {
			tableConfig.startY = cursor.y;
		}

		if (!headers.length) {
			headers = _times(rows[0].length);
			tableConfig.showHeader = 'never';
		}

		this.pdf.autoTable(headers, rows, tableConfig);

		return this;
	}

	getSource({ autoPrint = false, newTab = false } = {}) {
		if (autoPrint) {
			this.pdf.autoPrint();
		}
		return this.pdf.output((newTab) ? 'dataurlnewwindow': 'bloburi');
	}

	print() {
		if (PDFDocument._iframe) {
			document.body.removeChild(PDFDocument._iframe);
		}

		return new Promise((resolve, reject) => {
			let iframe = document.createElement('iframe');
			let style = 'position: absolute; top: -100vh; left: -100vw';
			iframe.setAttribute('style', style);
			document.body.appendChild(iframe);

			iframe.src = this.getSource({ autoPrint: false });
			iframe.onload = () => {
				try {
					iframe.contentWindow.print();
					resolve();
				} catch {
					iframe.onload = () => resolve();
					iframe.onerror = (e) => reject(e);
					iframe.src = this.getSource({ autoPrint: true });

					reject(new Error('print method not supported'));
				}
			}

			PDFDocument._iframe = iframe;
		})
	}

	download() {
		let name = this.filename;
		this.pdf.save(name.endsWith('.pdf') ? name : name + '.pdf');
	}

	get pageCount() {
		return this.pdf.internal.getNumberOfPages();
	}

	formatLine({ preset, width = 1.2, color = 0, }) {
		if (preset) {
			this.formatLine(PDFDocument.LinePresets[preset]);
		} else {
			this.pdf.setLineWidth(width).setDrawColor(color);
		}
		return this;
	}

	formatText({ preset, size = 14, type = 'normal', color = 0 }) {
		if (preset) {
			this.formatText(PDFDocument.TextPresets[preset]);
		} else {
			let colorParam = Array.isArray(color) ? color : [color];
			this.pdf.setFontSize(size).setFontType(type).setTextColor(...colorParam);
		}
		return this;
	}

	getTextWidth(text) {
		let { pdf } = this;
		let scale = pdf.internal.scaleFactor;
		return pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / scale;
	}

	resizeToWidth({ text, width }) {
		let textWidth = this.getTextWidth(text);
		let fontSize = this.pdf.internal.getFontSize();
		this.pdf.setFontSize((width / textWidth) * fontSize);

		return this;
	}

	drawInvoiceHeader({ title, printMsg }) {
		let { padding, width } = this;
		let logo = { width: 120, height: 23 };

		this.setCursor({ x: padding, y: padding })
			.render({ src: imgs.exploreLearning, ...logo })

		this.moveCursor({ y: 60 })
			.write(title, 'h2')

		this.formatText({preset: 'b'})
		let justifyOffset = width-this.getTextWidth(printMsg)-(padding*2)

		this.moveCursor({ x: justifyOffset })
			.write(printMsg)

		this.moveCursor({ x:-justifyOffset, y: padding*2 })

		return this;
	}

	drawFilterDescription({ filters }) {
		let { width } = this;
		let splitDesc = this.pdf.splitTextToSize(filters, 350);

		let { x, y } = this.cursor;

		this.setCursor({ x: width - 220, y: 46 })
			.write('* Filters applied to report:', 'b')
			.moveCursor({ y: 10 })
			.write(splitDesc, 'div');

		this.setCursor({ x, y });

		return this;
	}

	/**
	 * For each page in the pdf document, draws a border around the page's content
	 * with a copywrite message at the bottom of the page
	 * @return {PDFDocument}
	 *         the pdf document
	 */
	drawBorder() {
		let { width, height, padding, pageCount, pdf } = this;
		let now = new Date()
		let footerText = `© `+now.getFullYear()+` ExploreLearning`;
		let w = width - padding * 2;
		let h = height - padding * 2;

		while (pageCount) {
			pdf.setPage(pageCount--);
			this.formatLine({ preset: 'md' });
			pdf.roundedRect(padding, padding, w, h, 4, 4, 'S');
			this.formatText({ preset: 'div' });
			let footerW = pdf.getStringUnitWidth(footerText) * 8;
			pdf.text(footerText, (width - footerW) / 2, height - padding - 4);
		}

		return this;
	}
}
