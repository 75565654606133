<template>
	<div id="account">
		<v-layout wrap>
			<Headline :headline="$t('profile.header')"></Headline>
		</v-layout>
		<v-tabs slot="extension"
			v-model="activeTab"
			:icons-and-text="$vuetify.breakpoint.width > 750"
			grow
			color="grey lighten-3"
			class="account-nav"
			hide-slider
		>
			<v-tab
				v-for="tab in tabs"
				:to="tab.route"
				:key="tab.id"
				data-test-id="account-tabs">
				<span v-if="$vuetify.breakpoint.width > 750" >
					{{tab.label}}
				</span>
				<v-icon>{{tab.icon}}</v-icon>
			</v-tab>
		</v-tabs>
		<v-flex>
			<v-window v-model="activeWindow">
				<v-window-item>
					<Profile></Profile>
				</v-window-item>
				<v-window-item>
					<Password></Password>
				</v-window-item>
				<v-window-item>
					<Subscriptions></Subscriptions>
				</v-window-item>
				<v-window-item>
					<History></History>
				</v-window-item>
			</v-window>
		</v-flex>
	</div>
</template>

<script>
	import Headline from 'components/layout/Headline'
	import Password from 'components/account/Password'
	import Profile from 'components/account/Profile'
	import Subscriptions from 'components/account/Subscriptions'
	import History from 'components/account/History'
	export default {
		name: 'Account',
		components: {
			Headline,
			Password,
			Profile,
			Subscriptions,
			History
		},
		metaInfo: {
			title : ''
		},
		data(){
			return {
				activeTab: null,
				activeWindow: null,
			}
		},
		computed : {
			numOrders(){
				if(this.$store.state.store.orderHistory){
					return this.$store.state.store.orderHistory.length
				} else {
					return 0
				}
			},
			tabs(){
				let tabs = [
					{id:'profile', icon:'assignment_ind', label:this.$t('profile.profileTab'), route:'profile'},
					{id:'security', icon:'security', label:this.$t('profile.passwordTab'), route:'security'},
					{id:'subscriptions', icon:'vpn_key', label:this.$t('profile.subscriptionTab'), route:'subscriptions'}
				]
				if(this.numOrders){
					tabs.push({id:'orderHistory', icon:'history', label:this.$t('profile.historyTab'), route:'purchases'})
				}
				return tabs
			}
		},
		watch : {
			activeTab(){
				this.$store.commit('setMessageError', '')
				this.$store.commit('setMessageSuccess', '')
				this.$store.commit('setMessageWarning', '')
			},
			$route(to){
					this.activeTab = this.getTabIndex(to.meta.tab)
					this.activeWindow = this.getTabIndex(to.meta.tab)
			}
		},
		created (){
			this.activeTab = this.getTabIndex(this.$route.meta.tab)
			this.activeWindow = this.getTabIndex(this.$route.meta.tab)
		},
		methods:{
			updateURL(tabRoute){
				this.$router.push(tabRoute)
			},
			getTabIndex(tabID){
				let tabIndex = -1
				this.tabs.forEach(function(tab, index){
					if(tabID == tab.id){
						tabIndex = index
					}
				})
				return tabIndex
			}
		}
	}
</script>

<style lang="less">
	.account-nav{
		.v-tabs__bar {
			border-radius: 10px;
			overflow: hidden;
			margin-bottom: 30px;
		}
	}
	.tabHeading {
		text-align: center;
		margin-bottom: 15px;
	}
	.v-tabs__item--active {
		border-bottom: 2px solid #1976d2;
	}
	.v-tabs__item:focus {
		background-color: #E0E0E0;
		border-top: 2px solid #1976d2;
		border-bottom: 2px solid #1976d2;
	}
</style>
