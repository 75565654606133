<template>
	<div>
		<form v-if="incomplete" @submit.prevent="advance">
			<v-layout wrap>
				<Headline :headline="headline"></Headline>
			</v-layout>
			<v-layout wrap>
				<v-flex xs12>
						<Messaging outline></Messaging>
				</v-flex>
			</v-layout>
			<v-layout v-if="showForm" wrap>
				<v-flex xs12>
					<v-text-field
			      	v-model="email" 
			      	:label="$t('form.email')"
			      	v-validate="'required|email|max:100'"
			      	name="email"
			      	:error-messages="errors.collect('email')"
			      	autofocus>
			      </v-text-field>
				</v-flex>
				<v-flex v-if="isTrialOrFree" xs12>
					<v-checkbox
						key="ageCheck"
						:label="$t('email.ageCheck')"
						v-model="ageCheck"
						v-validate="'required'"
						name="age verification"
						:error-messages="errors.collect('age verification')">
			  		</v-checkbox>
			 	 </v-flex>
				<v-flex xs12 class="text-xs-right">
					<v-btn color="primary" large raised type="submit" :disabled="(isTrialOrFree && !ageCheck) || awaitingAJAX || hasError">
						{{$t('navigation.submit')}}
					</v-btn>
				</v-flex>
			</v-layout>
			<v-footer absolute>
				<div class="center">
					{{$t('email.footer')}}
					<router-link v-if="flow=='parent'" :to="{name:'ParentLogin', params: {environment: currentEnvironment}}" >{{$t('email.signIn')}}</router-link>
					<router-link v-else :to="{name:'Login', params: {userType:'educator', environment: currentEnvironment}}">{{$t('email.signIn')}}</router-link>
				</div>
			</v-footer>
		</form>
		<EmailSent v-else></EmailSent>
	</div>
</template>

<script>
	import APIs from 'mixins/APIs'
  	import EmailSent from 'components/email/EmailSent'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import ErrorHandling from 'mixins/ErrorHandling'
	import Headline from 'components/layout/Headline'
	import Messaging from 'components/layout/Messaging'
	import ProductInfo from 'mixins/ProductInfo'
	import Tracking from 'mixins/Tracking'
	import AutoFill from 'mixins/AutoFill'
	export default {
		name: 'TrialEmail',
		metaInfo : {
            title : 'Email'
        },
		components: {
			EmailSent,
			Headline,
			Messaging
		},
		mixins: [APIs, EnvironmentDetector, ErrorHandling, ProductInfo, Tracking, AutoFill],
		data () {
			return {
				ageCheck : false,
				awaitingAJAX: false,
				email : '',
				IsUserFreemium : true,
				incomplete: true,
				showForm: false
			}
		},
		computed: {
			hasError(){
				return Boolean(this.$store.state.messaging.error)
			},
			headline(){
				return this.$t(this.flow+'.header')
			},
			isTrialOrFree(){
				return (this.flow == 'trial' || this.flow == 'free')
			}
		},
		watch : {
			email(){
				if(this.hasError){
					this.$store.commit('setMessageError', null)
				}
			}
		},
		mounted(){
			// Verify that the current use of this form (Trial/Free) is valid
			this.autoFill()
			if(this.isTrialOrFree && this.productHasTrialPromotions(this.getProductCode())){
				this.$http.get(this.getFreemiumTrialPromotionAPI()).then(response => {
					const isTrialPeriod = response.data.FreemiumTrialPromotion.promotionEnabled
					const currentRoute = this.$route.name
					const query = this.$route.query
					if(isTrialPeriod){
						this.IsUserFreemium = false
						if(currentRoute=='FreeReg'){
							this.$router.replace({name: 'TrialReg', query})
						}
					} else {
						if(currentRoute=='TrialReg'){
							this.$router.replace({name: 'FreeReg', query})
						}
					}
					this.autoFillAndSubmit()
					this.showForm = true
				}).catch(() => {
					this.$store.commit('setMessageError', this.$t('error.unknown'))
				})
			} else {
				this.IsUserFreemium = false
				this.autoFillAndSubmit()
				this.showForm = true
			}
		},
		methods: {
			advance: async function (){
				var valid = await this.$validator.validateAll()
				if(valid){
					this.sendEmail()
				}
			},
			autoFillAndSubmit(){
				if(this.$route.query.email){
					this.email = this.$route.query.email
					if(this.isTrialOrFree && this.$route.query.age>=18){
						this.ageCheck = true
						this.sendEmail()
					} else if(this.flow=='parent'){
						this.ageCheck = true
						this.sendEmail()
					} else {
						this.incomplete = true
					}
				} else {
					this.incomplete = true
			}
			},
			sendEmail(){
				this.awaitingAJAX = true
				let apiEndpoint = this.getTrialEmailAPI(this.IsUserFreemium)
				if(this.flow=='parent'){
					apiEndpoint = this.getParentEmailAPI()
				}
				let requestInfo = {
					email: this.email.trim(),
					formInfo : this.addTrackingInfo()
				}
				this.$http.post(apiEndpoint, requestInfo).then(() => {
					this.incomplete = false
					if(typeof window.dataLayer != 'undefined'){
						var dataObject = {
							event : 'TrialSubmitStart',
							product : this.productDisplayName(this.getProductCode())
						}
						window.dataLayer.push(dataObject);
					}
				}, error => {
					this.incomplete = true
					this.handleError(error.response)
          			this.awaitingAJAX = false
				})
			}
		}
	}
</script>
